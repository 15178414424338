import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import get from 'lodash.get';
import {FormattedLink} from '@foes/react-i18n-routing';

import {EVENTS, EVENTS_PAST} from './../../../routing/events/routes';

import withLocationPage from './../../_hocs/withLocationPage';
import {getTemplateRoute, getEventsRoutes} from './../../_util/routes';
import {getRelNext, getRelPrev} from './../../_util/pagination';
import {EVENT} from './../../../routing/events/routes';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
import {getMonthFromDate, getDayNumberFromDate} from '../../_util/getDateFromString';

import Helmet from './../../seo/Helmet';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Loader from './../../atoms/Loader/Loader';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import {PrimaryButton} from './../../atoms/Button/Button';

import Paginator from './../../components/Paginator/Paginator';

import GenericHero from './../../compositions/GenericHero/GenericHero';

import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './Agenda.scss';

const formatListRoute = (locale, template) => page => getTemplateRoute(template, page, locale, {});

const getDatesStringFromEventInfo = (dateTimeRange, locale) => {
  const startDate = `${getDayNumberFromDate(dateTimeRange.startDate)} ${getMonthFromDate(
    dateTimeRange.startDate,
    locale,
  )}`;
  const endDate = `${getDayNumberFromDate(dateTimeRange.endDate)} ${getMonthFromDate(dateTimeRange.endDate, locale)}`;
  return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
};

const Agenda = ({content, title, image, intl, pagination, page, fetching, template}) => (
  <div className="agenda">
    <GenericHero backgroundImage={image} title={title} type={title} />
    <Helmet
      relNext={getRelNext(page, pagination.totalPages, getEventsRoutes(0, intl.locale, {}))}
      relPrev={getRelPrev(page, getEventsRoutes(0, intl.locale, {}))}
      title={title}
    />
    <FullRow className="agenda__content">
      <Column className="agenda__content-aside">
        <div className="agenda__content-aside-filters">
          <FormattedLink name={template === 'agenda' ? EVENTS_PAST : EVENTS}>
            <PrimaryButton>
              {intl.formatMessage({id: template === 'agenda' ? 'agenda.show.past' : 'agenda.show'})}
            </PrimaryButton>
          </FormattedLink>
        </div>
      </Column>
      <Column className="agenda__content-main">
        {!fetching ? (
          content.length > 0 ? (
            content.map((card, index) => {
              const dates = getDatesStringFromEventInfo(card.dateTimeRange, intl.locale);
              const location = get(card, 'city.city');
              const ambit = get(card, 'ambits.items.0.title');
              const cardContent = (
                <div className="agenda__content-item-data">
                  <div className="agenda__content-item-data-left">
                    {card.eventType && card.eventType.items.length > 0 && (
                      <Typography className="agenda__content-item-data-type" variant={VARIANT.LABEL}>
                        {card.eventType.items[0].title}
                      </Typography>
                    )}
                    {ambit && <Typography variant={VARIANT.FOOTER}>{ambit}</Typography>}
                  </div>

                  {location && (
                    <div className="agenda__content-item-data-place">
                      <Iconography className="agenda__content-item-data-place-icon" icon={ICON.PLACE} />
                      <Typography variant={VARIANT.FOOTER}>{location}</Typography>
                    </div>
                  )}
                </div>
              );
              return (
                <RoutingGenericPreview
                  content={cardContent}
                  horizontal
                  key={index}
                  label={dates}
                  preview={card}
                  to={EVENT}
                />
              );
            })
          ) : (
            <Typography className="agenda__non-content">{intl.formatMessage({id: 'list.event.none'})}</Typography>
          )
        ) : (
          <Loader />
        )}
        {pagination.totalPages > 1 && (
          <Paginator
            currentPage={page}
            formatPageRoute={formatListRoute(intl.locale, template)}
            totalPages={pagination.totalPages}
          />
        )}
      </Column>
    </FullRow>
  </div>
);

export default compose(React.memo, injectIntl, withLocationPage)(Agenda);
