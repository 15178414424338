import React from 'react';
import PropTypes from 'prop-types';

import CmsLink from './../../components/CmsLink/CmsLink';

import ExpertSocial from './../../components/ExpertSocial/ExpertSocial';

const RoutingExpertSocial = ({linkedinSlug, researchgateSlug, ...rest}) => (
  <ExpertSocial
    linkedinSlug={linkedinSlug}
    renderLinkedin={children => (
      <CmsLink className="expert-social__link" slug={linkedinSlug}>
        {children}
      </CmsLink>
    )}
    renderResearchGate={children => (
      <CmsLink className="expert-social__link" slug={researchgateSlug}>
        {children}
      </CmsLink>
    )}
    researchgateSlug={researchgateSlug}
    {...rest}
  />
);

RoutingExpertSocial.propTypes = {
  linkedinSlug: PropTypes.string.isRequired,
  researchgateSlug: PropTypes.string.isRequired,
};

export default React.memo(RoutingExpertSocial);
