import React from 'react';
const LandingIcon = ({path}) => {
  const style = {
    display: 'inline-block',
    width: 'var(--size, 24px)',
    height: 'var(--size, 24px)',
    'min-width': 'var(--size, 24px)',
    'background-color': 'var(--color, #000)',
    mask: `url(${path}) no-repeat center / contain`,
  };
  return <span className="landing-icon" style={style}></span>;
};

export default LandingIcon;
