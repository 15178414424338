import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ShareButtons from './ShareButtons/ShareButtons';
import PrintButton from './PrintButton/PrintButton';

import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import {PrimaryButton} from './../../atoms/Button/Button';

import './SocialSharePrint.scss';

const SocialSharePrint = ({print, renderButton, text, url, white}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={classNames('social-share-print', {
        'social-share-print--open': isOpen,
        'social-share-print--white': white,
      })}
    >
      <div className="social-share-print__column">
        <PrimaryButton
          className={classNames('social-share-print__button', {'social-share-print__button--open': isOpen})}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Iconography
            className={isOpen ? 'social-share-print__close-icon' : 'social-share-print__share-icon'}
            icon={isOpen ? ICON.CLOSE : ICON.SHARE}
          />
        </PrimaryButton>
        {isOpen && <ShareButtons renderButton={renderButton} text={text} url={url} />}
        {print && <PrintButton />}
      </div>
    </div>
  );
};

SocialSharePrint.propTypes = {
  print: PropTypes.bool,
  renderButton: PropTypes.func,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  white: PropTypes.bool,
};

SocialSharePrint.defaultProps = {
  renderButton: children => children,
  print: false,
  white: false,
};

export default SocialSharePrint;
