import i18n from '../../../i18n';

const shouldShowChilds = (selected, mainItems = []) =>
  selected !== null && mainItems[selected] && mainItems[selected].items && mainItems[selected].items.length > 0;

const recursiveFinding = (locale, pathname) => currItem =>
  (currItem.slug !== '' &&
    (i18n.prependLocale(locale, currItem.slug) === pathname ||
      pathname.includes(i18n.prependLocale(locale, currItem.slug)))) ||
  (currItem.items && currItem.items.find(recursiveFinding(locale, pathname)));

const isSelected = ({item, locale, pathname}) =>
  (item.slug !== '' && i18n.prependLocale(locale, item.slug) === pathname) ||
  (item.items && item.items.find(recursiveFinding(locale, pathname)));

const checkScrolled = setIsScrolled => () => {
  window.requestAnimationFrame(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsScrolled(scrollTop > 50);
  }, []);
};

const shouldShowChildsByColumnsAndRows = ({columnsPerView, row, selected, items}) =>
  selected !== null &&
  row === parseInt(selected / columnsPerView, 10) &&
  items[selected].items &&
  items[selected].items.length > 0;

const shouldShowChildsMobile = (selected, index, mainItems = []) =>
  selected !== null &&
  selected === index &&
  mainItems[selected] &&
  mainItems[selected].items &&
  mainItems[selected].items.length > 0;

const hasSubElements = (items = []) => items.some(item => item.items);

const hasChildren = item => item && item.items && item.items.length > 0;

export {
  isSelected,
  checkScrolled,
  shouldShowChilds,
  shouldShowChildsByColumnsAndRows,
  shouldShowChildsMobile,
  hasSubElements,
  hasChildren,
};
