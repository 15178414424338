import React from 'react';
import classNames from 'classnames';

import './LandingTitle.scss';

const LandingTitle = ({content}) => {
  const wrapperStyle = {
    'justify-content': content.verticalAlignment || 'flex-start',
  };
  const titleStyle = {
    color: content.color,
  };
  const descriptionStyle = {
    color: content.color,
  };
  return (
    <div className="landing-title__wrapper" style={wrapperStyle}>
      <h1 className={classNames(`fs-sec--${content.size}`, 'landing-title__title')} style={titleStyle}>
        {content.title}
      </h1>
      {content.description && (
        <div
          className="fs--md landing-title__description"
          dangerouslySetInnerHTML={{__html: content.description}}
          style={descriptionStyle}
        ></div>
      )}
    </div>
  );
};
export default React.memo(LandingTitle);
