import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';

import ConditionalWrap from './../../../_util/ConditionalWrap';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

import './CmsNumbers.scss';

const CmsNumbers = ({content, wrapWithSensor, intl}) => {
  const [visible, setVisible] = useState(wrapWithSensor ? false : true);

  const getDecimalByLocale = () => {
    switch (intl.locale) {
      case 'es':
      case 'eu':
      case 'fr':
        return ',';
      case 'en':
        return '.';
      default:
        return ',';
    }
  };

  const getSeparatorByLocale = () => {
    switch (intl.locale) {
      case 'es':
      case 'eu':
        return '.';
      case 'fr':
        return ' ';
      case 'en':
        return ',';
      default:
        return '.';
    }
  };
  return (
    <ConditionalWrap
      condition={wrapWithSensor}
      wrap={(children) => (
        <VisibilitySensor active={!visible} onChange={(isVisible) => setVisible(isVisible)} partialVisibility>
          {children}
        </VisibilitySensor>
      )}
    >
      <div className="cms-numbers">
        {visible && !content.noTransition ? (
          <CountUp
            decimal={getDecimalByLocale()}
            decimals={content.hasDecimals ? 2 : 0}
            delay={0}
            duration={4}
            end={parseInt(content.number, 10)}
            prefix={content.prefix}
            redraw={true}
            separator={getSeparatorByLocale()}
            start={0}
            startOnMount={false}
            suffix={content.subfix}
          >
            {({countUpRef}) => <p className="cms-numbers__number" ref={countUpRef} />}
          </CountUp>
        ) : (
          <p className="cms-numbers__number">{parseInt(content.number, 10)}</p>
        )}
        <Typography variant={content.small ? VARIANT.BODY_SMALLER : VARIANT.HEADING_LOWER}>{content.title}</Typography>
      </div>
    </ConditionalWrap>
  );
};

CmsNumbers.propTypes = {
  content: PropTypes.object,
  wrapWithSensor: PropTypes.bool,
};

CmsNumbers.defaultProps = {
  content: {
    noTransition: false,
  },
  wrapWithSensor: false,
};

export default injectIntl(CmsNumbers);
