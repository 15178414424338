import {connect} from 'react-redux';
import queryString from 'query-string';

const withLocationPage = Component => connect(mapStateToProps)(Component);

const pageFromLocation = location => parseInt(queryString.parse(location.search).page, 10) || 1;

const mapStateToProps = state => ({
  page: pageFromLocation(state.router.location),
});

export default withLocationPage;
