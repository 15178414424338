import React from 'react';
import PropTypes from 'prop-types';

import relatedInspiringStoryProptype from '../../_propTypes/relatedInspiringStory';

import Picture, {RATIO} from '../../atoms/Picture/Picture';
import {VARIANT} from '../../atoms/Typography/Typography';

import TitleTextComponent from '../TitleTextComponent/TitleTextComponent';

import './InspiringStoryPreview.scss';

const InspiringStoryPreview = ({inspiringStory, renderContainer, label}) => (
  <React.Fragment>
    {renderContainer(
      <article className="inspiring-story-preview">
        <div className="inspiring-story-preview__picture">
          <Picture
            alt={inspiringStory.image.alt}
            className="inspiring-story-preview__picture-image"
            cover
            ratio={RATIO.R_4_3}
            src={inspiringStory.image.src.medium}
            srcSet={[
              {
                src: inspiringStory.image.src.small,
                maxWidth: 480,
              },
            ]}
          />
        </div>
        <div className="inspiring-story-preview__content">
          <TitleTextComponent text={inspiringStory.title} textTag="p" textVariant={VARIANT.BODY_BIG} title={label} />
          <div className="inspiring-story-preview__logo">
            <Picture
              alt={inspiringStory.quote.partner[0].bWLogo.alt}
              className="inspiring-story-preview__logo-image"
              cover
              src={inspiringStory.quote.partner[0].bWLogo.src.medium}
              srcSet={[
                {
                  src: inspiringStory.quote.partner[0].bWLogo.src.small,
                  maxWidth: 480,
                },
              ]}
            />
          </div>
        </div>
      </article>,
    )}
  </React.Fragment>
);

InspiringStoryPreview.propTypes = {
  inspiringStory: relatedInspiringStoryProptype,
  renderContainer: PropTypes.func,
};

InspiringStoryPreview.defaultProps = {
  renderContainer: children => children,
};

export default React.memo(InspiringStoryPreview);
