import {cancelableGet} from './Client';

export default locale => ({
  oneBySlug: ({page = 1, perPage = 12, ...restParams}) =>
    cancelableGet(`/api/redirect?&page=${page}&per_page=${perPage}&lang=${locale}`, {
      params: restParams,
      headers: {
        'Accept-Language': locale,
      },
    }),
});
