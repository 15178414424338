const isPhone = new RegExp(/^((\+34)?)(\(?\d{3}\)?)(-?\d{3})(-?\d{3,4})$/);
// eslint-disable-next-line no-useless-escape
const isMail = new RegExp(/^[a-zA-Z0-9_\-\.]+\@[a-zA-Z0-9_\-\.]+$/);

export const isURLPhoneOrMail = url => isPhone.test(url) || isMail.test(url);

const replaceURLForPhoneOrMail = url => {
  if (isPhone.test(url)) {
    return `tel:${url}`;
  }

  if (isMail.test(url)) {
    return `mailto:${url}`;
  }

  return url;
};

export default replaceURLForPhoneOrMail;
