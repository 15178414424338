import React from 'react';
import Helmet from 'react-helmet';
import {withRouter} from 'react-router';

const defaultTitle = 'Tecnalia';
const defaultDescription = 'Tecnalia Website';
const defaultImage = `/images/logo.jpg`;
const defaultTwitter = '@tecnalia';
const defaultSep = ' | ';
const defaultUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3201' : 'https://www.tecnalia.com';

class Page extends React.Component {
  getMetaTags(
    {title, description, image, contentType, twitter, noCrawl, published, updated, category, tags},
    pathname,
  ) {
    const theTitle = title ? (title + defaultSep + defaultTitle).substring(0, 60) : defaultTitle;
    const theDescription = description ? description.substring(0, 155) : defaultDescription;
    const theImage = image ? image : defaultImage;

    const metaTags = [
      {itemprop: 'name', content: theTitle},
      {itemprop: 'description', content: theDescription},
      {itemprop: 'image', content: theImage},
      {name: 'description', content: theDescription},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:site', content: defaultTwitter},
      {name: 'twitter:title', content: theTitle},
      {name: 'twitter:description', content: theDescription},
      {name: 'twitter:creator', content: twitter || defaultTwitter},
      {name: 'twitter:image:src', content: theImage},
      {property: 'og:title', content: theTitle},
      {property: 'og:type', content: contentType || 'website'},
      {property: 'og:url', content: defaultUrl + pathname},
      {property: 'og:image', content: theImage},
      {property: 'og:description', content: theDescription},
      {property: 'og:site_name', content: defaultTitle},
      {property: 'fb:app_id', content: 'xxx'},
    ];

    if (noCrawl) {
      metaTags.push({name: 'robots', content: 'noindex, nofollow'});
    }
    if (published) {
      metaTags.push({name: 'article:published_time', content: published});
    }
    if (updated) {
      metaTags.push({name: 'article:modified_time', content: updated});
    }
    if (category) {
      metaTags.push({name: 'article:section', content: category});
    }
    if (tags) {
      metaTags.push({name: 'article:tag', content: tags});
    }

    return metaTags;
  }

  render() {
    const {children, className, ...rest} = this.props;

    return (
      <div className={className}>
        <Helmet
          htmlAttributes={{
            itemscope: undefined,
            itemtype: `http://schema.org/${rest.schema || 'WebPage'}`,
          }}
          link={[
            {
              rel: 'canonical',
              href: defaultUrl + this.props.location.pathname,
            },
          ]}
          meta={this.getMetaTags(rest, this.props.location.pathname)}
          title={rest.title ? rest.title + defaultSep + defaultTitle : defaultTitle}
        />
        <main>{children}</main>
      </div>
    );
  }
}

export default withRouter(Page);
