import React, {useState} from 'react';

import FixedButton from './../../../atoms/FixedButton/FixedButton';
import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';
import Modal from './../../../atoms/Modal/Modal';

import Form from './../../../components/Forms/Form';

import Column from './../../../layouts/Column/Column';
import FullRow from './../../../layouts/FullRow/FullRow';

const FixedModule = ({content}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FullRow scrollAnimation={false}>
      <FullRow>
        <Column>
          <Modal
            className="fixed-module__center-modal"
            fullHeight={true}
            isOpen={isOpen}
            onCloseClick={() => setIsOpen(false)}
          >
            <Form formInfo={content} />
          </Modal>
        </Column>
      </FullRow>
      <FixedButton className="fixed-module__cta" onClick={() => setIsOpen(true)}>
        <Iconography className="fixed-module__icon" icon={ICON.MAIL} />
      </FixedButton>
    </FullRow>
  );
};

export default React.memo(FixedModule);
