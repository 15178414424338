export const DELEGATIONS = 'delegations';
export const DELEGATION = 'delegation';

export const delegationsRouteParams = {
  [DELEGATIONS]: {
    shouldPartiallyRender: true,
  },
  [DELEGATION]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [DELEGATIONS]: {
    es: '/sedes',
    en: '/locations',
    eu: '/egoitzak',
    fr: '/sieges',
  },
  [DELEGATION]: {
    es: '/sedes/:slug',
    en: '/locations/:slug',
    eu: '/egoitzak/:slug',
    fr: '/sieges/:slug',
  },
};
