import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedNavLink} from '@foes/react-i18n-routing';
import {FormattedMessage, injectIntl} from 'react-intl';
import {pushDataLayer} from 'lin3s-react-analytics';

import {pageLoadError404} from './../../_util/dataLayerTransformation';

import {HOME} from './../../../routing/routes';
import Helmet from './../../seo/Helmet';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import InlineButton from './../../atoms/InlineButton/InlineButton';

import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';

import './Error.scss';

const Error = ({error, intl}) => (
  <Route
    render={({staticContext}) => {
      if (staticContext) {
        staticContext.status = error.status;
      }

      const is404 = error.status.toString() === '404';

      if (is404) {
        pushDataLayer(pageLoadError404());
      }

      return (
        <div className="error-template">
          <Helmet title={intl.formatMessage({id: `page.error.${is404 ? '404' : '500'}_title`})} />

          <Row className="error-template__title" scrollAnimation={false}>
            <Column>
              <Typography variant={VARIANT.H1}>
                <FormattedMessage id={`page.error.${is404 ? '404' : '500'}`} />
              </Typography>
            </Column>
          </Row>

          <Row scrollAnimation={false}>
            <Column>
              <FormattedNavLink name={HOME}>
                <Typography variant={VARIANT.HEADING}>
                  <InlineButton>
                    <FormattedMessage id={'page.error.return_homepage'} />
                  </InlineButton>
                </Typography>
              </FormattedNavLink>
            </Column>
          </Row>
        </div>
      );
    }}
  />
);

Error.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.number.isRequired,
    message: PropTypes.string,
  }).isRequired,
};

export default injectIntl(Error);
