import stripHTMLTagsByText from './../stripHTMLTagsByText';

const mapEventToMicrodata = ({dateTimeRange, excerpt, image: {src = {}} = {}, place, price, title, city}) => ({
  online: city === 'Online' || city.city === 'Online',
  name: title,
  description: stripHTMLTagsByText(excerpt),
  image: src.original,
  startDate: dateTimeRange.startDate,
  endDate: dateTimeRange.endDate,
  price: price.price,
  place: place.place ? place.place : place.delegation ? place.delegation.postTitle : '',
  images: [src.small, src.medium, src.large],
});

export {mapEventToMicrodata};
