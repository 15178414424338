import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography from './../Typography/Typography';

import './InlineButton.scss';

const InlineButton = ({boxed, children, className, disabled, inverted, tag, ...rest}) => (
  <Typography
    className={classNames(
      'inline-button',
      {
        'inline-button--boxed': boxed,
      },
      {
        'inline-button--inverted': inverted,
      },
      className,
    )}
    disabled={disabled}
    tag={tag}
    {...rest}
  >
    {children &&
      React.Children.map(children, (child, index) => (
        <span className="inline-button__content" key={index}>
          {child}
        </span>
      ))}
  </Typography>
);

InlineButton.propTypes = {
  boxed: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  tag: PropTypes.string,
};

InlineButton.defaultProps = {
  boxed: true,
  disabled: false,
  inverted: false,
};

export default React.memo(InlineButton);
