import React from 'react';
import {compose} from 'redux';
import {connectSearchBox} from 'react-instantsearch-dom';

const SearchInput = ({currentRefinement, refine}) => (
  <input
    className="search-input"
    onChange={event => refine(event.currentTarget.value)}
    type="text"
    value={currentRefinement}
  />
);

export default compose(
  React.memo,
  connectSearchBox,
)(SearchInput);
