import React, {useState} from 'react';
import {Spring} from 'react-spring/renderprops.cjs.js';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';

const ScrollAnimation = ({children, delay, shouldDelayAnimation, scrollAnimation}) => {
  const [visible, setVisible] = useState(false || !scrollAnimation);
  return (
    <VisibilitySensor active={!visible} onChange={isVisible => setVisible(isVisible)} partialVisibility>
      <Spring
        config={{delay: shouldDelayAnimation ? delay : 0, tension: 210, friction: 35, clamp: true}}
        to={{opacity: visible ? 1 : 0, transform: visible ? '' : 'translateY(200px)'}}
      >
        {props => <div style={props}>{children}</div>}
      </Spring>
    </VisibilitySensor>
  );
};

ScrollAnimation.propTypes = {
  delay: PropTypes.number,
  shouldDelayAnimation: PropTypes.bool,
};

ScrollAnimation.defaultProps = {
  delay: 0,
  shouldDelayAnimation: true,
  scrollAnimation: true,
};

export default React.memo(ScrollAnimation);
