import React from 'react';
import {compose} from 'redux';
import get from 'lodash.get';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {useSelector} from 'react-redux';

import BREAKPOINTS from './../../_config/breakpoints';
import withSize from './../../../_packages/resize/ui/withSize';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import Content from './../../atoms/Content/Content';

import PrefooterMobile from './PrefooterMobile/PrefooterMobile';

import CmsLink from './../../components/CmsLink/CmsLink';

import Grid from './../Grid/Grid';

import './Prefooter.scss';

const Prefooter = ({windowWidth}) => {
  const menus = useSelector(state => get(state, 'api.backendContext.resource.menus'));
  if (!menus) {
    return null;
  }
  const prefooter = menus.filter(menu => menu.originalSlug === 'pre-footer')[0];
  return (
    <nav className="prefooter">
      {windowWidth < BREAKPOINTS.XL && <PrefooterMobile prefooter={prefooter} />}
      {windowWidth >= BREAKPOINTS.XL && (
        <Grid className="prefooter__grid" columnsPerBreakpoint={{S: 6}} scrollAnimation={false}>
          <Content className="prefooter__menu-title" variant={VARIANT.MENU}>
            {prefooter.options.title}
          </Content>
          {prefooter.items.map((menuLink, index) => (
            <CmsLink className="prefooter__menu-items" key={index} slug={menuLink.slug}>
              <Typography variant={VARIANT.MENU}>{menuLink.title}</Typography>
            </CmsLink>
          ))}
          <AnchorLink className="prefooter__anchor" href="#header" offset="">
            <Iconography icon={ICON.UP_ARROW} />
          </AnchorLink>
        </Grid>
      )}
    </nav>
  );
};

export default compose(
  withSize,
  React.memo,
)(Prefooter);
