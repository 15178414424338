import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import {FormattedLink} from '@foes/react-i18n-routing';

import RoutingGenericPreview from './../../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import {NEWS, NEWS_LIST} from './../../../../routing/news/routes';

import {VARIANT} from './../../../atoms/Typography/Typography';
import AnimatedInlineButton from './../../../atoms/AnimatedInlineButton/AnimatedInlineButton';

import RelatedContentCards from './../../../compositions/RelatedContentCards/RelatedContentCards';

import Column from './../../../layouts/Column/Column';
import Row from './../../../layouts/Row/Row';

import './NewsModule.scss';

const NewsModule = ({content, intl}) => (
  <Row className="news-module" scrollAnimation={false} tag="section">
    <RelatedContentCards
      scrollAnimation={false}
      slidesPerBreakpoint={{S: 1, L: 2}}
      subtitle={content.subtitle}
      title={content.title}
      titleTag="h1"
      titleVariant={VARIANT.H1}
    >
      {content.items.map((item, index) => (
        <RoutingGenericPreview key={index} label={intl.formatMessage({id: 'news.category'})} preview={item} to={NEWS} />
      ))}
    </RelatedContentCards>
    <Column>
      <div className="news-module__link-button">
        <FormattedLink className="news-module__link" name={NEWS_LIST}>
          <AnimatedInlineButton text={intl.formatMessage({id: 'show.all'})} />
        </FormattedLink>
      </div>
    </Column>
  </Row>
);

export default compose(injectIntl, React.memo)(NewsModule);
