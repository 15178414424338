import React from 'react';
import './LandingAccordionBlock.scss';
import LandingAccordion from '../LandingAccordion/LandingAccordion';

const LandingAccordionBlock = ({label, title, description, accordion}) => (
  <div className="landing-accordion-block">
    {label && <h2 className="fs--label">{label}</h2>}
    {title && <h3 className="fs-sec--lg">{title}</h3>}
    {description && (
      <div
        className="fs--body landing-accordion-block__description"
        dangerouslySetInnerHTML={{__html: description}}
      ></div>
    )}
    {accordion && accordion.length > 0 && <LandingAccordion accordion={accordion}></LandingAccordion>}
  </div>
);

export default LandingAccordionBlock;
