import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import relatedTagsPropType from './../../_propTypes/relatedTagsPropType';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Grid from './../../layouts/Grid/Grid';
import FullRow from './../../layouts/FullRow/FullRow';
import get from 'lodash.get';

import './RelatedTags.scss';

const RelatedTags = ({className, breakpoints, hasLink, renderContainer, title, tags, withBorder}) => (
  <FullRow
    className={classNames(
      'related-tags',
      {'related-tags--with-border': withBorder, 'related-tags--disabled': !hasLink},
      className,
    )}
    scrollAnimation={false}
  >
    {tags.length > 0 && (
      <Typography className="related-tags__title" tag="h2" variant={VARIANT.HEADING_SMALL}>
        {title}
      </Typography>
    )}
    <Grid
      className="related-tags__grid"
      columnsPerBreakpoint={breakpoints}
      itemClassName="related-tags__grid-item"
      scrollAnimation={false}
    >
      {tags.map((tag, index) =>
        renderContainer(
          <Typography className="related-tags__item" key={index} variant={VARIANT.BODY_XSMALL}>
            {get(tag, 'fields.relatedPage.title') || tag.title}
          </Typography>,
          index,
        ),
      )}
    </Grid>
  </FullRow>
);

RelatedTags.propTypes = {
  renderContainer: PropTypes.func,
  tags: relatedTagsPropType.isRequired,
  title: PropTypes.string.isRequired,
  withBorder: PropTypes.bool,
};

RelatedTags.defaultProps = {
  renderContainer: children => children,
  withBorder: true,
};

export default React.memo(RelatedTags);
