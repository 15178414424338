export const ACTIVE = 'active';
export const ACTIVES = 'actives';

export const activesRouteParams = {
  [ACTIVES]: {
    shouldPartiallyRender: true,
  },
  [ACTIVE]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [ACTIVES]: {
    es: '/activos',
    en: '/technological-assets',
    eu: '/aktibo-teknologikoak',
    fr: '/actifs-technologiques',
  },
  [ACTIVE]: {
    es: '/activos/:slug',
    en: '/technological-assets/:slug',
    eu: '/aktibo-teknologikoak/:slug',
    fr: '/actifs-technologiques/:slug',
  },
};
