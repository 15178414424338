import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Spring} from 'react-spring/renderprops.cjs.js';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Picture from './../../atoms/Picture/Picture';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';

import Grid from './../../layouts/Grid/Grid';
import FullRow from './../../layouts/FullRow/FullRow';

import './LogosGrid.scss';

const LogosGrid = ({breakpoints, hasDescription, renderContainer, logos, title, maxLogos, withBackground}) => {
  const [opened, setOpened] = useState(false);

  const logosToShow = opened ? logos : logos.slice(0, maxLogos);

  return (
    <FullRow>
      <Typography className="logos-grid__title" tag="h2" variant={VARIANT.HEADING}>
        {title}
      </Typography>
      <div
        className={classNames({
          'logos-grid--with-background': withBackground,
          'logos-grid--with-no-description': !hasDescription,
        })}
      >
        <Grid className="logos-grid__grid" columnsPerBreakpoint={breakpoints} scrollAnimation={false}>
          {logosToShow.map((logo, index) => (
            <Spring
              from={{opacity: 0, transform: 'translate3d(0,-40px,0)'}}
              key={`logosGrid--${logo.id}`}
              to={{opacity: 1, transform: 'translate3d(0,0,0)'}}
            >
              {props => (
                <div style={props}>
                  {renderContainer(
                    <Picture
                      alt={logo.image.alt}
                      className={classNames({'logos-grid__item-image': hasDescription})}
                      cover
                      src={logo.image.src.small}
                    />,
                    index,
                  )}
                  {hasDescription && (
                    <Typography className="logos-grid__item-description" tag="div" variant={VARIANT.BODY_SMALLER}>
                      {logo.title}
                    </Typography>
                  )}
                  {hasDescription &&
                    renderContainer(
                      <Typography className="logos-grid__item-link" variant={VARIANT.BODY_SMALL}>
                        {logo.file.text}
                      </Typography>,
                      index,
                    )}
                </div>
              )}
            </Spring>
          ))}
        </Grid>
        {logos.length > maxLogos && (
          <Typography
            className="logos-grid__show-button"
            onClick={() => setOpened(!opened)}
            variant={VARIANT.BODY_SMALL}
          >
            <FormattedMessage id={opened ? 'show.less' : 'show.all'} />
            <Iconography className="logos-grid__icon" icon={opened ? ICON.CLOSE : ICON.PLUS} />
          </Typography>
        )}
      </div>
    </FullRow>
  );
};

LogosGrid.propTypes = {
  hasDescription: PropTypes.bool,
  renderContainer: PropTypes.func,
  withBackground: PropTypes.bool,
};

LogosGrid.defaultProps = {
  hasDescription: false,
  renderContainer: children => children,
  withBackground: false,
};

export default React.memo(LogosGrid);
