import PropTypes from 'prop-types';

import responsiveImagePropType from './responsiveImagePropType';

export default PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: responsiveImagePropType.isRequired,
      technologies: PropTypes.arrayOf(
        PropTypes.shape({
          fields: PropTypes.shape({
            image: responsiveImagePropType,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
          }),
          slug: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
});
