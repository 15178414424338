import PropTypes from 'prop-types';

import responsiveImagePropType from './responsiveImagePropType';

export default PropTypes.arrayOf(
  PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: responsiveImagePropType.isRequired,
        link: PropTypes.shape({
          linkUrl: PropTypes.string.isRequired,
        }),
        title: PropTypes.string,
      }),
    ),
    title: PropTypes.string.isRequired,
  }),
);
