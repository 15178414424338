import React, {useCallback, useEffect, useState} from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {useForm} from 'react-hook-form';

import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {pushDataLayer} from 'lin3s-react-analytics';

import commentApi from '../../../../api/Backend/Comment';

import {commentFormSubmitOK, commentFormSubmitKO} from './../../../_util/dataLayerTransformation';

import Column from '../../../layouts/Column/Column';
import Row from '../../../layouts/Row/Row';
import {PrimaryButton} from '../../../atoms/Button/Button';
import Typography, {VARIANT} from '../../../atoms/Typography/Typography';

import './CommentForm.scss';

const CaptchaComponent = ({setToken}) => {
  const {executeRecaptcha} = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('submit');

    setToken(token);
  }, [executeRecaptcha, setToken]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return <></>;
};

const Success = () => (
  <Row>
    <Column>
      <Typography variant={VARIANT.HEADING_SMALL}>
        <FormattedMessage id="form.success" />
      </Typography>
    </Column>
  </Row>
);

const Error = () => (
  <Row>
    <Column>
      <Typography variant={VARIANT.HEADING_SMALL}>
        <FormattedMessage id="form.error" />
      </Typography>
    </Column>
  </Row>
);
const CommentForm = ({postId, intl}) => {
  const GOOGLE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [token, setToken] = useState('');

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);

  const onSubmit = async data => {
    setError(false);
    setSuccess(false);

    try {
      await commentApi({
        author_email: data.email,
        author_name: `${data.name} ${data.surname}`,
        content: data.comment,
        post: data.post,
        token: token,
      });

      setSuccess(true);
      pushDataLayer(commentFormSubmitOK());
      reset();
    } catch (e) {
      setError(true);
      pushDataLayer(commentFormSubmitKO());
    }

    setVisible(false);
  };

  return (
    <div className="form__form">
      {!visible && (
        <Row className="comment-form-toggle" scrollAnimation={false}>
          <Column>
            <button className="typography typography--body-small button" onClick={() => setVisible(true)}>
              <FormattedMessage id="form.button" />
            </button>
          </Column>
        </Row>
      )}
      {
        <form
          className={`form form__content comment-form ${visible ? 'comment-form--visible' : ''}`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row scrollAnimation={false}>
            <Column className="form__form-column--half">
              <Typography variant={VARIANT.HEADING}>
                <FormattedMessage id="inspiringBlog.comments.title" />
              </Typography>
            </Column>
          </Row>
          <Row>
            <Column className="form__form-column--half">
              <div className="input">
                <label className="input__label" htmlFor="First name">
                  <Typography className="input__label" variant={VARIANT.LABEL}>
                    <FormattedMessage id="form.input.first_name.label" />
                  </Typography>
                </label>
                <input
                  className="input__input"
                  type="text"
                  {...register('name', {required: intl.formatMessage({id: 'form.required'}), maxLength: 80})}
                />
              </div>
              {errors.name && errors.name.message && <p className="error">{errors.name.message}</p>}
            </Column>
            <Column className="form__form-column--half">
              <div className="input">
                <label className="input__label" htmlFor="Last name">
                  <Typography className="input__label" variant={VARIANT.LABEL}>
                    <FormattedMessage id="form.input.last_name.label" />
                  </Typography>
                </label>
                <input
                  className="input__input"
                  type="text"
                  {...register('surname', {required: intl.formatMessage({id: 'form.required'}), maxLength: 100})}
                />
              </div>
              {errors.surname && errors.surname.message && <p className="error">{errors.surname.message}</p>}
            </Column>

            <Column className="form__form-column--half">
              <div className="input">
                <label className="input__label" htmlFor="Email">
                  <Typography className="input__label" variant={VARIANT.LABEL}>
                    <FormattedMessage id="form.input.email.label" />
                  </Typography>
                </label>
                <input
                  className="input__input"
                  type="text"
                  {...register('email', {
                    required: intl.formatMessage({id: 'form.required'}),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: intl.formatMessage({id: 'form.error.format'}),
                    },
                  })}
                />
              </div>
              {errors.email && errors.email.message && <p className="error">{errors.email.message}</p>}
            </Column>
            <Column className="form__form-column--full">
              <div className="input">
                <label className="input__label" htmlFor="Comment">
                  <Typography className="input__label" variant={VARIANT.LABEL}>
                    <FormattedMessage id="form.input.comment.label" />
                  </Typography>
                </label>
                <textarea
                  {...register('comment', {required: intl.formatMessage({id: 'form.required'})})}
                  className="standard"
                  cols="40"
                  rows="10"
                />
              </div>
              {errors.comment && errors.comment.message && <p className="error">{errors.comment.message}</p>}
            </Column>

            <Column className="form__form-column--full">
              <Typography className="input__label" variant={VARIANT.FOOTER_SMALL}>
                <FormattedMessage id="form.mandatory" />
              </Typography>
            </Column>

            <Column className="form__form-column--full">
              <div className="form__form-checkbox-content">
                <input
                  className="form-checkbox"
                  type="checkbox"
                  {...register('legal', {required: intl.formatMessage({id: 'form.required'})})}
                />
                <label className="inline" htmlFor="legal">
                  <FormattedHTMLMessage id="form.input.legal.label" />
                </label>
              </div>

              {errors.legal && errors.legal.message && <p className="error">{errors.legal.message}</p>}
            </Column>
          </Row>
          <Row scrollAnimation={false}>
            <Column>
              <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_KEY}>
                <CaptchaComponent setToken={setToken} />
              </GoogleReCaptchaProvider>
              <input type="hidden" value={postId} {...register('post')} />
              <PrimaryButton className="form__form-button-button" type="submit">
                <FormattedMessage id="form.button" />
              </PrimaryButton>
            </Column>
          </Row>
        </form>
      }
      {success && <Success />}
      {error && <Error />}
    </div>
  );
};

export default injectIntl(CommentForm);
