import React from 'react';

import Picture, {RATIO} from '../../../atoms/Picture/Picture';

const Banner = ({image, url, ratio}) => (
  <>
    <a href={url}>
      <Picture
        alt={image.alt}
        className=""
        cover
        imageClassName=""
        pictureClassName=""
        ratio={ratio || RATIO.R_1_1}
        src={image.src.large}
        srcSet={[
          {src: image.src.small, maxWidth: 639},
          {src: image.src.medium, maxWidth: 1279},
        ]}
      />
    </a>
  </>
);
export default Banner;
