import React, {useState} from 'react';
import {compose} from 'redux';
import VisibilitySensor from 'react-visibility-sensor';

import withSize from './../../../../_packages/resize/ui/withSize';
import BREAKPOINTS from './../../../_config/breakpoints';

import Picture, {RATIO} from './../../../atoms/Picture/Picture';
import AnimatedInlineButton from './../../../atoms/AnimatedInlineButton/AnimatedInlineButton';

import CmsNumbers from './../../../components/CmsComponents/CmsNumbers/CmsNumbers';
import CmsLink from './../../../components/CmsLink/CmsLink';

import Slider from './../../../compositions/Slider/Slider';

import FullRow from './../../../layouts/FullRow/FullRow';

import './TecnaliaModule.scss';

const TecnaliaModule = ({content, windowWidth}) => {
  const [visible, setVisible] = useState(false);

  return (
    <VisibilitySensor active={!visible} onChange={isVisible => setVisible(isVisible)} partialVisibility>
      <FullRow className="tecnalia-module">
        <Slider infiniteLoop slidesPerBreakpoint={{S: 1}}>
          {content.slides.map((element, index) => (
            <div className="tecnalia-module__slide" key={index}>
              <Picture
                alt={element.image.alt}
                cover
                ratio={windowWidth >= BREAKPOINTS.M ? RATIO.R_8_3 : RATIO.R_1_1}
                src={element.image.src.original}
                srcSet={[
                  {src: element.image.src.small, maxWidth: 479},
                  {src: element.image.src.medium, maxWidth: 639},
                  {src: element.image.src.large, maxWidth: 1279},
                ]}
              />
              {visible && (
                <div className="tecnalia-module__info">
                  <div className="tecnalia-module__info-numbers">
                    <CmsNumbers content={element.numbers.left} />
                  </div>
                  <div className="tecnalia-module__info-numbers">
                    <CmsNumbers content={element.numbers.right} />
                  </div>
                  {windowWidth >= BREAKPOINTS.M && element.cta.text && (
                    <CmsLink className="tecnalia-module__info-link" slug={element.cta.url}>
                      <AnimatedInlineButton text={element.cta.text} />
                    </CmsLink>
                  )}
                </div>
              )}
              {windowWidth < BREAKPOINTS.M && element.cta.text && (
                <CmsLink className="tecnalia-module__info-link--mobile" slug={element.cta.url}>
                  <AnimatedInlineButton text={element.cta.text} />
                </CmsLink>
              )}
            </div>
          ))}
        </Slider>
      </FullRow>
    </VisibilitySensor>
  );
};

export default compose(withSize)(TecnaliaModule);
