import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import {getMonthFromDate, getDayNumberFromDate} from '../../_util/getDateFromString';

import Picture, {RATIO} from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import TitleTextComponent from './../TitleTextComponent/TitleTextComponent';

import './GenericPreview.scss';

const GenericPreview = ({
  biggerImage,
  className,
  content,
  horizontal,
  label,
  preview,
  rawContent,
  renderContainer,
  intl,
}) => (
  <div className={classNames('generic-preview__wrapper', className)}>
    {renderContainer(
      <article
        className={classNames('generic-preview', {
          'generic-preview--horizontal': horizontal,
          'generic-preview--bigger-image': biggerImage,
        })}
      >
        <div className="generic-preview__picture">
          {preview.image && (
            <Picture
              alt={preview.image.alt || preview.image.title}
              className="generic-preview__picture-image"
              cover
              ratio={biggerImage ? RATIO.R_16_9 : RATIO.R_3_2}
              src={preview.image.src.medium}
              srcSet={[
                {
                  src: preview.image.src.small,
                  maxWidth: 480,
                },
              ]}
            />
          )}
          {preview.createdAt && (
            <div className="generic-preview__date">
              <Typography variant={VARIANT.BODY_SMALLER}>{getDayNumberFromDate(preview.createdAt)}</Typography>
              <Typography variant={VARIANT.BODY_XSMALL_REGULAR}>
                {getMonthFromDate(preview.createdAt, intl.locale)}
              </Typography>
            </div>
          )}
        </div>

        <div className="generic-preview__content">
          <TitleTextComponent raw={rawContent} text={preview.title} textTag="p" title={label} titleTag="h2" />
          {content}
        </div>
      </article>,
    )}
  </div>
);

GenericPreview.propTypes = {
  biggerImage: PropTypes.bool,
  content: PropTypes.node,
  horizontal: PropTypes.bool,
  label: PropTypes.string,
  preview: PropTypes.object,
  renderContainer: PropTypes.func,
};

GenericPreview.defaultProps = {
  biggerImage: false,
  horizontal: false,
  renderContainer: children => children,
};

export default compose(injectIntl, React.memo)(GenericPreview);
