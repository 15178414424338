import React from 'react';
import PropTypes from 'prop-types';
import {getLocale} from '@foes/react-i18n-routing/dist/common/locale';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import {getMonthFromDate, getDayNumberFromDate} from './../../_util/getDateFromString';

import './Agenda.scss';

const Agenda = ({item, renderContainer}) => (
  <div className="agenda__content-text">
    <div className="agenda__content-date">
      <Typography className="agenda__content-number" variant={VARIANT.NUMBER_SMALL}>
        {getDayNumberFromDate(item.dateTimeRange.startDate)}
      </Typography>
      <div className="agenda__content-date-details">
        <Typography className="agenda__content-month" variant={VARIANT.HEADING_SMALL}>
          {getMonthFromDate(item.dateTimeRange.startDate, getLocale())}
        </Typography>
      </div>
    </div>
    {renderContainer(
      <Typography className="agenda__content-event" variant={VARIANT.BODY}>
        {item.title}
      </Typography>,
    )}
  </div>
);

Agenda.propTypes = {
  // TODO this will change once the info is in the CMS
  item: PropTypes.shape({
    dayNumber: PropTypes.string,
    dayWeek: PropTypes.string,
    month: PropTypes.string,
    title: PropTypes.string,
  }),
  renderContainer: PropTypes.func,
};

Agenda.defaultProps = {
  renderContainer: children => children,
};

export default React.memo(Agenda);
