import React from 'react';
import PropTypes from 'prop-types';
import {Trail, Spring} from 'react-spring/renderprops.cjs.js';
import classNames from 'classnames';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Picture, {RATIO} from './../../atoms/Picture/Picture';

import HeroCategoryTitle from './../../components/HeroCategoryTitle/HeroCategoryTitle';

import FullRow from './../../layouts/FullRow/FullRow';
import Breadcrumbs from './../../layouts/Breadcrumbs/Breadcrumbs';

import './GenericHero.scss';

const GenericHero = ({color, image, title, type, backgroundImage, ambitHero, redGradient}) => (
  <FullRow
    className={classNames('generic-hero', {
      'ambit-hero--with-background': ambitHero,
      'generic-hero--with-background': backgroundImage && !ambitHero,
      'generic-hero--red-gradient': redGradient && !ambitHero,
      'generic-hero--gradient': color && color !== 'none' && !ambitHero,
      [`ambit-hero-text--${ambitHero && ambitHero.textColor}`]: ambitHero && ambitHero.hasOwnProperty('textColor'),
      [`generic-hero--${color}`]: color && color !== 'none' && !ambitHero,
    })}
    scrollAnimation={false}
  >
    <div className="generic-hero__column generic-hero__column-content">
      <Trail
        config={{tension: 210, friction: 35, delay: 500}}
        from={{opacity: 0, transform: 'translateY(100px)'}}
        items={[
          <Breadcrumbs key="breadcrumbs" />,
          <HeroCategoryTitle key="content" title={title} titleIcon="TITLE_ICON" type={type} />,
        ]}
        keys={item => item.key}
        to={{opacity: 1, transform: 'translateY(0)'}}
      >
        {item => props => <div style={props}>{item}</div>}
      </Trail>
    </div>
    {image && (
      <Spring
        config={{tension: 280, friction: 90, clamp: true}}
        delay={1200}
        from={{transform: 'scale(0)'}}
        to={{transform: 'scale(1)'}}
      >
        {props => (
          <div className="generic-hero__column generic-hero__picture" style={props}>
            <Picture
              alt={image.alt}
              src={image.src.large}
              srcSet={[
                {
                  src: image.src.small,
                  maxWidth: 480,
                },
              ]}
            />
          </div>
        )}
      </Spring>
    )}
    {backgroundImage && (
      <Picture
        alt={backgroundImage.alt}
        className="generic-hero__background"
        cover
        ratio={RATIO.R_16_9}
        src={backgroundImage.src.large}
        srcSet={[
          {src: backgroundImage.src.medium, maxWidth: 639},
          {src: backgroundImage.src.large, maxWidth: 1279},
        ]}
      />
    )}
    {ambitHero && (
      <Picture
        alt={ambitHero.desktopImage.alt}
        className="ambit-hero__background"
        cover
        ratio={RATIO.R_16_9}
        src={ambitHero.desktopImage.src.large}
        srcSet={[
          {
            src: ambitHero.hasOwnProperty('mobileImage')
              ? ambitHero.mobileImage.src.medium
              : ambitHero.desktopImage.src.medium,
            maxWidth: 767,
          },
          {src: ambitHero.desktopImage.src.large, maxWidth: 1279},
        ]}
      />
    )}
  </FullRow>
);

GenericHero.propTypes = {
  backgroundImage: responsiveImagePropType,
  color: PropTypes.string,
  image: responsiveImagePropType,
  redGradient: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

GenericHero.defaultProps = {
  redGradient: false,
};

export default React.memo(GenericHero);
