export const PROJECTS = 'projects';
export const PROJECT = 'project';

export const projectsRouteParams = {
  [PROJECTS]: {
    shouldPartiallyRender: true,
  },
  [PROJECT]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [PROJECTS]: {
    es: '/proyectos',
    en: '/projects',
    eu: '/proiektuak',
    fr: '/projets',
  },
  [PROJECT]: {
    es: '/proyectos/:slug',
    en: '/projects/:slug',
    eu: '/proiektuak/:slug',
    fr: '/projets/:slug',
  },
};
