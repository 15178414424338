import React from 'react';

import CmsLink from './../../components/CmsLink/CmsLink';

import Cms5050 from './../../components/CmsComponents/Cms5050/Cms5050';

const RoutingCms5050 = ({content, ...rest}) => (
  <Cms5050
    content={content}
    renderLink={children =>
      content.cta.ctaUrl ? <CmsLink slug={content.cta.ctaUrl}>{children}</CmsLink> : React.cloneElement(children)
    }
    {...rest}
  />
);

export default React.memo(RoutingCms5050);
