import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import LandingComponent from './../../layouts/LandingComponent/LandingComponent';
import Page from './../../layouts/Page/Page';

import './Landing.scss';

const Landing = ({canonical, content}) => (
  <Page canonical={canonical} className="landing" title={content.title}>
    {content.fields && (
      <LandingComponent
        campaignKey={content.fields.campaignKey}
        className="landing__content"
        items={content.fields.sections}
        scrollAnimation={false}
        webKey={content.fields.webKey}
      />
    )}
  </Page>
);

export default compose(injectIntl, React.memo)(Landing);
