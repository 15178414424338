export const AMBIT = 'ambit';
export const AMBITS = 'ambits';

export const ambitsRouteParams = {
  [AMBITS]: {
    shouldPartiallyRender: true,
  },
  [AMBIT]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [AMBIT]: {
    es: '/ambitos/:slug',
    en: '/scopes-of-action/:slug',
    eu: '/jarduera-eremuak/:slug',
    fr: '/domaines-de-action/:slug',
  },
  [AMBITS]: {
    es: '/ambitos',
    en: '/scopes-of-action',
    eu: '/jarduera-eremuak',
    fr: '/domaines-de-action',
  },
};
