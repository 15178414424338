import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import i18n from './../../../i18n';

// eslint-disable-next-line max-len
import RoutingInspiringBlogPostPreview from '../../_bridges/RoutingInspiringBlogPostPreview/RoutingInspiringBlogPostPreview';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingRelatedTagsCategory from '../../_bridges/RoutingRelatedTagsCategory/RoutingRelatedTagsCategory';
import RoutingRelatedTags from '../../_bridges/RoutingRelatedTags/RoutingRelatedTags';

import inspiringBlogPostPropType from '../../_propTypes/inspiringBlogPostPropType';

import {getFullFormatDate} from '../../_util/getDateFromString';
import {getPostType} from '../../_util/routes';

import BlogPostMicrodata from './../../seo/Microdata/BlogPostMicrodata';

import Content from '../../atoms/Content/Content';
import {VARIANT} from '../../atoms/Typography/Typography';
import Picture, {RATIO} from '../../atoms/Picture/Picture';

import TableOfContent from '../../components/TableOfContents/TableOfContent';
import AuthorBiography from '../../components/AuthorBiography/AuthorBiography';

import Hero, {HERO_TYPE_BLOG} from '../../compositions/Hero/Hero';
import RelatedContentCards from '../../compositions/RelatedContentCards/RelatedContentCards';

import MainLayout from '../../layouts/MainLayout/MainLayout';
import CmsContent from '../../layouts/CmsContent/CmsContent';
import Row from '../../layouts/Row/Row';
import Column from '../../layouts/Column/Column';

import {AMBIT} from '../../../routing/ambits/routes';
import {INSPIRING_BLOG_CATEGORY, INSPIRING_BLOG_TAG} from '../../../routing/inspiringBlog/routes';

import './InspiringBlogPost.scss';
import Comments from './Comments/Comments';
import Form from '../../components/Forms/Form';
import CommentForm from './CommentForm/CommentForm';

const InspiringBlogPost = ({canonical, content: inspiringBlog, intl, translatedSlugs}) => {
  const [content, setContent] = useState(inspiringBlog.content);

  const getLanguageLink = (lang, key, last) =>
    translatedSlugs.current !== translatedSlugs[lang] ? (
      <a
        className="hero__links-link hero__links-link-anchor"
        href={translatedSlugs[lang]}
        key={key}
        title={`link to ${lang} post`}
      >
        {`${intl.formatMessage({id: `language.${[lang]}`})}${!last ? ', ' : ''}`}
      </a>
    ) : (
      <span className="hero__links-link" key={key}>
        {`${intl.formatMessage({id: `language.${[lang]}`})}${!last ? ', ' : ''}`}
      </span>
    );

  const links = Object.keys(translatedSlugs).filter(item => translatedSlugs[item] !== '/' && item !== 'current');
  return (
    <div className="inspiringBlog">
      <Hero
        date={getFullFormatDate(inspiringBlog.createdAt, intl.locale)}
        extraInfo={
          <div className="hero__links">
            {intl.formatMessage({id: 'language'})}
            {links.map((lang, index) => getLanguageLink(lang, index, index === links.length - 1))}
          </div>
        }
        image={inspiringBlog.image}
        inverted
        title={inspiringBlog.title}
        type={intl.formatMessage({id: 'blog.category'})}
        variant={HERO_TYPE_BLOG}
        vertical
      />
      <MainLayout
        asideContent={
          <>
            <RoutingExpertModule
              formId={inspiringBlog.inventory.authorSalesforceId}
              formInfo={inspiringBlog.form && inspiringBlog.form.find(form => form.type === 'expert')}
              imgAlt={inspiringBlog.author.name}
              imgSrc={inspiringBlog.author.image}
              isInspiringBlogAuthor
              name={inspiringBlog.author.name}
              position={inspiringBlog.author.position}
              templateTitle={inspiringBlog.title}
              titleTag="span"
            />
            {inspiringBlog.banners &&
              inspiringBlog.banners.slice(0, 1).map((banner, key) => (
                <a href={banner.url} key={key}>
                  <Picture
                    alt={banner.alt || banner.title}
                    className="inspiringblog__aside__banner"
                    cover
                    ratio={RATIO.R_3_2}
                    src={banner.image.src.medium}
                    srcSet={[
                      {
                        src: banner.image.src.small,
                        maxWidth: 480,
                      },
                    ]}
                  />
                </a>
              ))}
          </>
        }
        canonical={canonical}
        mainContent={
          <>
            <Content className="inspiringblog__main-excerpt" raw variant={VARIANT.H3}>
              {inspiringBlog.excerpt}
            </Content>
            <TableOfContent className="inspiringblog__main-toc" content={content} setContent={setContent} />
            <CmsContent
              content={[
                {
                  type: 'TextComponent',
                  fields: {text: content},
                },
              ]}
            />
            <AuthorBiography author={inspiringBlog.author} />
          </>
        }
        socialText={inspiringBlog.title}
      />

      {inspiringBlog.newsletter && inspiringBlog.newsletter.form && (
        <Row>
          <Column className="inspiringblog__main-bottom-column">
            <Form formInfo={inspiringBlog.newsletter.form[0]} fullHeight />
          </Column>
        </Row>
      )}

      {inspiringBlog.comments && <Comments comments={inspiringBlog.comments} />}

      <CommentForm postId={inspiringBlog.id} />

      {inspiringBlog.categories && inspiringBlog.categories.items && inspiringBlog.categories.items.length > 0 && (
        <Row className="inspiring-blog__related-tags">
          <Column className="inspiringblog__main-bottom-column">
            <RoutingRelatedTagsCategory
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={inspiringBlog.categories.items}
              title={inspiringBlog.categories.title}
              to={INSPIRING_BLOG_CATEGORY}
            />
          </Column>
        </Row>
      )}

      {inspiringBlog.tags && inspiringBlog.tags.items && inspiringBlog.tags.items.length > 0 && (
        <Row className="inspiring-blog__related-tags">
          <Column className="inspiringblog__main-bottom-column">
            <RoutingRelatedTagsCategory
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={inspiringBlog.tags.items}
              title={inspiringBlog.tags.title}
              to={INSPIRING_BLOG_TAG}
            />
          </Column>
        </Row>
      )}

      {inspiringBlog.ambits && inspiringBlog.ambits.items && inspiringBlog.ambits.items.length > 0 && (
        <Row>
          <Column className="inspiringblog__main-bottom-column">
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={inspiringBlog.ambits.items}
              title={inspiringBlog.ambits.title}
              to={AMBIT}
            />
          </Column>
        </Row>
      )}

      {inspiringBlog.sectors && inspiringBlog.sectors.items && inspiringBlog.sectors.items.length > 0 && (
        <Row>
          <Column className="inspiringblog__main-bottom-column">
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={inspiringBlog.sectors.items}
              title={inspiringBlog.sectors.title}
            />
          </Column>
        </Row>
      )}

      {inspiringBlog.relatedByAmbit && inspiringBlog.relatedByAmbit.items.length > 0 && (
        <RelatedContentCards
          scrollAnimation={false}
          slidesPerBreakpoint={{S: 3}}
          title={inspiringBlog.relatedByAmbit.title}
          titleBig
        >
          {inspiringBlog.relatedByAmbit.items.map((card, index) => (
            <RoutingInspiringBlogPostPreview key={index} preview={card} to={getPostType(card.type)} />
          ))}
        </RelatedContentCards>
      )}

      <BlogPostMicrodata canonical={canonical} post={inspiringBlog} />
    </div>
  );
};

InspiringBlogPost.propTypes = {
  canonical: PropTypes.string,
  content: inspiringBlogPostPropType,
};

const mapStateToProps = state => ({
  translatedSlugs:
    state.api.backendPage && state.api.backendPage.resource
      ? state.api.backendPage.resource.translatedSlugs
      : i18n.defaultTranslatedRoutes,
});

export default compose(connect(mapStateToProps), injectIntl, React.memo)(InspiringBlogPost);
