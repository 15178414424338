import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

// eslint-disable-next-line max-len
import RoutingInspiringBlogPostPreview from '../../../_bridges/RoutingInspiringBlogPostPreview/RoutingInspiringBlogPostPreview';

import {VARIANT} from '../../../atoms/Typography/Typography';

import RelatedContentCards from './../../../compositions/RelatedContentCards/RelatedContentCards';

import FullRow from './../../../layouts/FullRow/FullRow';

import './PostsModule.scss';
import {getPostType} from '../../../_util/routes';

const PostsModule = ({content, defaultButtons}) => (
  <FullRow className="posts-module" scrollAnimation={false} tag="section">
    <RelatedContentCards
      invertedButtons={defaultButtons ? false : true}
      scrollAnimation={false}
      slidesPerBreakpoint={{S: 1, L: 3, XXXXL: 3}}
      subtitle={content.subtitle || ''}
      title={content.title}
      titleTag="h2"
      titleVariant={VARIANT.HEADING}
    >
      {content.items.map((item, index) => (
        <RoutingInspiringBlogPostPreview key={index} preview={item} to={getPostType(item.type)} />
      ))}
    </RelatedContentCards>
  </FullRow>
);

export default compose(injectIntl, React.memo)(PostsModule);
