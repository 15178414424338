import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
);
