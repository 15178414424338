import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

import './Paginator.scss';

/**
 * onPageChange prop is used for the navigation. hrefBuilder prop is used for accesibily,
 * not for real navigation (the underlying event handler is doing a evt.preventDefault)
 */

const Paginator = ({currentPage, formatPageRoute, pushRoute, totalPages}) => (
  <ReactPaginate
    activeClassName="paginator__item--active"
    containerClassName="paginator"
    disableInitialCallback={true}
    disabledClassName="paginator__item--disabled"
    hrefBuilder={page => formatPageRoute(parseInt(page, 10))}
    initialPage={currentPage - 1}
    marginPagesDisplayed={1}
    nextClassName="paginator__item"
    nextLabel=">|"
    onPageChange={paging => pushRoute(formatPageRoute(parseInt(paging.selected, 10) + 1))}
    pageClassName="paginator__item"
    pageCount={totalPages}
    pageRangeDisplayed={2}
    previousClassName="paginator__item"
    previousLabel="|<"
  />
);

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  formatPageRoute: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => ({
  pushRoute: bindActionCreators(push, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(React.memo(Paginator));
