import {FormattedLink, withI18nRouting} from '@foes/react-i18n-routing';
import classNames from 'classnames';
import React from 'react';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';
import {HOME} from './../../../routing/routes';

import Picture from './../../atoms/Picture/Picture';

import FullRow from './../../layouts/FullRow/FullRow';

import LandingLanguageSelector from '../../components/Landing/LandingLanguageSelector/LandingLanguageSelector';

import './LandingHeader.scss';

const LandingHeader = ({i18nRouting: {locale}, mode}) => (
  <header className={classNames('landing-header')} id="landing-header">
    <FullRow className="landing-header__wrapper" scrollAnimation={false}>
      <React.Fragment>
        <div className="landing-header__logo">
          <FormattedLink name={HOME}>
            <Picture alt="tecnalia-logo" src={mode === 'dark' ? '/images/logo-white.svg' : '/images/logo.svg'} />
          </FormattedLink>
        </div>
        <LandingLanguageSelector currentLocale={locale} mode={mode} />
      </React.Fragment>
    </FullRow>
  </header>
);

export default compose(withI18nRouting, withSize, React.memo)(LandingHeader);
