import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, A11y} from 'swiper';
// https://swiper6.vercel.app/
import 'swiper/swiper.scss';
import './LandingGallery.scss';

SwiperCore.use([Navigation, A11y]);

const LandingGallery = ({images, label}) => (
  <div className="landing-gallery">
    <h2 className="fs--label">{label}</h2>
    <div className="landing-gallery__slider">
      <Swiper
        navigation={{
          nextEl: '.landing-gallery__navigation-next',
          prevEl: '.landing-gallery__navigation-prev',
        }}
        slidesPerView="auto"
        spaceBetween={15}
      >
        {images.map(image => (
          <SwiperSlide>
            <div className="landing-gallery__item">
              <img alt={image.image.alt} src={image.image.src.medium}></img>
              <span className="fs--body landing-gallery__item-caption">{image.text}</span>
            </div>
          </SwiperSlide>
        ))}
        <div className="landing-gallery__navigation">
          <button className="fs--body landing-gallery__navigation-prev">
            <svg fill="none" height="17" viewBox="0 0 10 17" width="10" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.98047 16.6572L0.960326 8.63682L8.98047 0.616674"
                stroke="var(--landing-color-neutral-800)"
                strokeWidth="0.5"
              />
            </svg>
          </button>
          <button className="fs--body landing-gallery__navigation-next">
            <svg fill="none" height="17" viewBox="0 0 10 17" width="10" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.01953 0.617065L9.03968 8.63748L1.01953 16.6576"
                stroke="var(--landing-color-neutral-800)"
                strokeWidth="0.5"
              />
            </svg>
          </button>
        </div>
      </Swiper>
    </div>
  </div>
);

export default LandingGallery;
