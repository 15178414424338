import React from 'react';
import PropTypes from 'prop-types';
import {FormattedLink} from '@foes/react-i18n-routing';

import FeaturedLink from './../../components/FeaturedLink/FeaturedLink';
import CmsLink from './../../components/CmsLink/CmsLink';

const RoutingFeaturedLink = ({area, slug, to, ...rest}) => (
  <FeaturedLink
    area={area}
    renderContainer={children =>
      area && area.slug ? (
        <FormattedLink name={to} params={{slug: area.slug}}>
          {children}
        </FormattedLink>
      ) : slug ? (
        <CmsLink slug={slug}>{children}</CmsLink>
      ) : (
        React.cloneElement(children)
      )
    }
    {...rest}
  />
);

RoutingFeaturedLink.propTypes = {
  area: PropTypes.object,
  to: PropTypes.string,
};

export default React.memo(RoutingFeaturedLink);
