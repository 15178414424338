import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
import RoutingFeaturedLink from './../../_bridges/RoutingFeaturedLink/RoutingFeaturedLink';

import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {PRESS_RELEASE} from './../../../routing/pressReleases/routes';

import withSize from './../../../_packages/resize/ui/withSize';
import {getFullFormatDate} from './../../_util/getDateFromString';

import {ICON} from './../../atoms/Iconography/Iconography';
import Content from './../../atoms/Content/Content';

import Hero from './../../compositions/Hero/Hero';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';

import MainLayout from './../../layouts/MainLayout/MainLayout';

import './PressRelease.scss';

const PressRelease = ({canonical, content: pressRelease, intl}) => (
  <div className="press-release">
    <Hero
      date={getFullFormatDate(pressRelease.createdAt, intl.locale)}
      image={pressRelease.image}
      inverted
      title={pressRelease.title}
      type={intl.formatMessage({id: 'press.release.category'})}
    />

    <MainLayout
      asideContent={
        <React.Fragment>
          {pressRelease.relatedContact &&
            pressRelease.relatedContact.items.length > 0 &&
            pressRelease.relatedContact.items.map((contact, index) => (
              <RoutingExpertModule
                expertPageSlug={contact.slug}
                formId={pressRelease.formId.id}
                formInfo={pressRelease.form.find(form => form.type === 'expert')}
                hidden={pressRelease.relatedContact.simplified}
                imgAlt={contact.title}
                imgSrc={contact.image.src.small}
                key={`relatedContact${index}`}
                name={contact.title}
                position={contact.position}
                templateTitle={pressRelease.title}
              />
            ))}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <React.Fragment>
          <Content className="press-release__main-content" raw>
            {pressRelease.content}
          </Content>
          {pressRelease.technologies && pressRelease.technologies.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={pressRelease.technologies.items}
              title={pressRelease.technologies.title}
              to={TECHNOLOGY}
            />
          )}
        </React.Fragment>
      }
      socialText={pressRelease.title}
      topContent={
        pressRelease.file && pressRelease.file.file ? (
          <RoutingFeaturedLink
            className="press-release__download"
            icon={ICON.DOWNLOAD}
            slug={pressRelease.file.file}
            title={pressRelease.file.text}
            uppercaseTitle
          />
        ) : null
      }
    />

    {pressRelease.relatedMediaGallery.elements &&
      pressRelease.relatedMediaGallery.elements.length > 0 &&
      pressRelease.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={pressRelease.relatedMediaGallery} />
      )}

    {pressRelease.relatedPressRelease && pressRelease.relatedPressRelease.length > 0 && (
      <RelatedContentCards
        className="press-release__related-press-releases"
        slidesPerBreakpoint={{S: 1, L: 2, XXL: 3, XXXXL: 4}}
        title={intl.formatMessage({id: 'press.release.related'})}
        withBackground
      >
        {pressRelease.relatedPressRelease.map((project, index) => (
          <RoutingGenericPreview
            key={index}
            label={intl.formatMessage({id: 'press.release.category'})}
            preview={project}
            to={PRESS_RELEASE}
            withBackground
          />
        ))}
      </RelatedContentCards>
    )}
  </div>
);

export default compose(withSize, injectIntl, React.memo)(PressRelease);
