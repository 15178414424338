import PropTypes from 'prop-types';

export default PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      document: PropTypes.shape({
        file: PropTypes.shape({
          name: PropTypes.string,
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
        linkText: PropTypes.string.isRequired,
      }),
    }),
  ),
});
