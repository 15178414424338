import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const EventMicrodata = ({event}) => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: event.name,
        description: event.description,
        image: event.image,
        startDate: event.startDate,
        endDate: event.endDate,
        eventStatus: 'https://schema.org/EventScheduled',
        eventAttendanceMode: event.online
          ? 'https://schema.org/OfflineEventAttendanceMode'
          : 'https://schema.org/OfflineEventAttendanceMode',
        location: {
          '@type': 'Place',
          name: event.place,
        },
      }),
    }}
    type="application/ld+json"
  />
);

EventMicrodata.propTypes = {
  canonical: PropTypes.string.isRequired,
  event: PropTypes.shape({
    description: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default React.memo(EventMicrodata);
