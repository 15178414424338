import PropTypes from 'prop-types';

import responsiveImagePropType from './responsiveImagePropType';
import relatedTagsPropType from './relatedTagsPropType';
import relatedMediaGalleryPropType from './relatedMediaGalleryPropType';
import relatedResearcherPropType from './relatedResearcherPropType';
import relatedCardPropType from './relatedCardPropType';
import relatedPartnerGroupsPropType from './relatedPartnerGroupsPropType';
import catalogPropType from './catalogPropType';

export default PropTypes.shape({
  ambits: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.text,
        title: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }).isRequired,
  catalog: catalogPropType,
  content: PropTypes.array.isRequired,
  detailedExcerpt: PropTypes.string,
  image: responsiveImagePropType.isRequired,
  relatedContact: relatedResearcherPropType.isRequired,
  relatedMediaGallery: relatedMediaGalleryPropType.isRequired,
  relatedProject: relatedCardPropType.isRequired,
  relatedService: relatedCardPropType.isRequired,
  technologies: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: responsiveImagePropType,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  relatedPartnerGroups: relatedPartnerGroupsPropType.isRequired,
  relatedBusinessOpportunity: relatedCardPropType.isRequired,
  sectors: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: relatedTagsPropType.isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});
