export const EVENTS = 'events';
export const EVENTS_PAST = 'events_past';
export const EVENT = 'event';

export const eventsRouteParams = {
  [EVENTS]: {
    shouldPartiallyRender: true,
  },
  [EVENTS_PAST]: {
    shouldPartiallyRender: true,
  },
  [EVENT]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [EVENTS]: {
    es: '/agenda',
    en: '/agenda',
    eu: '/agenda',
    fr: '/agenda',
  },
  [EVENTS_PAST]: {
    es: '/agenda/pasados',
    en: '/agenda/previous',
    eu: '/agenda/aurrekoak',
    fr: '/agenda/precedent',
  },
  [EVENT]: {
    es: '/agenda/:slug',
    en: '/agenda/:slug',
    eu: '/agenda/:slug',
    fr: '/agenda/:slug',
  },
};
