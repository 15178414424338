import React from 'react';

import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';

import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';

import GenericHero from './../../compositions/GenericHero/GenericHero';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import CmsContent from './../../layouts/CmsContent/CmsContent';
import GenericItems from './../../layouts/GenericItems/GenericItems';

import './Page.scss';

const getGenericHeroType = template => {
  switch (template) {
    case 'flexible': {
      return 'Tecnalia';
    }
    case 'job': {
      return '';
    }
    default:
      return template;
  }
};

const Page = ({canonical, content: page}) => (
  <div title={page.title}>
    <div className="page">
      <GenericHero
        backgroundImage={(page.template !== 'legal' && page.image) || null}
        redGradient={page.template === 'flexible'}
        title={page.title}
        type={getGenericHeroType(page.template)}
      />
      {page.content && (
        <MainLayout
          asideContent={
            page.showSidebar.showSidebar && (
              <React.Fragment>
                {page.relatedContact.items.map((contact, index) => (
                  <RoutingExpertModule
                    expertPageSlug={contact.slug}
                    formId={page.formId.id}
                    formInfo={page.form.find(form => form.type === 'expert')}
                    hidden={page.relatedContact.simplified}
                    imgAlt={contact.title}
                    imgSrc={contact.image.src.small}
                    key={`relatedContact${index}`}
                    name={contact.title}
                    position={contact.position}
                    templateTitle={page.title}
                  />
                ))}
                {page.catalog.items.map((catalog, index) => (
                  <CatalogueDownload
                    catalogueInfo={catalog}
                    formId={page.formId.catalogId}
                    formInfo={page.form.find(form => form.type === 'download')}
                    key={index}
                  />
                ))}
              </React.Fragment>
            )
          }
          canonical={canonical}
          delay={0}
          mainContent={<CmsContent content={page.content} />}
          socialText={page.title}
        />
      )}
      {page.fields && <GenericItems items={page.fields.items} />}
    </div>
  </div>
);

export default React.memo(Page);
