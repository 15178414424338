import PropTypes from 'prop-types';

export default {
  hidden: PropTypes.bool,
  imgAlt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  renderContainer: PropTypes.func,
  formId: PropTypes.string,
  formInfo: PropTypes.object,
  position: PropTypes.string,
};
