import React from 'react';

import Video from './../../atoms/Video/Video';

import SearchBox from './../../components/SearchBox/SearchBox';

import FullRow from './../../layouts/FullRow/FullRow';

import './HomepageHero.scss';

const videoPlayerProps = {loop: true, muted: true, playing: true, playsinline: true};

const HomepageHero = ({video}) => (
  <FullRow className="homepage-hero" scrollAnimation={false}>
    {video.video && (
      <Video
        className="homepage-hero__video"
        controls={false}
        playerProps={videoPlayerProps}
        src={video.video.url}
        withRatio={false}
      />
    )}
    <div className="homepage-hero__search">
      <SearchBox id="homepage" showCloseIcon={false} />
    </div>
  </FullRow>
);

export default React.memo(HomepageHero);
