const FIELDS_FILTER = {
  POST_TYPE: 'posttype',
};

export {FIELDS_FILTER};

const getKeyByValue = (item, value) => Object.keys(item).find(key => item[key] === value);

export default ({queryString, strategyKeys}) =>
  Object.keys(queryString)
    .filter(key => getKeyByValue(strategyKeys, key))
    .reduce((acc, curr) => ({...acc, [curr]: queryString[curr]}), {});
