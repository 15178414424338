import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollAnimation from './../../_behaviours/ScrollAnimation';
import ConditionalWrap from './../../_util/ConditionalWrap';

import './Row.scss';

const Row = ({scrollAnimation, children, className, tag: RowTag, shouldDelayAnimation, ...rest}) => (
  <ConditionalWrap
    condition={scrollAnimation}
    wrap={content => (
      <ScrollAnimation shouldDelayAnimation={shouldDelayAnimation} {...rest}>
        {content}
      </ScrollAnimation>
    )}
  >
    <RowTag className={classNames('row', className)} scrollAnimation={scrollAnimation} {...rest}>
      {children}
    </RowTag>
  </ConditionalWrap>
);

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollAnimation: PropTypes.bool,
  shouldDelayAnimation: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Row.defaultProps = {
  tag: 'div',
  scrollAnimation: true,
};

export default React.memo(Row);
