export const extractSeoTwitter = (seo = {}) =>
  seo.twitter || {
    title: seo['twitter-title'],
    description: seo['twitter-description'],
    image: seo['twitter-image'],
  };

export const extractSeoOpengraph = (seo = {}) =>
  seo.opengraph || {
    title: seo['opengraph-title'],
    description: seo['opengraph-description'],
    image: seo['opengraph-image'],
  };
