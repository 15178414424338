import React, {useEffect} from 'react';
import {compose} from 'redux';
import {withI18nRouting} from '@foes/react-i18n-routing';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';

import './CmsCookiesList.scss';

const CmsCookiesList = ({i18nRouting}) => {
  let container = React.createRef();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'CookieDeclaration';
    script.src = `https://consent.cookiebot.com/${process.env.REACT_APP_COOKIEBOT_ID}/cd.js`;
    script.dataset.culture = i18nRouting.locale;
    script.async = true;
    container.appendChild(script);
  }, [container, i18nRouting.locale]);

  return (
    <Row>
      <Column>
        <div
          className="cookies-list"
          ref={divRef => {
            container = divRef;
          }}
        />
      </Column>
    </Row>
  );
};

export default compose(withI18nRouting, React.memo)(CmsCookiesList);
