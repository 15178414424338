export const PUBLICATIONS = 'publications';
export const PUBLICATION = 'publication';

export const publicationsRouteParams = {
  [PUBLICATIONS]: {
    shouldPartiallyRender: true,
  },
  [PUBLICATION]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [PUBLICATIONS]: {
    es: '/publicaciones',
    en: '/publications',
    eu: '/argitalpenak',
    fr: '/publications',
  },
  [PUBLICATION]: {
    es: '/publicacion/:slug',
    en: '',
    eu: '',
    fr: '',
  },
};
