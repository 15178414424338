import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {ACTIVE} from '../../../routing/actives/routes';
import {COMPANY} from '../../../routing/companies/routes';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';

import {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';
import TitleTextButton from './../../components/TitleTextButton/TitleTextButton';

import DataTable from './../../components/DataTable/DataTable';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import NewsAgendaModule from './../../compositions/NewsAgendaModule/NewsAgendaModule';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';

import CmsContent from './../../layouts/CmsContent/CmsContent';
import MainLayout from './../../layouts/MainLayout/MainLayout';

import Hero from './../../compositions/Hero/Hero';

import './BusinessOpportunity.scss';

const patentTableHeadRowFields = ['patent.key.title', 'patent.key.number'];

const BusinessOpportunity = ({canonical, content: businessOpportunity, intl}) => (
  <div className="business-opportunity">
    <Hero
      image={businessOpportunity.image}
      title={businessOpportunity.title}
      type={intl.formatMessage({
        id: businessOpportunity.type === 'active' ? 'business.opportunity.active' : 'business.opportunity.company',
      })}
    />

    <MainLayout
      asideContent={
        <React.Fragment>
          {businessOpportunity.relatedContact.items.map((contact, index) => (
            <RoutingExpertModule
              expertPageSlug={contact.slug}
              formId={businessOpportunity.formId.id}
              formInfo={businessOpportunity.form.find(form => form.type === 'expert')}
              hidden={businessOpportunity.relatedContact.simplified}
              imgAlt={contact.title}
              imgSrc={contact.image.src.small}
              key={`relatedContact${index}`}
              name={contact.title}
              position={contact.position}
              templateTitle={businessOpportunity.title}
            />
          ))}

          {businessOpportunity.whatWeSeek.title && (
            <TitleTextButton
              formId={businessOpportunity.formId.id}
              formInfo={businessOpportunity.form.find(form => form.type === 'expert')}
              templateTitle={businessOpportunity.title}
              text={businessOpportunity.whatWeSeek.text}
              title={businessOpportunity.whatWeSeek.title}
            />
          )}
          {businessOpportunity.catalog.items.map((catalog, index) => (
            <CatalogueDownload
              catalogueInfo={catalog}
              formId={businessOpportunity.formId.catalogId}
              formInfo={businessOpportunity.form.find(form => form.type === 'download')}
              key={index}
            />
          ))}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <React.Fragment>
          {businessOpportunity.featuredDescription && (
            <Content className="business-opportunity__featured-description" raw variant={VARIANT.H3}>
              {businessOpportunity.featuredDescription.featuredDescription}
            </Content>
          )}
          {businessOpportunity.technologies && businessOpportunity.technologies.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              className="business-opportunity__technologies"
              tags={businessOpportunity.technologies.items}
              title={businessOpportunity.technologies.title}
              to={TECHNOLOGY}
            />
          )}
          {businessOpportunity.sectors.items && businessOpportunity.sectors.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={businessOpportunity.sectors.items}
              title={businessOpportunity.sectors.title}
            />
          )}

          {businessOpportunity.content && <CmsContent content={businessOpportunity.content} />}
        </React.Fragment>
      }
    />

    {businessOpportunity.relatedPatent.items.length > 0 && (
      <DataTable
        keys={patentTableHeadRowFields}
        patents={businessOpportunity.relatedPatent.items}
        title={businessOpportunity.relatedPatent.title}
      />
    )}

    {businessOpportunity.relatedMediaGallery.elements &&
      businessOpportunity.relatedMediaGallery.elements.length > 0 &&
      businessOpportunity.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={businessOpportunity.relatedMediaGallery} />
      )}

    {((businessOpportunity.nextEvents && businessOpportunity.nextEvents.items.length > 0) ||
      businessOpportunity.relatedNews.items.length > 0) && (
      <NewsAgendaModule
        agendaItems={businessOpportunity.nextEvents && businessOpportunity.nextEvents.items}
        agendaTitle={businessOpportunity.nextEvents && businessOpportunity.nextEvents.title}
        newsCards={businessOpportunity.relatedNews.items}
        newsTitle={businessOpportunity.relatedNews.title}
      />
    )}

    {businessOpportunity.relatedBusinessOpportunity.items.length > 0 && (
      <RelatedContentCards title={businessOpportunity.relatedBusinessOpportunity.title} withBackground>
        {businessOpportunity.relatedBusinessOpportunity.items.map((item, index) => (
          <RoutingGenericPreview
            horizontal
            key={index}
            label={intl.formatMessage({
              id: item.type === 'active' ? 'business.opportunity.active' : 'business.opportunity.company',
            })}
            preview={item}
            to={item.type === 'active' ? ACTIVE : COMPANY}
          />
        ))}
      </RelatedContentCards>
    )}
  </div>
);

export default compose(injectIntl, React.memo)(BusinessOpportunity);
