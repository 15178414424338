import {cancelableGet} from './Client';

export default () => ({
  byPath: slug => {
    slug = slug.split('/');
    slug = slug[slug.length - 1];
    return cancelableGet(`/api/template?slug=/${slug}`);
  },
});

// export default locale => ({
//     byPath: ({page = 1, perPage = 12, slug = '', ...restParams}) =>
//       cancelableGet(`/api/page?path=${normalizeSlug(slug)}&page=${page}&per_page=${perPage}&lang=${locale}`, {
//         params: restParams,
//         headers: {
//           'Accept-Language': locale,
//         },
//       }),
//   });
