import React from 'react';
import {Link} from 'react-router-dom';
import {withI18nRouting} from '@foes/react-i18n-routing';
import {PropTypes} from 'prop-types';

const spreadQuery = query =>
  Object.keys(query)
    .map(e => `${encodeURIComponent(e)}=${encodeURIComponent(query[e])}`)
    .join('&');

const FormattedLink = ({name, params, i18nRouting, query, ...rest}) => (
  <Link
    to={`${i18nRouting.formatIntlRoute(name, params, i18nRouting.locale)}${query && `?${spreadQuery(query)}`}`}
    {...rest}
  />
);
FormattedLink.propTypes = {
  name: PropTypes.string,
  query: PropTypes.string,
};

export default withI18nRouting(FormattedLink);
