import React from 'react';

import CmsLink from './../../components/CmsLink/CmsLink';
import SocialSharePrint from './../../components/SocialSharePrint/SocialSharePrint';

const RoutingSocialSharePrint = ({...rest}) => (
  <SocialSharePrint
    renderButton={(children, slug, index) => (
      <CmsLink key={index} slug={slug}>
        {children}
      </CmsLink>
    )}
    {...rest}
  />
);

export default React.memo(RoutingSocialSharePrint);
