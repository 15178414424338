import React from 'react';
import PropTypes from 'prop-types';

import ConditionalWrap from './../../_util/ConditionalWrap';

import CmsLink from './../../components/CmsLink/CmsLink';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import './TableCell.scss';

const TableCell = ({columns, slug, text, tag: ElementTag}) => (
  <ElementTag className="table-cell" style={{width: `${100 / columns}%`}}>
    <ConditionalWrap
      condition={slug}
      wrap={children => (
        <CmsLink className="table-cell__link" slug={slug}>
          {children}
        </CmsLink>
      )}
    >
      <Typography tag="h4" variant={VARIANT.BODY}>
        {text}
      </Typography>
    </ConditionalWrap>
  </ElementTag>
);

TableCell.propTypes = {
  columns: PropTypes.number.isRequired,
  slug: PropTypes.string,
  tag: PropTypes.string,
  text: PropTypes.string.isRequired,
};

TableCell.defaultProps = {
  columns: 1,
  tag: 'td',
};

export default React.memo(TableCell);
