import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Trail, Spring} from 'react-spring/renderprops.cjs.js';
import {ParallaxBanner} from 'react-scroll-parallax';
import {connect} from 'react-redux';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';

import Picture, {RATIO} from './../../atoms/Picture/Picture';

import HeroCategoryTitle from './../../components/HeroCategoryTitle/HeroCategoryTitle';

import FullRow from './../../layouts/FullRow/FullRow';
import Breadcrumbs from './../../layouts/Breadcrumbs/Breadcrumbs';

import './Hero.scss';

const getHeroPictureRatio = windowWidth =>
  windowWidth < 1024 ? RATIO.R_3_2 : windowWidth < 1920 ? RATIO.R_1_1 : windowWidth > 2399 ? RATIO.R_1_1 : RATIO.R_4_3;

const HeroPicture = ({image, props, windowWidth}) => (
  <Picture
    alt={image.alt}
    className="hero__column hero__column-picture"
    cover
    imageClassName="hero__image"
    pictureClassName="hero__picture"
    ratio={windowWidth ? getHeroPictureRatio(windowWidth) : RATIO.R_16_9}
    src={image.src.large}
    srcSet={[
      {src: image.src.small, maxWidth: 639},
      {src: image.src.medium, maxWidth: 1279},
    ]}
    style={props}
  />
);

const Hero = ({
  button,
  component: HeroWrapper,
  content,
  date,
  extraInfo,
  formId,
  formInfo,
  image,
  inverted,
  subtitle,
  title,
  type,
  variant,
  vertical,
  isIE,
  windowWidth,
}) => (
  <HeroWrapper
    className={classNames('hero', {
      'hero--inverted': inverted,
      'hero--vertical': vertical,
      'hero--blog': variant === HERO_TYPE_BLOG,
      'hero--extra-info': extraInfo,
    })}
    scrollAnimation={false}
  >
    <Spring
      config={{tension: 210, friction: 35, clamp: true}}
      from={{transform: 'translateY(105%)'}}
      to={{transform: 'translateY(0)'}}
    >
      {props => (
        <div className="hero__column hero__column-content" style={props}>
          <Trail
            config={{tension: 210, friction: 35, delay: 1000}}
            from={{opacity: 0, transform: 'translateY(200px)'}}
            items={[
              <Breadcrumbs key="breadcrumbs" />,
              <div className="hero__common-content" key="content">
                <HeroCategoryTitle
                  button={button}
                  date={date}
                  extraInfo={extraInfo}
                  formId={formId}
                  formInfo={formInfo}
                  fullWidth={variant === HERO_TYPE_BLOG}
                  inverted={inverted}
                  subtitle={subtitle}
                  title={title}
                  type={type}
                />
                {content && <div className="hero__common-content-main">{content}</div>}
              </div>,
            ]}
            keys={item => item.key}
            to={{opacity: 1, transform: 'translateY(0)'}}
          >
            {(item, index) => styleProps => (
              <div className={classNames({'hero__common-content': index === 1})} style={styleProps}>
                {item}
              </div>
            )}
          </Trail>
        </div>
      )}
    </Spring>

    <Spring
      config={{delay: 1000, tension: 210, friction: 35, clamp: true}}
      from={{transform: 'translateY(105%)'}}
      to={{transform: 'translateY(0)'}}
    >
      {props =>
        isIE || !vertical ? (
          <HeroPicture image={image} props={props} windowWidth={windowWidth} />
        ) : (
          <ParallaxBanner
            layers={[
              {
                amount: 0.2,
                children: <HeroPicture image={image} props={props} />,
              },
            ]}
            style={{height: 'auto'}}
          />
        )
      }
    </Spring>
  </HeroWrapper>
);

export const HERO_TYPE_REGULAR = '';
export const HERO_TYPE_BLOG = 'blog';

Hero.propTypes = {
  component: PropTypes.shape(),
  date: PropTypes.string,
  inverted: PropTypes.bool,
  leftNode: PropTypes.node,
  rightNode: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([HERO_TYPE_REGULAR, HERO_TYPE_BLOG]),
  vertical: PropTypes.bool,
};

Hero.defaultProps = {
  component: FullRow,
  inverted: false,
  vertical: false,
  variant: HERO_TYPE_REGULAR,
};

const mapStateToProps = state => ({
  isIE: state.ui.isIE,
});

export default compose(connect(mapStateToProps), React.memo, withSize)(Hero);
