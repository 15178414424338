export const COMPANY = 'company';
export const COMPANIES = 'companies';

export const companiesRouteParams = {
  [COMPANIES]: {
    shouldPartiallyRender: true,
  },
  [COMPANY]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [COMPANIES]: {
    es: '/empresas-participadas',
    en: '/partly-owned-companies',
    eu: '/enpresa-partaidetuak',
    fr: '/entreprises-associees',
  },
  [COMPANY]: {
    es: '/empresas-participadas/:slug',
    en: '/partly-owned-companies/:slug',
    eu: '/enpresa-partaidetuak/:slug',
    fr: '/entreprises-associees/:slug',
  },
};
