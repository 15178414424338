import React from 'react';
import PropTypes from 'prop-types';
import {Trail} from 'react-spring/renderprops.cjs.js';

import {getMailShareUrl, socialShareList} from './../../../_config/socialConfig';

import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';
import {PrimaryButton} from './../../../atoms/Button/Button';

import './ShareButtons.scss';

const ShareButtons = ({text, renderButton, url}) => {
  const testingButtons = socialShareList({text, url}).map((item, index) =>
    renderButton(
      <PrimaryButton className="share-buttons__item" key={index}>
        <Iconography icon={item.icon} />
      </PrimaryButton>,
      item.slug,
      index,
    ),
  );
  testingButtons.push(
    <a href={getMailShareUrl({url: url, body: text, subject: text})} rel="noopener noreferrer nofollow" target="_blank">
      <PrimaryButton className="share-buttons__item">
        <Iconography icon={ICON.MAIL} />
      </PrimaryButton>
    </a>,
  );
  return (
    <div className="share-buttons">
      <Trail
        config={{duration: 800}}
        from={{opacity: '0'}}
        items={testingButtons}
        keys={item => item.title}
        to={{opacity: '1'}}
      >
        {item => props => <div style={props}>{item}</div>}
      </Trail>
    </div>
  );
};

ShareButtons.propTypes = {
  renderButton: PropTypes.func,
  url: PropTypes.string.isRequired,
};

ShareButtons.defaultProps = {
  renderButton: children => children,
};

export default React.memo(ShareButtons);
