import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import RoutingAgenda from './../../_bridges/RoutingAgenda/RoutingAgenda';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import FullRow from './../../layouts/FullRow/FullRow';

import './RelatedAgenda.scss';

const RelatedAgenda = ({agenda, title, subtitle, titleVariant, intl}) => (
  <FullRow className="related-agenda">
    {subtitle && (
      <Typography className="related-agenda__content" tag="h3" variant={VARIANT.HEADING_SMALL}>
        {subtitle}
      </Typography>
    )}
    <Typography className="related-agenda__title" tag="h3" variant={titleVariant}>
      {title}
    </Typography>
    {agenda.length > 0 ? (
      agenda.map((event, index) => <RoutingAgenda item={event} key={index} />)
    ) : (
      <Typography>{intl.formatMessage({id: 'list.event.none'})}</Typography>
    )}
  </FullRow>
);

RelatedAgenda.propTypes = {
  agenda: PropTypes.arrayOf(PropTypes.shape),
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.oneOf(Object.values(VARIANT)),
};

RelatedAgenda.defaultProps = {
  titleVariant: VARIANT.HEADING,
};

export default compose(injectIntl, React.memo)(RelatedAgenda);
