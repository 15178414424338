import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingExpertSocial from './../../_bridges/RoutingExpertSocial/RoutingExpertSocial';
import RoutingInfrastructures from './../../_bridges/RoutingInfrastructures/RoutingInfrastructures';

import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {PROJECT} from './../../../routing/projects/routes';
import {DELEGATION} from './../../../routing/delegations/routes';

import Content from './../../atoms/Content/Content';
import {VARIANT} from './../../atoms/Typography/Typography';

// import DataTable from './../../components/DataTable/DataTable';
import TitleTextComponent from './../../components/TitleTextComponent/TitleTextComponent';

import Hero from './../../compositions/Hero/Hero';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';

import MainLayout from './../../layouts/MainLayout/MainLayout';

import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
// import RoutingNoPaddingPreview from './../../_bridges/RoutingNoPaddingPreview/RoutingNoPaddingPreview';

// import {expertContent} from './../../templates/Homepage/dummyContent';

import './Expert.scss';

const Expert = ({canonical, content: expert, intl}) => (
  <div className="expert">
    <Hero
      button={intl.formatMessage({id: 'button.contact'})}
      content={
        <div className="expert__hero-main-content">
          <TitleTextComponent text={expert.division.division} textVariant="BODY_XSMALL" title={expert.division.title} />
          <TitleTextComponent
            text={expert.businessArea.text}
            textVariant="BODY_XSMALL"
            title={intl.formatMessage({id: 'expert.title'})}
          />
        </div>
      }
      image={expert.image}
      subtitle={expert.position}
      title={expert.title}
      type={intl.formatMessage({id: 'expert.title'})}
    />
    <MainLayout
      asideContent={
        <React.Fragment>
          {(expert.linkedinProfile || expert.researchGatesProfile) && (
            <RoutingExpertSocial
              linkedinSlug={expert.linkedinProfile}
              researchgateSlug={expert.researchGatesProfile}
              socialTitle={intl.formatMessage({id: 'expert.social.title'})}
            />
          )}
          {expert.delegation && expert.delegation.items.length > 0 && (
            <RoutingInfrastructures
              infrastructures={expert.delegation.items}
              title={expert.delegation.title}
              to={DELEGATION}
            />
          )}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <React.Fragment>
          {expert.detailedExcerpt && (
            <Content className="expert__content" raw variant={VARIANT.H3}>
              {expert.detailedExcerpt}
            </Content>
          )}
          {expert.technologies && expert.technologies.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3}}
              tags={expert.technologies.items}
              title={expert.technologies.title}
              to={TECHNOLOGY}
            />
          )}
        </React.Fragment>
      }
      socialText={expert.title}
    />
    {expert.relatedProject.items.length > 0 && (
      <RelatedContentCards
        cards={expert.relatedProject.items}
        slidesPerBreakpoint={{S: 1, L: 2, XXL: 3, XXXXL: 5}}
        title={expert.relatedProject.title}
      >
        {expert.relatedProject.items.map((card, index) => (
          <RoutingGenericPreview key={index} label={expert.technologies.title} preview={card} to={PROJECT} />
        ))}
      </RelatedContentCards>
    )}
    {/* Blog won't be on first release */}
    {/* expertContent.blog.items.length > 0 && (
      <RelatedContentCards
        cards={expertContent.blog.items}
        slidesPerBreakpoint={{S: 1, L: 2, XXL: 3}}
        title={expertContent.blog.title}
      >
        {expertContent.blog.items.map((card, index) => (
          <RoutingNoPaddingPreview key={index} preview={card} to={BLOG} />
        ))}
      </RelatedContentCards>
    )*/}
    {/* TODO this is still hardcoded */}
    {/* <DataTable tableData={expertContent.patentsContent} />
    <DataTable tableData={expertContent.colaborationsContent} /> */}
  </div>
);

export default compose(injectIntl, React.memo)(Expert);
