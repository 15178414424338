import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import playerPropType from './../../_propTypes/playerPropType';

const ControlledPlayer = ({className, onEnded, height, url, width, ...props}) => (
  <ReactPlayer
    {...props}
    className={classNames('controlled-player', className)}
    height={height}
    onEnded={onEnded}
    url={url}
    width={width}
  />
);

ControlledPlayer.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  onEnded: PropTypes.func,
  url: PropTypes.oneOfType([PropTypes.arrayOf(playerPropType), PropTypes.string]).isRequired,
  width: PropTypes.string,
};

ControlledPlayer.defaultProps = {
  height: '100%',
  onEnded: () => {},
  width: '100%',
};

export default React.memo(ControlledPlayer);
