import React from 'react';
import {compose} from 'redux';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

import PropTypes from 'prop-types';
import {SecondaryButton} from '../../atoms/Button/Button';
import Picture, {RATIO} from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';
import {getMonthFromDate, getDayNumberFromDate} from '../../_util/getDateFromString';

import './InspiringBlogHeroCard.scss';

const InspiringBlogHeroCard = ({post, renderContainer, intl}) => (
  <div className="inspiring-blog-hero-card">
    <div className="inspiring-blog-hero-card__image">
      <Picture
        alt={post.image.alt}
        cover={true}
        ratio={RATIO.R_24_19}
        src={post.image.src.large}
        srcSet={[
          {
            src: post.image.src.small,
            maxWidth: 480,
          },
        ]}
      />
    </div>
    <div className="inspiring-blog-hero-card__date">
      <Typography variant={VARIANT.BODY_SMALLER}>{getDayNumberFromDate(post.date)}</Typography>
      <Typography variant={VARIANT.BODY_XSMALL_REGULAR}>{getMonthFromDate(post.date, intl.locale)}</Typography>
    </div>
    <div className="inspiring-blog-hero-card__content">
      <Typography className="inspiring-blog-hero-card__content-title" tag="h2" variant={VARIANT.H1}>
        {post.title}
      </Typography>
      <Typography className="inspiring-blog-hero-card__content-author" tag="div" variant={VARIANT.FOOTER}>
        <FormattedHTMLMessage id="blog.card.author" values={{name: post.author.displayName}} />
      </Typography>
      {renderContainer(
        <SecondaryButton className="inspiring-blog-hero-card__content-button">
          <FormattedMessage id="inspiringBlog.read_more" />
        </SecondaryButton>,
      )}
    </div>
  </div>
);

InspiringBlogHeroCard.propTypes = {
  renderContainer: PropTypes.func,
};

InspiringBlogHeroCard.defaultProps = {
  renderContainer: children => children,
};

export default compose(injectIntl)(InspiringBlogHeroCard);
