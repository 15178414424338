import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import {TECHNOLOGY} from './../../../routing/technologies/routes';

import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingInspStoryPreview from './../../_bridges/RoutingInspiringStoryPreview/RoutingInspiringStoryPreview';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import InspiringStoryHero from './../../compositions/InspiringStoryHero/InspiringStoryHero';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import NewsAgendaModule from './../../compositions/NewsAgendaModule/NewsAgendaModule';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './InspiringStory.scss';

const InspiringStory = ({canonical, content: story, intl}) => (
  <div className="inspiring-story">
    <InspiringStoryHero
      image={story.image}
      quote={story.quote}
      sectors={story.sectors}
      title={story.title}
      type={intl.formatMessage({id: 'inspiringStories.title'})}
      video={story.video}
    />
    <MainLayout
      asideContent={
        <React.Fragment>
          {story.relatedContact.items.map((contact, index) => (
            <RoutingExpertModule
              expertPageSlug={contact.slug}
              formId={story.formId.id}
              formInfo={story.form.find(form => form.type === 'expert')}
              hidden={story.relatedContact.simplified}
              imgAlt={contact.title}
              imgSrc={contact.image.src.small}
              key={`relatedContact${index}`}
              name={contact.title}
              position={contact.position}
              templateTitle={story.title}
            />
          ))}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={<React.Fragment>{story.content && <CmsContent content={story.content} />}</React.Fragment>}
    />
    {story.technologies.items.length > 0 && (
      <RelatedContentCards title={story.technologies.title}>
        {story.technologies.items.map((item, index) => (
          <RoutingGenericPreview
            horizontal
            key={index}
            label={intl.formatMessage({id: 'technology.category'})}
            preview={item.fields.relatedPage}
            to={TECHNOLOGY}
          />
        ))}
      </RelatedContentCards>
    )}

    {story.relatedMediaGallery.elements &&
      story.relatedMediaGallery.elements.length > 0 &&
      story.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={story.relatedMediaGallery} />
      )}

    {story.relatedNews.items.length > 1 && (
      <NewsAgendaModule newsCards={story.relatedNews.items} newsTitle={story.relatedNews.title} />
    )}
    {story.relatedInspiringStory.items.length > 0 && (
      <RelatedContentCards
        className="inspiring-stories__related-stories"
        title={story.relatedInspiringStory.title}
        titleTag="h4"
        withBackground
      >
        {story.relatedInspiringStory.items.map((item, index) => (
          <RoutingInspStoryPreview
            inspiringStory={item}
            key={index}
            label={intl.formatMessage({id: 'inspiringStories.category'})}
          />
        ))}
      </RelatedContentCards>
    )}
  </div>
);

export default compose(injectIntl, React.memo)(InspiringStory);
