import React from 'react';

const HompageMicrodata = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Tecnalia',
        alternateName: 'Tecnalia Research & Innovation',
        url: 'https://www.tecnalia.com/',
        logo: 'https://www.tecnalia.com/images/logo.svg',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '946 430 850',
          contactType: 'customer service',
          areaServed: 'ES',
          availableLanguage: 'es',
        },
        sameAs: [
          'https://www.facebook.com/Tecnalia/',
          'https://twitter.com/tecnalia',
          'https://www.instagram.com/tecnaliaoficial/',
          'https://www.youtube.com/user/tecnaliaTV',
          'https://es.linkedin.com/company/tecnalia-research-&-innovation',
        ],
      }),
    }}
    type="application/ld+json"
  />
);

export default HompageMicrodata;
