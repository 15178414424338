import React, {useState, useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import {useLocation} from 'react-router';

import {getListRoute, getPostType} from '../../_util/routes';
import InspiringBlogPostsHero from '../../compositions/InspiringBlogPostsHero/InspiringBlogPostsHero';
import Helmet from '../../seo/Helmet';
import {getRelNext, getRelPrev} from '../../_util/pagination';
import Row from '../../layouts/Row/Row';
import Column from '../../layouts/Column/Column';
import Grid from '../../layouts/Grid/Grid';
import FullRow from '../../layouts/FullRow/FullRow';
import Typography from '../../atoms/Typography/Typography';
import Loader from '../../atoms/Loader/Loader';
import {RATIO} from '../../atoms/Picture/Picture';
import Paginator from '../../components/Paginator/Paginator';
import withLocationPage from '../../_hocs/withLocationPage';
// eslint-disable-next-line max-len
import RoutingInspiringBlogPostPreview from '../../_bridges/RoutingInspiringBlogPostPreview/RoutingInspiringBlogPostPreview';
import Filters from './Filters/Filters';
import PostsModule from './PostsModule/PostsModule';
import Banner from './Banner/Banner';
import Form from '../../components/Forms/Form';
import RoutingRelatedTagsCategory from '../../_bridges/RoutingRelatedTagsCategory/RoutingRelatedTagsCategory';
import {INSPIRING_BLOG_CATEGORY} from '../../../routing/inspiringBlog/routes';

import './InspiringBlogPosts.scss';

const formatListRoute = (locale, type) => initialParams => page => getListRoute(type, page, locale, initialParams);

const InspiringBlogPosts = ({content, title, intl, pagination, page, type, fetching, filters, categories}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search !== '' ? location.search : '');
  // const [currentPage, setCurrentPage] = useState(parseInt(params.get('page'), 10) || 1);
  const [currentPage, setCurrentPage] = useState(parseInt(params.get('page'), 10) || 1);
  const initialParams = {};

  // eslint-disable-next-line no-return-assign
  Array.from(params.entries()).map(entry => (initialParams[entry[0]] = entry[1]));

  const listBaseUrl = getListRoute(type, 0, intl.locale, initialParams);

  useEffect(() => {
    document.querySelector('.body').classList.add('inspiring-blog-index');
  }, []);

  useEffect(() => {
    const nextPage = parseInt(params.get('page'), 10) || 1;
    if (nextPage !== currentPage) {
      window.scrollTo(0, 0);
      setCurrentPage(nextPage);
    }
  }, [currentPage, params]);

  const Banners = () => (
    <>
      {content.banners &&
        content.banners
          .filter(banner => banner !== null)
          .slice(0, 2)
          .map((banner, key) => (
            <div className="aside__banner" key={key}>
              <Banner image={banner.image} ratio={RATIO.R_4_3} url={banner.url} />
            </div>
          ))}
    </>
  );

  return (
    <div className="inspiring-blog">
      <InspiringBlogPostsHero
        items={content.heroItems}
        title={title}
        type={intl.formatMessage({id: 'blog.category'})}
      />

      <Helmet
        relNext={getRelNext(page, pagination.totalPages, listBaseUrl)}
        relPrev={getRelPrev(page, listBaseUrl)}
        title={title}
      />
      <Row className="inspiring-blog__content">
        <Column className="inspiring-blog__content-main">
          {!fetching ? (
            content.items.length > 0 ? (
              <Grid
                className="inspiring-blog__grid"
                columnsPerBreakpoint={{S: 1, M: 2, XXL: 2}}
                scrollAnimation={false}
                tag={FullRow}
              >
                {content.items.map((card, index) => (
                  <RoutingInspiringBlogPostPreview key={index} preview={card} to={getPostType(card.type)} />
                ))}
              </Grid>
            ) : (
              <Typography className="inspiring-blog__non-content">
                {intl.formatMessage({id: 'list.blog.none'})}
              </Typography>
            )
          ) : (
            <Loader />
          )}
        </Column>

        <Column className="inspiring-blog__content-aside">
          <Filters
            ambits={filters.ambits}
            authors={filters.authors}
            categories={filters.categories}
            dates={filters.dates}
            tags={filters.tags}
          />
          <div className="aside__banner-container">
            <Banners />
          </div>
        </Column>
      </Row>

      {pagination.totalPages > 1 && (
        <Row>
          <Column className="inspiring-blog__paginator">
            <Paginator
              currentPage={page}
              formatPageRoute={formatListRoute(intl.locale, type)(initialParams)}
              totalPages={pagination.totalPages}
            />
          </Column>
        </Row>
      )}

      <Row className="inspring-blog__banner-container">
        <Column className="inspiring-blog__banner-container-column">
          <Banners />
        </Column>
      </Row>

      <div className="inspiring-blog__bottom">
        {content.newsletter && content.newsletter.form && (
          <Row>
            <Form formInfo={content.newsletter.form[0]} fullHeight />
          </Row>
        )}
        {content.highlights && content.highlights.items.length > 0 && (
          <Row>
            <PostsModule content={content.highlights} defaultButtons />
          </Row>
        )}
      </div>

      {categories.length > 0 && (
        <Row className="inspiring-blog__related-tags">
          <Column className="inspiringblog__main-bottom-column">
            <RoutingRelatedTagsCategory
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={categories}
              title={intl.formatMessage({id: 'blog.categories'})}
              to={INSPIRING_BLOG_CATEGORY}
            />
          </Column>
        </Row>
      )}
    </div>
  );
};

export default compose(React.memo, injectIntl, withLocationPage)(InspiringBlogPosts);
