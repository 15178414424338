export const SEARCH = 'search';

export const searchRoutesParams = {
  [SEARCH]: {
    shouldPartiallyRender: true,
  },
};

export default {
  [SEARCH]: {
    es: '/buscador',
    en: '/search',
    eu: '/bilatu',
    fr: '/recherche',
  },
};
