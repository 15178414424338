import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Typography.scss';

const VARIANT = {
  NUMBER: 'number',
  HUGE: 'huge',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  EXCERPT: 'excerpt',
  HEADING: 'heading',
  HEADING_LOWER: 'heading-lower',
  HEADING_SMALL: 'heading-small',
  BODY: 'body',
  BODY_BIG: 'body-big',
  BODY_LIGHT: 'body-light',
  BODY_SMALL: 'body-small',
  BODY_SMALLER: 'body-smaller',
  BODY_XSMALL: 'body-xsmall',
  BODY_XSMALL_REGULAR: 'body-xsmall-regular',
  BODY_XSMALL_LOWERCASE: 'body-xsmall-lowercase',
  BREADCRUMB: 'breadcrumb',
  MENU: 'menu',
  FOOTER: 'footer',
  CONTACT: 'contact',
  LABEL: 'label',
  LABEL_SMALL: 'label-small',
  FOOTER_SMALL: 'footer-small',
  NUMBER_SMALL: 'number-small',
  HEADING_LIGHT: 'heading-light',
  HEADING_MEDIUM: 'heading-medium',
};

const Typography = ({className, tag: TypographyTag, children, italic, variant, ...rest}) => (
  <TypographyTag
    className={classNames(
      'typography',
      `typography--${variant}`,
      {
        'typography--italic': italic,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </TypographyTag>
);

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  italic: PropTypes.bool,
  tag: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

Typography.defaultProps = {
  tag: 'span',
  variant: VARIANT.BODY,
};

export {VARIANT};

export default React.memo(Typography);
