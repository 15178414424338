import PropTypes from 'prop-types';

import responsiveImagePropType from './responsiveImagePropType';
import relatedTagsPropType from './relatedTagsPropType';
import relatedMediaGalleryPropType from './relatedMediaGalleryPropType';
import relatedResearcherPropType from './relatedResearcherPropType';
import relatedCardPropType from './relatedCardPropType';
import catalogPropType from './catalogPropType';

export default PropTypes.shape({
  catalog: catalogPropType,
  content: PropTypes.string.isRequired,
  detailedExcerpt: PropTypes.string,
  image: responsiveImagePropType,
  relatedContact: relatedResearcherPropType,
  relatedMediaGallery: relatedMediaGalleryPropType,
  relatedProject: relatedCardPropType,
  relatedService: relatedCardPropType,
  technologies: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: responsiveImagePropType,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }),
  sectors: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: relatedTagsPropType,
  }),
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});
