import React, {useState} from 'react';
import {Trail} from 'react-spring/renderprops.cjs.js';
import classNames from 'classnames';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

import CmsLink from './../../../components/CmsLink/CmsLink';
import LanguageSelector from './../HeaderLanguageSelector/HeaderLanguageSelector';

import FullRow from './../../../layouts/FullRow/FullRow';

import {isSelected, hasChildren} from './../Header.utils';

import './HeaderMenuMobile.scss';
import LandingIcon from '../../../components/Landing/LandingIcon/LandingIcon';

const handleItemClicked = ({index, selected, setSelected, subSelected, setSubSelected}) => {
  setSelected(index === selected ? null : index);
  if (subSelected !== null) {
    setSubSelected(null);
  }
};

const HeaderMenuMobile = ({contact, isScrolled, items, locale, pathname}) => {
  const [selected, setSelected] = useState(null);
  const [subSelected, setSubSelected] = useState(null);

  return (
    <FullRow
      className={classNames('header-menu-mobile', {'header-menu-mobile--scrolled': isScrolled})}
      scrollAnimation={false}
    >
      <div className="header-menu-mobile__header">
        {contact && (
          <a className="landing-button" href={contact.slug}>
            {contact.title}
          </a>
        )}
        <LanguageSelector currentLocale={locale} />
      </div>

      <div className="header-menu-mobile__items">
        <Trail
          config={{duration: 600, delay: 150}}
          from={{opacity: 0}}
          items={items}
          keys={item => item.title}
          to={{opacity: 1}}
        >
          {(item, index) => props =>
            hasChildren(item) ? (
              <div
                className={classNames('header-menu-mobile__item', 'header-menu-mobile__item--accordion', {
                  'header-menu-mobile__item--selected': isSelected({item, locale, pathname}),
                  'header-menu-mobile__item--active': selected === index,
                })}
                key={item.menuId}
                style={props}
              >
                <button
                  className="header-menu-mobile__item-header"
                  onClick={() => handleItemClicked({index, selected, setSelected, subSelected, setSubSelected})}
                >
                  <Typography className="header-menu-mobile__item-typo" variant={VARIANT.MENU}>
                    {item.title}
                  </Typography>
                  <LandingIcon path={'/icons/chevron-down.svg'}></LandingIcon>
                </button>

                <section className="header-menu-mobile__item-animation-wrapper">
                  <div className="header-menu-mobile__item-animation">
                    <div className="header-menu-mobile__item-transform-wrapper">
                      <div className="header-menu-mobile__item-content">
                        {item.items.map((subItem, subIndex) => (
                          <div
                            className={classNames('header-menu-mobile__subitem', {
                              'header-menu-mobile__subitem--active': subIndex === subSelected,
                              'header-menu-mobile__subitem--featured': subItem.fields.featured,
                            })}
                            key={subIndex}
                          >
                            <CmsLink className="header-menu-mobile__submenu-item" slug={subItem.slug}>
                              <Typography
                                className="header-menu-mobile__submenu-typo"
                                onClick={() => setSubSelected(subSelected === subIndex ? null : subIndex)}
                                variant={VARIANT.MENU}
                              >
                                {subItem.title}
                              </Typography>
                            </CmsLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <CmsLink
                className={classNames('header-menu-mobile__item', 'header-menu-mobile__item--link', {
                  'header-menu-mobile__item--selected': isSelected({item, locale, pathname}),
                  'header-menu-mobile__item--active': selected === index,
                  'header-menu-mobile__item--featured': item.fields.featured,
                })}
                key={item.menuId}
                slug={item.slug}
                style={props}
              >
                <Typography className="header-menu-mobile__item-typo" variant={VARIANT.MENU}>
                  {item.title}
                </Typography>
              </CmsLink>
            )}
        </Trail>
      </div>
    </FullRow>
  );
};

export default compose(injectIntl, React.memo)(HeaderMenuMobile);
