import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {compose} from 'redux';
import {withI18nRouting} from '@foes/react-i18n-routing';

import i18n from './../../../../i18n';
import BREAKPOINTS from './../../../_config/breakpoints';
import withSize from './../../../../_packages/resize/ui/withSize';
import {INSPIRING_BLOG_CATEGORY, INSPIRING_BLOG_TAG} from '../../../../routing/inspiringBlog/routes';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import LandingIcon from '../../../components/Landing/LandingIcon/LandingIcon';

import './HeaderLanguageSelector.scss';

const translatedSlugsForLocales = (locales, extraData) => translatedSlugs =>
  locales.map(locale => ({
    ...(extraData && extraData[locale]),
    locale: locale,
    slug: translatedSlugs && translatedSlugs[locale] ? translatedSlugs[locale] : '',
  }));

const translatedSlugsForAppLocales = translatedSlugsForLocales(i18n.locales);

const routerForTemplate = {
  inspiringBlogCategory: INSPIRING_BLOG_CATEGORY,
  inspiringBlogTag: INSPIRING_BLOG_TAG,
};

const translatedSlugsProvider = ({i18nRouting, slug, template, locale}) =>
  ['inspiringBlogCategory', 'inspiringBlogTag'].includes(template)
    ? i18nRouting.formatIntlRoute(routerForTemplate[template], {slug: slug}, locale)
    : i18n.prependLocale(locale, slug);
const LocaleItem = ({children, i18nRouting, locale, selectedLocale, slug, template}) => (
  <a
    className={classNames('header__language-selector-option', {
      'header__language-selector-option--selected': selectedLocale === locale,
    })}
    href={translatedSlugsProvider({slug, template, locale, i18nRouting})}
  >
    {children}
  </a>
);
const HeaderLanguageSelector = ({currentLocale, template, translatedSlugs, windowWidth, i18nRouting}) => (
  <React.Fragment>
    {windowWidth >= BREAKPOINTS.XL ? (
      <div className="header__language-selector" onTouchEnd={() => true} onTouchStart={() => true}>
        <div className="header__language-selector-selected">
          <Typography variant={VARIANT.LABEL_SMALL}>{currentLocale}</Typography>
          <LandingIcon path={'/icons/chevron-down.svg'}></LandingIcon>
        </div>
        <div className="header__language-selector-options">
          {translatedSlugsForAppLocales(translatedSlugs).map(({locale, slug}) => (
            <LocaleItem
              i18nRouting={i18nRouting}
              key={locale}
              locale={locale}
              selectedLocale={currentLocale}
              slug={slug}
              template={template}
            >
              <Typography variant={VARIANT.LABEL_SMALL}>{locale}</Typography>
            </LocaleItem>
          ))}
        </div>
      </div>
    ) : (
      <div className="header__language-selector--mobile">
        <Typography className="header__language-selector-current" variant={VARIANT.BODY_XSMALL_REGULAR}>
          {currentLocale}
        </Typography>
        {translatedSlugsForAppLocales(translatedSlugs).map(({locale, slug}) => (
          <LocaleItem
            i18nRouting={i18nRouting}
            key={locale}
            locale={locale}
            selectedLocale={currentLocale}
            slug={slug}
            template={template}
          >
            <Typography variant={VARIANT.BODY_XSMALL_REGULAR}>{locale}</Typography>
          </LocaleItem>
        ))}
      </div>
    )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  translatedSlugs:
    state.api.backendPage && state.api.backendPage.resource
      ? state.api.backendPage.resource.translatedSlugs
      : i18n.defaultTranslatedRoutes,
  template: state.api.backendPage && state.api.backendPage.resource ? state.api.backendPage.resource.template : '',
});

export default compose(connect(mapStateToProps), withSize, React.memo, withI18nRouting)(HeaderLanguageSelector);
