import {FormattedLink, withI18nRouting} from '@foes/react-i18n-routing';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';
import {HOME} from './../../../routing/routes';
import BREAKPOINTS from './../../_config/breakpoints';

import ConditionalWrap from './../../_util/ConditionalWrap';

import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import Picture from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import LandingIcon from '../../components/Landing/LandingIcon/LandingIcon.js';

import CmsLink from './../../components/CmsLink/CmsLink';
import SearchBox from './../../components/SearchBox/SearchBox';

import LanguageSelector from './HeaderLanguageSelector/HeaderLanguageSelector';
import HeaderMenuMobile from './HeaderMenu/HeaderMenuMobile';
import MainMenu from './MainMenu/MainMenu';

import Column from './../../layouts/Column/Column';
import FullRow from './../../layouts/FullRow/FullRow';

import {checkScrolled, isSelected, shouldShowChilds} from './Header.utils.js';

import './Header.scss';

const getMenus = state =>
  state.api.backendContext && state.api.backendContext.resource && state.api.backendContext.resource.menus;

const Header = ({i18nRouting: {locale}, windowWidth}) => {
  const menus = useSelector(state => getMenus(state));
  const pathname = useSelector(state => state.router.location.pathname);

  const [selected, setSelected] = useState(null);
  const [searchOpened, setSearchOpened] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', checkScrolled(setIsScrolled));
    return () => {
      window.removeEventListener('scroll', checkScrolled(setIsScrolled));
    };
  }, []);

  const [isMobileOpen, setMobileOpened] = useState('');
  useEffect(() => {
    isMobileOpen ? (document.body.style = 'position: fixed;') : (document.body.style = 'position: static;');
  }, [isMobileOpen]);

  const handleHeaderLinkItemClick = index => () => {
    setSelected(index);
    if (searchOpened) {
      setSearchOpened(!searchOpened);
    }
  };

  useEffect(() => {
    setSelected(null);
    setMobileOpened(false);
  }, [pathname]);

  const handleHeaderSearchClick = () => {
    setSearchOpened(!searchOpened);
    if (selected !== null) {
      setSelected(null);
    }
    if (isMobileOpen) {
      setMobileOpened(!isMobileOpen);
    }
  };

  const handleMenuMobileClicked = () => {
    setMobileOpened(!isMobileOpen);
    if (searchOpened) {
      setSearchOpened(!searchOpened);
    }
  };

  if (!menus) {
    return null;
  }
  const {items} = menus.filter(menu => menu.originalSlug === 'main')[0];
  const contact = menus.filter(menu => menu.originalSlug === 'contact');
  const contactPage = contact && contact[0] && contact[0].items && contact[0].items.length && contact[0].items[0];

  return (
    <header
      className={classNames('header', {'header--search': searchOpened, 'header--scrolled': isScrolled})}
      id="header"
    >
      <FullRow className="header__wrapper" scrollAnimation={false}>
        <React.Fragment>
          <Column className="header__logo">
            <FormattedLink className="w" name={HOME}>
              <Picture alt="tecnalia-logo" src="/images/logo.svg" />
            </FormattedLink>
          </Column>
          {windowWidth >= BREAKPOINTS.XL && (
            <Column className="header__links">
              {items.map((item, index) => {
                let linkClasses = {};
                if (item.fields) {
                  linkClasses = {
                    'main-menu__link--featured': item.fields.featured,
                  };
                }
                return (
                  <ConditionalWrap
                    condition={!item.items}
                    key={`header-links-${index}`}
                    wrap={children => (
                      <CmsLink className={classNames('main-menu__link', linkClasses)} slug={item.slug}>
                        {children}
                      </CmsLink>
                    )}
                  >
                    <Typography
                      className={classNames('header__links-item', {
                        'header__links-item--selected': isSelected({item, locale, pathname}),
                      })}
                      key={index}
                      onClick={handleHeaderLinkItemClick(index)}
                      variant={VARIANT.MENU}
                    >
                      {item.title}
                    </Typography>
                  </ConditionalWrap>
                );
              })}
            </Column>
          )}

          {contactPage && windowWidth >= BREAKPOINTS.XL && (
            <Column className="header__contact-col">
              <a className="landing-button header__contact" href={contactPage.slug}>
                {contactPage.title}
              </a>
            </Column>
          )}

          {windowWidth >= BREAKPOINTS.XL && (
            <Column className="header__language-selector">
              <LanguageSelector currentLocale={locale} />
            </Column>
          )}
          <Column className={classNames('header__search', {'header__search--mobile': windowWidth < BREAKPOINTS.XL})}>
            <button className="header__search-icon" onClick={handleHeaderSearchClick}>
              {searchOpened ? (
                <LandingIcon path={'/icons/close.svg'}></LandingIcon>
              ) : (
                <LandingIcon path={'/icons/search.svg'}></LandingIcon>
              )}
            </button>
          </Column>
          {windowWidth < BREAKPOINTS.XL && (
            <Column className="header__menu">
              <Iconography
                className="header__menu-icon"
                icon={isMobileOpen ? ICON.CLOSE : ICON.MENU}
                onClick={handleMenuMobileClicked}
              />
            </Column>
          )}
        </React.Fragment>
      </FullRow>
      {searchOpened && (
        <FullRow scrollAnimation={false}>
          <Column className="header__search-bar">
            <SearchBox onClick={() => setSearchOpened(false)} showCloseIcon={false} />
          </Column>
        </FullRow>
      )}
      {shouldShowChilds(selected, items) && (
        <FullRow scrollAnimation={false}>
          <MainMenu isScrolled={isScrolled} items={items[selected].items} onCustomClick={() => setSelected(null)} />
        </FullRow>
      )}
      {isMobileOpen && (
        <HeaderMenuMobile
          contact={contactPage}
          isScrolled={isScrolled}
          items={items}
          locale={locale}
          pathname={pathname}
        />
      )}
    </header>
  );
};

export default compose(withI18nRouting, withSize, React.memo)(Header);
