import React from 'react';
import {FormattedNavLink} from '@foes/react-i18n-routing';

import GenericPreview from './../../components/GenericPreview/GenericPreview';

const RoutingGenericPreview = ({preview, to, ...rest}) => (
  <GenericPreview
    preview={preview}
    renderContainer={(children) => (
      <FormattedNavLink name={to} params={{slug: preview.slug}}>
        {children}
      </FormattedNavLink>
    )}
    {...rest}
  />
);
export default RoutingGenericPreview;
