/* eslint-disable no-debugger */
import {useEffect} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {compose} from 'redux';
import isSSR from './../../config/isSSR';

const ScrollToTop = ({location, pageInfo}) => {
  useEffect(() => {
    const template = pageInfo.template || 'page';
    const excludeTemplates = ['inspiringBlogPosts'];
    const shouldNotHaveParams = ['inspiringBlogPosts'];

    if (
      !isSSR() &&
      !(excludeTemplates.includes(template) && shouldNotHaveParams.includes(template) && location.search !== '')
    ) {
      window.scrollTo(0, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, location.pathname]);
  return null;
};

const mapStateToProps = state => ({
  pageInfo: state.api.backendPage.resource,
});

export default compose(connect(mapStateToProps), withRouter)(ScrollToTop);
