import React from 'react';
import PropTypes from 'prop-types';
import {FormattedLink} from '@foes/react-i18n-routing';

import CmsLink from './../../components/CmsLink/CmsLink';
import Infrastructures from './../../components/Infrastructures/Infrastructures';

const RoutingInfrastructures = ({infrastructures, to, ...rest}) => (
  <Infrastructures
    infrastructures={infrastructures}
    renderInfrastructureLocation={(children, index) =>
      /* TODO  If this is redirecting to Google Maps, the link has to be different, both of them have the same name */
      infrastructures[index].slug ? (
        <CmsLink className="infrastructures__link--location" slug={infrastructures[index].slug}>
          {children}
        </CmsLink>
      ) : (
        React.cloneElement(children, {key: index})
      )
    }
    renderInfrastructurePage={(children, index) =>
      infrastructures[index].slug ? (
        <FormattedLink className="infrastructures__link" name={to} params={{slug: infrastructures[index].slug}}>
          {children}
        </FormattedLink>
      ) : (
        React.cloneElement(children, {key: index})
      )
    }
    {...rest}
  />
);

RoutingInfrastructures.propTypes = {
  infrastructures: PropTypes.array.isRequired,
  to: PropTypes.string.isRequired,
};

export default React.memo(RoutingInfrastructures);
