import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';

import BREAKPOINTS from './../../_config/breakpoints';

import RoutingInspiringBlogHeroCard from './../../_bridges/RoutingInspiringBlogHeroCard/RoutingInspiringBlogHeroCard';

import Slider from './../Slider/Slider';

import FullRow from './../../layouts/FullRow/FullRow';

import './HeroSlider.scss';

const HeroSlider = ({items, slidesPerBreakpoint, scrollAnimation, windowWidth}) => (
  <FullRow className="hero-slider" scrollAnimation={scrollAnimation}>
    <Slider
      className="related-content-cards__wrapper"
      groupCells={windowWidth < BREAKPOINTS.L ? 1 : 2}
      slidesPerBreakpoint={slidesPerBreakpoint}
    >
      {items.map((item, key) => (
        <RoutingInspiringBlogHeroCard item={item} key={key} />
      ))}
    </Slider>
  </FullRow>
);

HeroSlider.propTypes = {
  invertedButtons: PropTypes.bool,
  scrollAnimation: PropTypes.bool,
};

HeroSlider.defaultProps = {
  scrollAnimation: true,
  slidesPerBreakpoint: {S: 1, L: 2},
};

export default compose(withSize, React.memo)(HeroSlider);
