import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {FormattedLink} from '@foes/react-i18n-routing';

import withSize from './../../../_packages/resize/ui/withSize';
import BREAKPOINTS from './../../_config/breakpoints';
import {ACTIVE} from '../../../routing/actives/routes';
import {COMPANY} from '../../../routing/companies/routes';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Picture, {RATIO} from './../../atoms/Picture/Picture';
import {PrimaryButton} from './../../atoms/Button/Button';

import HeroCategoryTitle from './../../components/HeroCategoryTitle/HeroCategoryTitle';

import Slider from './../../compositions/Slider/Slider';

import FullRow from './../../layouts/FullRow/FullRow';

import './RelatedServices.scss';

const RelatedServices = ({cards, intl, title, windowWidth}) => (
  <FullRow className="related-services">
    <Typography className="related-services__title" tag="h2" variant={VARIANT.HEADING}>
      {title}
    </Typography>
    <div className="related-services__slider">
      <Slider className="related-content-cards__wrapper" invertedButtons noMargin slidesPerBreakpoint={{S: 1}}>
        {cards.map((card, index) => (
          <FullRow className="related-services__slider-item" key={index} tag="article">
            <div className="related-services__content">
              <HeroCategoryTitle
                title={card.title}
                titleTag="h2"
                type={intl.formatMessage({
                  id: card.type === 'active' ? 'business.opportunity.active' : 'business.opportunity.company',
                })}
              />
              <Typography className="related-services__text" tag="p" variant={VARIANT.BODY}>
                {card.excerpt}
              </Typography>
              <FormattedLink name={card.type === 'active' ? ACTIVE : COMPANY} params={{slug: card.slug}}>
                <PrimaryButton className="related-services__button">
                  {intl.formatMessage({id: 'button.know.more'})}
                </PrimaryButton>
              </FormattedLink>
            </div>
            <Picture
              alt={card.image.alt}
              className="related-services__image"
              cover
              ratio={windowWidth < BREAKPOINTS.XXXL ? RATIO.R_1_1 : RATIO.R_3_2}
              src={card.image.src.large}
              srcSet={[
                {src: card.image.src.small, maxWidth: 479},
                {src: card.image.src.medium, maxWidth: 1279},
              ]}
            />
          </FullRow>
        ))}
      </Slider>
    </div>
  </FullRow>
);

RelatedServices.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape()),
};

export default compose(injectIntl, React.memo, withSize)(RelatedServices);
