import React, {useState} from 'react';
import {injectIntl} from 'react-intl';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import Iconography, {ICON} from './../../atoms/Iconography/Iconography';

import Column from './../../layouts/Column/Column';
import Row from './../../layouts/Row/Row';

import './AuthorBiography.scss';

const AuthorBiography = ({author, intl}) => {
  const [shouldShowAll, setShouldShowAll] = useState(false);
  const biography = author.description.split('\n').filter(element => element);

  return (
    <section className="author-biography">
      <Row className="author-biography__row">
        <Column className="author-biography__colum-image">
          <img alt={author.name} className="author-biography__image" src={author.image} />
        </Column>
        <Column className="author-biography__column-content">
          <Typography className="author-biography__tag" tag="p" variant={VARIANT.BODY}>
            {intl.formatMessage({id: 'inspiringBlog.author.about'})}
          </Typography>
          <Typography className="author-biography__title" tag="p" variant={VARIANT.BODY_SMALL}>
            {author.name}
          </Typography>
          {author.socialNetworks.linkedin && (
            <a href={author.socialNetworks.linkedin} rel="noopener noreferrer" target="_blank">
              <Iconography className="author-biography__icon" icon={ICON.LINKEDIN} />
            </a>
          )}
          <Content className="author-biography__biography" raw>
            {biography[0]}
          </Content>
          {shouldShowAll &&
            biography.slice(1).map((element, index) => (
              <Content className="author-biography__biography" key={`author-biography--${index}`} raw>
                {element}
              </Content>
            ))}
          {biography.length > 1 && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <Typography
              className="author-biography__more"
              onClick={() => setShouldShowAll(!shouldShowAll)}
              tag="p"
              variant={VARIANT.BODY_SMALL}
            >
              {intl.formatMessage({
                id: shouldShowAll ? 'inspiringBlog.biography.less' : 'inspiringBlog.biography.more',
              })}
            </Typography>
          )}
        </Column>
      </Row>
    </section>
  );
};

export default React.memo(injectIntl(AuthorBiography));
