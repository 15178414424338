export const INSPIRING_STORIES = 'inspiringStories';
export const INSPIRING_STORY = 'inspiringStory';

export const inspiringStoriesRouteParams = {
  [INSPIRING_STORIES]: {
    shouldPartiallyRender: true,
  },
  [INSPIRING_STORY]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [INSPIRING_STORIES]: {
    es: '/casos-de-exito',
    en: '/cases-of-success',
    eu: '/arrakasta-kasuak',
    fr: '/cas-de-succes',
  },
  [INSPIRING_STORY]: {
    es: '/casos-de-exito/:slug',
    en: '/cases-of-success/:slug',
    eu: '/arrakasta-kasuak/:slug',
    fr: '/cas-de-succes/:slug',
  },
};
