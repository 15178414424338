import React from 'react';
import {NavLink as RouterNavLink} from 'react-router-dom';
import {withI18nRouting} from '@foes/react-i18n-routing';

import i18n from './../../../i18n';

import breadcrumbsPropType from './../../_propTypes/breadcrumbsPropType';

import BreadcrumbItems from './../../components/BreadcrumbItems/BreadcrumbItems';

const RoutingBreadcrumbItems = ({breadcrumbs, i18nRouting}) => (
  <BreadcrumbItems
    breadcrumbs={breadcrumbs}
    renderContainer={(children, index) =>
      breadcrumbs[index] && breadcrumbs[index].url ? (
        <RouterNavLink exact key={index} to={i18n.prependLocale(i18nRouting.locale, breadcrumbs[index].url)}>
          {children}
        </RouterNavLink>
      ) : (
        React.cloneElement(children, {key: index})
      )
    }
  />
);

RoutingBreadcrumbItems.propTypes = {
  breadcrumbs: breadcrumbsPropType.isRequired,
};

export default withI18nRouting(RoutingBreadcrumbItems);
