import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Picture, {RATIO} from './../../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Content from './../../../atoms/Content/Content';

import FullRow from './../../../layouts/FullRow/FullRow';
import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';

import './Cms5050.scss';

const Cms5050 = ({content, inRow, reverse, renderLink, scrollAnimation}) => {
  const RowTag = inRow ? Row : FullRow;
  const {image, text, cta, title, id} = content;
  return (
    <RowTag className="cms-50-50__wrapper" id={id} scrollAnimation={scrollAnimation}>
      <div className={classNames('cms-50-50', {'cms-50-50--reverse': reverse})}>
        <Column className="cms-50-50__content">
          <div className="cms-50-50__content-wrapper">
            {title && (
              <Typography className="cms-50-50__content-title" tag="p" variant={VARIANT.HEADING}>
                {title}
              </Typography>
            )}
            <Content className="cms-50-50__content-text" raw tag="div">
              {text}
            </Content>
            {cta &&
              renderLink(
                <Typography className="cms-50-50__content-text" variant={VARIANT.BODY_SMALL}>
                  {cta.ctaText}
                </Typography>,
              )}
          </div>
        </Column>
        <Column className="cms-50-50__picture">
          <Picture
            alt={image.alt || image.title}
            caption={image.caption}
            className="cms-50-50__picture-image"
            cover
            ratio={RATIO.R_3_2}
            showCaption={image.showCaption}
            src={image.src.medium}
            srcSet={[
              {
                src: image.src.small,
                maxWidth: 480,
              },
            ]}
          />
        </Column>
      </div>
    </RowTag>
  );
};

Cms5050.propTypes = {
  inRow: PropTypes.bool,
  renderLink: PropTypes.func,
  reverse: PropTypes.bool,
};

Cms5050.defaultProps = {
  inRow: false,
  renderLink: children => children,
  reverse: false,
};

export default React.memo(Cms5050);
