import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from './../Typography/Typography';

import './Button.scss';

const Button = ({children, className, disabled, secondary, tag, type, ...rest}) => (
  <Typography
    className={classNames('button', {'button--secondary': secondary, 'button--disabled': disabled}, className)}
    disabled={disabled}
    tag={tag}
    type={type}
    variant={VARIANT.CTA}
    {...rest}
  >
    {children}
  </Typography>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  tag: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

Button.defaultProps = {
  disabled: false,
  secondary: false,
  tag: 'button',
};

export const PrimaryButton = ({...rest}) => <Button {...rest} secondary={false} />;

export const SecondaryButton = ({...rest}) => <Button {...rest} secondary />;
