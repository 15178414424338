import React, {useState} from 'react';
import LandingIcon from '../LandingIcon/LandingIcon';
import './LandingAccordion.scss';
const LandingAccordion = ({accordion}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleClick = index => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="landing-accordion">
      {accordion.map((item, index) => (
        <div
          className={`landing-accordion__item ${expandedIndex === index ? 'landing-accordion__item--expanded' : ''}`}
          key={index}
        >
          <button className="fs--md landing-accordion__header" onClick={() => handleClick(index)}>
            <span className="landing-accordion__header-text">{item.header}</span>
            {expandedIndex === index ? (
              <LandingIcon path={'/icons/minus-circle.svg'}></LandingIcon>
            ) : (
              <LandingIcon path={'/icons/plus-circle.svg'}></LandingIcon>
            )}
          </button>
          <section className="landing-accordion__animation-wrapper">
            <div className="landing-accordion__animation">
              <div className="landing-accordion__transform-wrapper">
                <div
                  className="fs--body landing-accordion__content"
                  dangerouslySetInnerHTML={{__html: item.content}}
                ></div>
              </div>
            </div>
          </section>
        </div>
      ))}
    </div>
  );
};

export default LandingAccordion;
