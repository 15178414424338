import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Transition, animated} from 'react-spring/renderprops.cjs.js';
import get from 'lodash.get';

import RoutingBreadcrumbItems from './../../_bridges/RoutingBreadcrumbItems/RoutingBreadcrumbItems';

import './Breadcrumbs.scss';

const Breadcrumbs = ({breadcrumbs}) => (
  <div className="breadcrumbs">
    {breadcrumbs && (
      <Transition enter={{opacity: 1}} from={{opacity: 0}}>
        {() => style => (
          <animated.div className="breadcrumbs__breadcrumb-items" style={style}>
            <RoutingBreadcrumbItems breadcrumbs={breadcrumbs} />
          </animated.div>
        )}
      </Transition>
    )}
  </div>
);

const mapStateToProps = state => ({
  breadcrumbs: get(state, 'api.backendPage.resource.breadcrumbs'),
});

export default compose(
  React.memo,
  connect(mapStateToProps),
)(Breadcrumbs);
