import React from 'react';
import classNames from 'classnames';

import './Loader.scss';

const Loader = small => (
  <div className={classNames('loader', {'loader--small': small})}>
    <div className="loader-dots">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
