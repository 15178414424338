import {matchPath} from 'react-router';
import {matchRoutes} from 'react-router-config';

const getMatchedPathsRoutes = pathname => (acc, customRoute) => {
  /* eslint-disable indent */
  const matchCustomRoutePaths = customRoute.path
    ? matchPath(pathname, {
        path: customRoute.path,
        exact: true,
        strict: true,
      })
    : null;
  /* eslint-enable indent */
  const current = matchCustomRoutePaths ? {...matchCustomRoutePaths, ...customRoute} : null;
  return [...acc, ...[current]];
};

const clearMatchedPaths = customRoute =>
  customRoute !== null && customRoute.path !== '*' && customRoute.path !== '/(.*)?';

export const getCurrentCustomRouteAndPathByRoutesAndPathname = ({i18nRoutes, pathname}) =>
  matchRoutes(i18nRoutes, pathname).reduce(
    (acc, {route: {routes: customRoutes}}) =>
      customRoutes ? customRoutes.reduce(getMatchedPathsRoutes(pathname), []).filter(clearMatchedPaths) : acc,
    [],
  );
