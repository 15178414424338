import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import errorReducer from './Error/ErrorReducer';
import apiReducer from './Api/ApiReducer';
import uiReducer from './Ui/UiReducer';
import i18nReducer from './I18n/I18nReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    error: errorReducer,
    api: apiReducer,
    ui: uiReducer,
    i18n: i18nReducer,
  });
