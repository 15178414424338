import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import './Content.scss';

const Content = ({className, children, raw, tag, variant}) => (
  <Typography className={classNames('content', className)} tag={tag} variant={variant}>
    {raw ? <div dangerouslySetInnerHTML={{__html: children}} /> : children}
  </Typography>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  raw: PropTypes.bool,
  tag: PropTypes.string,
  variant: PropTypes.string,
};

Content.defaultProps = {
  raw: false,
  tag: 'div',
  variant: VARIANT.BODY,
};

export default React.memo(Content);
