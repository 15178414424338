export const SECTORS = 'sectors';
export const SECTOR = 'sector';

export const sectorsRouteParams = {
  [SECTORS]: {
    shouldPartiallyRender: true,
  },
  [SECTOR]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [SECTORS]: {
    es: '/sectores',
    en: '/sectors',
    eu: '/sektoreak',
    fr: '/secteurs',
  },
  [SECTOR]: {
    es: '/sectores/:slug',
    en: '/sectors/:slug',
    eu: '/sektoreak/:slug',
    fr: '/secteurs/:slug',
  },
};
