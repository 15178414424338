import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import objectFitImages from 'object-fit-images';

import isSSR from './../../../config/isSSR';

import Typography, {VARIANT} from './../Typography/Typography';

import './Picture.scss';

const RATIO = {
  R_533_409: '533-409',
  R_24_19: '24-19',
  R_16_9: '16-9',
  R_8_3: '8-3',
  R_3_2: '3-2',
  R_4_3: '4-3',
  R_1_1: '1-1',
  R_5_4: '5-4',
};

const imageIsLoaded = image => image.complete && image.naturalHeight !== 0;

class Picture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      loaded: this.props.asyncLoad ? false : true,
      imageUrl: this.props.asyncLoad ? null : this.props.src,
    };

    this.contextMenu = this.contextMenu.bind(this);
    this.loadImage = this.loadImage.bind(this);
  }

  loadImage() {
    var img = new Image();
    img.onload = () => {
      this.setState({imageUrl: this.props.src});
      this.setState({loaded: true});

      if (!isSSR()) {
        objectFitImages();
      }
    };
    img.src = this.props.src;
  }

  componentDidMount() {
    /* eslint-disable react/no-did-mount-set-state*/
    this.setState({mounted: !imageIsLoaded(this.image)});
    if (this.state.imageUrl === null) {
      this.loadImage();
    }
    /* eslint-enable react/no-did-mount-set-state*/
  }

  contextMenu(e) {
    e.preventDefault();
  }

  render() {
    const {loaded, mounted, imageUrl} = this.state;
    const {
      className,
      pictureClassName,
      imageClassName,
      alt,
      caption,
      srcSet,
      ratio,
      cover,
      dataIndex,
      showCaption,
      style,
      thumbnail,
      tag: PictureTag,
      ...rest
    } = this.props;
    return (
      <PictureTag
        className={classNames(
          'picture',
          className,
          {
            'picture--mounted': mounted,
            'picture--loaded': loaded,
          },
          ratio ? `picture--ratio-${ratio}` : '',
        )}
        onContextMenu={this.contextMenu}
        style={style}
        {...rest}
      >
        {thumbnail && (
          <div className="picture__preload">
            <img alt={alt} className={classNames('picture__image', imageClassName)} src={thumbnail} />
          </div>
        )}
        <picture
          className={classNames(
            'picture__picture',
            cover && 'picture__picture--cover',
            !cover && 'picture__picture--contain',
            pictureClassName,
          )}
        >
          {srcSet &&
            srcSet.map((srcSetObj, index) =>
              srcSetObj.src && srcSetObj.maxWidth ? (
                <source key={index} media={`(max-width: ${srcSetObj.maxWidth}px)`} srcSet={srcSetObj.src} />
              ) : null,
            )}
          <img
            alt={alt}
            className={classNames(
              'picture__image',
              cover && 'picture__image--cover',
              !cover && 'picture__image--contain',
              imageClassName,
            )}
            data-index={dataIndex}
            // onLoad={this.onLoaded}
            ref={ref => {
              this.image = ref;
            }}
            src={imageUrl}
          />
        </picture>
        {showCaption && caption.length && (
          <Typography className="picture__caption" tag="p" variant={VARIANT.BODY_XSMALL_LOWERCASE}>
            {caption}
          </Typography>
        )}
      </PictureTag>
    );
  }
}

Picture.propTypes = {
  alt: PropTypes.string,
  asyncLoad: PropTypes.bool,
  caption: PropTypes.string,
  className: PropTypes.string,
  cover: PropTypes.bool,
  dataIndex: PropTypes.number,
  imageClassName: PropTypes.string,
  pictureClassName: PropTypes.string,
  ratio: PropTypes.oneOf(Object.values(RATIO)),
  showCaption: PropTypes.bool,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      maxWidth: PropTypes.number,
    }),
  ),
  style: PropTypes.object,
  tag: PropTypes.string,
  thumbnail: PropTypes.string,
};

Picture.defaultProps = {
  alt: '',
  tag: 'div',
  asyncLoad: true,
};

export {RATIO};

export default React.memo(Picture);
