export const NEWS_LIST = 'newsList';
export const NEWS = 'news';

export const newsRouteParams = {
  [NEWS_LIST]: {
    shouldPartiallyRender: true,
  },
  [NEWS]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [NEWS_LIST]: {
    es: '/noticias',
    en: '/news',
    eu: '/berriak',
    fr: '/actualites',
  },
  [NEWS]: {
    es: '/noticias/:slug',
    en: '/news/:slug',
    eu: '/berriak/:slug',
    fr: '/actualites/:slug',
  },
};
