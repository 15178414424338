import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Iconography, {ICON} from './../Iconography/Iconography';
import Typography, {VARIANT} from './../Typography/Typography';

import './FormCheckbox.scss';

export const CHECKBOX_TYPE = {
  TYPE_REGULAR: '',
  TYPE_FILTER: '--filter',
};

const FormCheckbox = ({className, label, handleChange, inputClassName, type, secondary, ...rest}) => (
  <label
    className={classNames(
      'form-checkbox',
      {'form-checkbox--secondary': secondary},
      {[`form-checkbox${type}`]: type},
      className,
    )}
  >
    <input
      className={classNames('form-checkbox__input', {[`form-checkbox__input${type}`]: type}, inputClassName)}
      onChange={event => handleChange(event.currentTarget.value)}
      type="checkbox"
      {...rest}
    />
    <Iconography
      className={classNames('form-checkbox__box', {[`form-checkbox__box${type}`]: type})}
      icon={ICON.CLOSE}
    />
    <Typography
      className={classNames('form-checkbox__content', {[`form-checkbox__content${type}`]: type})}
      variant={VARIANT.CONTACT}
    >
      {label}
    </Typography>
  </label>
);

FormCheckbox.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  inputClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  type: PropTypes.string,
};

FormCheckbox.defaultProps = {
  handleChange: () => {},
  secondary: false,
  type: CHECKBOX_TYPE.TYPE_REGULAR,
};

export default React.memo(FormCheckbox);
