import React from 'react';

import CmsLink from './../../components/CmsLink/CmsLink';
// eslint-disable-next-line max-len
import CmsRelatedCertifications from './../../components/CmsComponents/CmsRelatedCertifications/CmsRelatedCertifications';

const RoutingCmsRelatedCertifications = ({content}) => (
  <CmsRelatedCertifications
    content={content}
    renderContainer={(children, index) =>
      content.items[index].file && content.items[index].file.file ? (
        <CmsLink slug={content.items[index].file.file}>{children}</CmsLink>
      ) : (
        React.cloneElement(children)
      )
    }
  />
);

export default React.memo(RoutingCmsRelatedCertifications);
