import React from 'react';
import {compose} from 'redux';

import withLocationSearch from '../../_hocs/withLocationSearch';
import Helmet from './../../seo/Helmet';
import removeParamsFromQueryStringByStrategy, {
  FIELDS_FILTER,
} from './../../_util/removeParamsFromQueryStringByStrategy';

import SearchContent from './SearchContent/SearchContent';

const Search = ({search: {search, ...rest}}) => (
  <div className="search">
    <Helmet noCrawl />
    <SearchContent
      filter={removeParamsFromQueryStringByStrategy({queryString: rest, strategyKeys: FIELDS_FILTER})}
      query={search}
    />
  </div>
);

export default compose(
  withLocationSearch,
  React.memo,
)(Search);
