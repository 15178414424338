import React from 'react';
import {compose} from 'redux';
import {useSelector} from 'react-redux';
import get from 'lodash.get';
import withSize from './../../../_packages/resize/ui/withSize';

import './LandingFooter.scss';
import Picture from './../../atoms/Picture/Picture';

const Footer = () => {
  const menus = useSelector(state => get(state, 'api.backendContext.resource.menus'));
  if (!menus) {
    return null;
  }
  const footer = menus.filter(menu => menu.originalSlug === 'footer')[0];

  const contactText = footer.options.center.title.replace('– ', '');

  return (
    <footer className="footer column">
      <div className="footer__top-wrapper">
        <div className="footer__logo">
          <Picture alt="tecnalia-logo" src="/images/logo-bw-white.svg" />
        </div>
        <div className="footer__contact">
          <h3 className="fs--label">{contactText}</h3>
          <div className="footer__location">
            <div className="footer__address" dangerouslySetInnerHTML={{__html: footer.options.left.text}}></div>
          </div>
          <div className="footer__phone" dangerouslySetInnerHTML={{__html: footer.options.center.text}}></div>
        </div>
        <div className="footer__collaborators">
          <Picture
            alt="tecnalia-logo"
            className="footer__collaborator footer__collaborator--brta"
            src="/images/logo-brta.svg"
          />
          <Picture
            alt="tecnalia-logo"
            className="footer__collaborator footer__collaborator--earto"
            src="/images/logo-earto.png"
          />
          <Picture
            alt="tecnalia-logo"
            className="footer__collaborator footer__collaborator--euskampus"
            src="/images/logo-euskampus.svg"
          />
        </div>
      </div>
      <div className="fs--sm footer__bottom-wrapper">
        <div className="footer__copyright">{footer.options.bottom.text}</div>
        <ul className="footer__legal-links">
          <li>
            <a className="footer__legal-link footer__legal-link--first" href={footer.items[0].slug}>
              {footer.items[0].title}
            </a>
          </li>
          <li>
            <a className="footer__legal-link" href={footer.items[1].slug}>
              {footer.items[1].title}
            </a>
          </li>
          <li>
            <a className="footer__legal-link" href={footer.items[2].slug}>
              {footer.items[2].title}
            </a>
          </li>
          <li>
            <a className="footer__legal-link footer__legal-link--last" href={footer.items[3].slug}>
              {footer.items[3].title}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default compose(withSize, React.memo)(Footer);
