import React from 'react';
import PropTypes from 'prop-types';

import titleTextPropType from './../../_propTypes/titleTextPropType';

import Content from './../../atoms/Content/Content';

import Grid from './../../layouts/Grid/Grid';

import './ContentGridList.scss';

const ContentGridList = ({items, title}) => (
  <Grid
    className="content-grid-list"
    columnTag="li"
    columnsPerBreakpoint={{S: 1, M: 2}}
    itemClassName="content-grid-list__item"
    scrollAnimation={false}
    tag="ul"
    title={title}
  >
    {items.map((item, index) => (
      <Content key={index} tag="span">
        {item.text}
      </Content>
    ))}
  </Grid>
);

ContentGridList.propTypes = {
  items: PropTypes.arrayOf(titleTextPropType),
  title: PropTypes.string,
};

export default React.memo(ContentGridList);
