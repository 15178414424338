import PropTypes from 'prop-types';

import menuChildItemsPropType from './menuChildItemsPropType';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  menuId: PropTypes.number.isRequired,
  options: PropTypes.object,
  items: PropTypes.arrayOf(menuChildItemsPropType),
});
