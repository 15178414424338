import React from 'react';
import PropTypes from 'prop-types';

import filePropType from './../../_propTypes/filePropType';
import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import Picture from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import FullRow from './../../layouts/FullRow/FullRow';

import './CatalogueNoForm.scss';

const CatalogueNoForm = ({catalogueInfo, delay, scrollAnimation}) => {
  const {document, image} = catalogueInfo;

  return (
    <FullRow className="catalogue-download" delay={delay} scrollAnimation={scrollAnimation} tag="article">
      <Picture alt={image.alt} className="catalogue-download__image" src={image.src.small} />
      <Typography tag="h1" variant={VARIANT.BODY}>
        {catalogueInfo.title}
      </Typography>
      <InlineButton className="catalogue-download__button">
        <a href={document.file.url} rel="noopener noreferrer" target="_blank" title={document.linkText}>
          <Typography variant={VARIANT.BODY_SMALL}>{document.linkText}</Typography>
        </a>
      </InlineButton>
    </FullRow>
  );
};

CatalogueNoForm.propTypes = {
  catalogueInfo: PropTypes.shape({
    document: PropTypes.shape({linkText: PropTypes.string, file: filePropType}),
    image: responsiveImagePropType,
    type: PropTypes.string,
    delay: PropTypes.number,
  }),
};

CatalogueNoForm.defaultProps = {
  delay: 0,
  scrollAnimation: true,
};

export default React.memo(CatalogueNoForm);
