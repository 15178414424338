import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from './../Typography/Typography';

import './FixedButton.scss';

const FixedButton = ({children, className, ...rest}) => (
  <Typography className={classNames('fixed-button', className)} variant={VARIANT.CTA} {...rest}>
    {children}
  </Typography>
);

FixedButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

FixedButton.defaultProps = {
  tag: 'button',
};

export default React.memo(FixedButton);
