import React from 'react';

import {VARIANT} from './../../../atoms/Typography/Typography';

import CmsAccordion from './../CmsAccordion/CmsAccordion';

const CmsAccordionContent = ({content}) => {
  const contentAccordion = {
    title: '',
    items: [],
  };

  for (var i = 0; i < content.items.length; i++) {
    const values = {
      values: {
        title: content.items[i].title,
        items: [
          {
            values: {
              breakpoints: {
                desktop: 1,
                tablet: 1,
              },
              items: [
                {
                  type: 'grid_full_content',
                  text: content.items[i].text,
                },
              ],
            },
          },
        ],
      },
    };
    contentAccordion.items.push(values);
  }
  return <CmsAccordion content={contentAccordion} titleBig={true} titleVariant={VARIANT.BODY_BIG} />;
};

export default React.memo(CmsAccordionContent);
