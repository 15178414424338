import React from 'react';

import './LandingImageLink.scss';
import Picture from '../../../atoms/Picture/Picture';
import LandingIcon from '../LandingIcon/LandingIcon';
const LandingImageLink = ({index, url, image, text}) => (
  <a className="landing-image-link" href={url} key={index}>
    <Picture
      alt={image.alt}
      caption={image.caption}
      className="landing-image-link__background"
      cover
      key={index}
      src={image.src.medium}
      srcSet={[
        {
          src: image.src.small,
          maxWidth: 480,
        },
      ]}
    />
    <div className="landing-image-link__content">
      <LandingIcon path="icons/arrow-right-circle.svg"></LandingIcon>
      <h2 className="landing-image-link__title">{text}</h2>
    </div>
  </a>
);

export default LandingImageLink;
