import PropTypes from 'prop-types';

export default PropTypes.shape({
  alt: PropTypes.string,
  caption: PropTypes.string,
  height: PropTypes.number,
  src: PropTypes.shape({
    thumbnail: PropTypes.string.isRequired,
    small: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
    large: PropTypes.string.isRequired,
    original: PropTypes.string.isRequired,
  }),
  width: PropTypes.number,
});
