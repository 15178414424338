import React from 'react';

import {PrimaryButton} from './../../../atoms/Button/Button';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

import CmsLink from './../../../components/CmsLink/CmsLink';

import Column from './../../../layouts/Column/Column';
import Row from './../../../layouts/Row/Row';

import {useSelector} from 'react-redux';

import './ContactModule.scss';
const getMenus = state =>
  state.api.backendContext && state.api.backendContext.resource && state.api.backendContext.resource.menus;

const ContactModule = ({content}) => {
  const menus = useSelector(state => getMenus(state));
  const contactMenu = menus && menus.filter(menu => menu.originalSlug === 'contact');
  const contact =
    contactMenu && contactMenu[0] && contactMenu[0].items && contactMenu[0].items.length && contactMenu[0].items[0];
  if (!menus) {
    return null;
  }
  return (
    <Row className="contact-module" tag="section">
      <Column className="contact-module__column">
        <div className="contact-module__content">
          <Typography className="contact-module__content-category" tag="h3" variant={VARIANT.HEADING_SMALL}>
            {content.subtitle}
          </Typography>
          <Typography tag="h2" variant={VARIANT.H1}>
            {content.title}
          </Typography>
          <Typography className="contact-module__content-text" tag="p" variant={VARIANT.BODY}>
            {content.description}
          </Typography>
          <div className="contact-module__buttons">
            <CmsLink className="contact-module__button" slug={content.linkUrl}>
              <PrimaryButton>
                <Typography variant={VARIANT.BODY_SMALL}>{content.linkTitle}</Typography>
              </PrimaryButton>
            </CmsLink>
            {contact && (
              <CmsLink className="contact-module__button" slug={contact.slug}>
                <PrimaryButton>
                  <Typography variant={VARIANT.BODY_SMALL}>{content.linkTitle2}</Typography>
                </PrimaryButton>
              </CmsLink>
            )}
          </div>
        </div>
      </Column>
    </Row>
  );
};

export default React.memo(ContactModule);
