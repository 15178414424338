import React, {useState} from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import classNames from 'classnames';
import {Spring} from 'react-spring/renderprops.cjs.js';

import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingLogosGrid from './../../_bridges/RoutingLogosGrid/RoutingLogosGrid';

import {getFullFormatDate, getMonthFromDate, getDayNumberFromDate} from './../../_util/getDateFromString';
import ConditionalWrap from './../../_util/ConditionalWrap';
import {mapEventToMicrodata} from '../../_util/Microdata/parseTemplateContentToMicrodata';

import EventMicrodata from './../../seo/Microdata/EventMicrodata';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import {ICON} from './../../atoms/Iconography/Iconography';

import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';
import CmsLink from './../../components/CmsLink/CmsLink';
import FeaturedLink from './../../components/FeaturedLink/FeaturedLink';
import TitleTextButton from './../../components/TitleTextButton/TitleTextButton';

import Hero from './../../compositions/Hero/Hero';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import FullRow from './../../layouts/FullRow/FullRow';
import Grid from './../../layouts/Grid/Grid';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './Event.scss';

const EventInfo = ({eventDates, place, time, price, text, url, intl}) => {
  const handleScrollTo = (e, anchor) => {
    if (anchor && anchor.charAt(0) === '#') {
      const element = document.querySelector(anchor);

      if (element) {
        e.preventDefault();
        const elementClientRect = element.getBoundingClientRect().top;
        const style = getComputedStyle(element.parentElement.parentElement.parentElement);
        const offset = style.opacity === '0' ? 350 : 150;

        const elementPosition = elementClientRect - offset;

        window.scrollBy({
          top: elementPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <Grid className="event__event-info" columnsPerBreakpoint={{S: 1, M: 2, XXL: 4}}>
      <div className="event__event-info-item">
        <Typography className="event__event-info-item-label" tag="p" variant={VARIANT.LABEL}>
          {intl.formatMessage({id: 'event.date'})}
        </Typography>
        <Typography tag="p" variant={VARIANT.BODY_XSMALL}>
          {eventDates}
        </Typography>
      </div>
      <div className="event__event-info-item">
        <Typography className="event__event-info-item-label" tag="p" variant={VARIANT.LABEL}>
          {intl.formatMessage({id: 'event.place'})}
        </Typography>
        <Typography tag="p" variant={VARIANT.BODY_XSMALL}>
          {place}
        </Typography>
      </div>
      <div className="event__event-info-item">
        <Typography className="event__event-info-item-label" tag="p" variant={VARIANT.LABEL}>
          {intl.formatMessage({id: 'event.time'})}
        </Typography>
        <Typography tag="p" variant={VARIANT.BODY_XSMALL}>
          {time ? time : '-'}
        </Typography>
      </div>
      <div className="event__event-info-item">
        <Typography className="event__event-info-item-label" tag="p" variant={VARIANT.LABEL}>
          {intl.formatMessage({id: 'event.price'})}
        </Typography>
        <Typography tag="p" variant={VARIANT.BODY_XSMALL}>
          {price ? (
            `${price} € ${intl.formatMessage({id: 'event.vat'})}`
          ) : text ? (
            <ConditionalWrap
              condition={url}
              wrap={children => (
                <CmsLink
                  className="event__event-info-item-link"
                  onClick={e => {
                    handleScrollTo(e, url);
                  }}
                  slug={url}
                >
                  {children}
                </CmsLink>
              )}
            >
              {text}
            </ConditionalWrap>
          ) : (
            intl.formatMessage({id: 'event.free'})
          )}
        </Typography>
      </div>
    </Grid>
  );
};

const AgendaTable = ({event, intl}) => {
  const [selected, setSelected] = useState(0);

  return (
    <FullRow className="event__agenda">
      <Typography className="event__agenda-title" tag="h3" variant={VARIANT.HEADING}>
        {intl.formatMessage({id: 'event.agenda'})}
      </Typography>
      {event.eventElementWrapper.items.map((day, index) => (
        <React.Fragment key={index}>
          <FeaturedLink
            className={classNames('event__agenda-header', {'event__agenda-header--selected': selected === index})}
            icon={ICON.PLUS}
            onClick={() => setSelected(selected === index ? null : index)}
            scrollAnimation={false}
            tag={FullRow}
            title={`${getDayNumberFromDate(day.date)} ${getMonthFromDate(day.date, intl.locale, 'long')}`}
            uppercaseTitle
          />
          {selected === index && (
            <Spring
              config={{tension: 120, friction: 14, clamp: true}}
              from={{transform: 'translateY(30px)', opacity: 0}}
              to={{transform: 'translateY(0)', opacity: 1}}
            >
              {props => (
                <div style={props}>
                  <table className="event__agenda-table">
                    <thead>
                      <tr>
                        <th>
                          <Typography className="event__agenda-table-header" tag="p" variant={VARIANT.LABEL}>
                            {intl.formatMessage({id: 'event.time'})}
                          </Typography>
                        </th>
                        <th>
                          <Typography className="event__agenda-table-header" tag="p" variant={VARIANT.LABEL}>
                            {intl.formatMessage({id: 'event.activity'})}
                          </Typography>
                        </th>
                        <th>
                          <Typography className="event__agenda-table-header" tag="p" variant={VARIANT.LABEL}>
                            {intl.formatMessage({id: 'event.host'})}
                          </Typography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {day.items.map((activity, index2) => (
                        <tr className="event__agenda-table-row" key={index2}>
                          <td className="event__agenda-table-cell">
                            <Typography tag="p">
                              {activity.startHour} - {activity.endHour}
                            </Typography>
                          </td>
                          <td className="event__agenda-table-cell">
                            <Typography tag="p">{activity.subject}</Typography>
                          </td>
                          <td className="event__agenda-table-cell">
                            <Typography tag="p">{activity.speaker}</Typography>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Spring>
          )}
        </React.Fragment>
      ))}
    </FullRow>
  );
};

const Event = ({canonical, content: event, intl}) => {
  const location = event.place.place ? event.place.place : event.place.delegation ? event.place.delegation.title : '';

  const startDate = `${getFullFormatDate(event.dateTimeRange.startDate, intl.locale)}`;
  const endDate = `${getFullFormatDate(event.dateTimeRange.endDate, intl.locale)}`;

  const eventDates = startDate + (startDate !== endDate ? ` - ${endDate}` : '');
  const time = `${event.dateTimeRange.startHour} - ${event.dateTimeRange.endHour}`;

  return (
    <div className="event">
      <Hero image={event.image} inverted title={event.title} type={intl.formatMessage({id: 'agenda.title'})} />
      <EventInfo
        eventDates={eventDates}
        intl={intl}
        place={location}
        price={event.price.price}
        text={event.price.text}
        time={time}
        url={event.price.url}
      />
      <MainLayout
        asideContent={
          <React.Fragment>
            {((!event.subscribeEvent.isExternal && event.formId.campaignId) ||
              (event.subscribeEvent.isExternal && event.subscribeEvent.cta.url)) && (
              <TitleTextButton
                formExtraInfo={event}
                formId={event.formId}
                formInfo={event.form.find(form => form.type === 'event')}
                isExternal={event.subscribeEvent.isExternal}
                text={event.subscribeEvent.text}
                title={event.subscribeEvent.title}
                url={event.subscribeEvent.cta.url}
              />
            )}
            {event.relatedContact.items.map((contact, index) => (
              <RoutingExpertModule
                expertPageSlug={contact.slug}
                formId={event.formId.id}
                formInfo={event.form.find(form => form.type === 'expert')}
                hidden={event.relatedContact.simplified}
                imgAlt={contact.title}
                imgSrc={contact.image.src.small}
                key={`relatedContact${index}`}
                name={contact.title}
                position={contact.position}
                templateTitle={event.title}
              />
            ))}
            {event.catalog.items.map((catalog, index) => (
              <CatalogueDownload
                catalogueInfo={catalog}
                formId={event.formId.catalogId}
                formInfo={event.form.find(form => form.type === 'download')}
                key={index}
              />
            ))}
          </React.Fragment>
        }
        canonical={canonical}
        mainContent={
          <React.Fragment>
            <CmsContent content={event.content} />
            {event.relatedPartner &&
              event.relatedPartner.map((partnerGroup, index) => (
                <RoutingLogosGrid
                  breakpoints={{S: 2, M: 3, L: 6}}
                  key={index}
                  logos={partnerGroup.items}
                  maxLogos={6}
                  title={partnerGroup.title}
                />
              ))}
          </React.Fragment>
        }
        socialText={event.title}
      />
      {event.eventElementWrapper.items.length > 0 && <AgendaTable event={event} intl={intl} />}

      <EventMicrodata canonical={canonical} event={mapEventToMicrodata(event)} />
    </div>
  );
};

export default compose(injectIntl, React.memo)(Event);
