import delegations, {delegationsRouteParams} from './delegations/routes';
import events, {eventsRouteParams} from './events/routes';
import infrastructures, {infrastrcuturesRouteParams} from './infrastructures/routes';
import inspiringStories, {inspiringStoriesRouteParams} from './inspiringStories/routes';
import labServices, {labServicesRouteParams} from './laboratoryServices/routes';
import pressReleases, {pressReleasesRouteParams} from './pressReleases/routes';
import ambits, {ambitsRouteParams} from './ambits/routes';
import technologies, {technologiesRouteParams} from './technologies/routes';
import news, {newsRouteParams} from './news/routes';
import search, {searchRoutesParams} from './search/routes';
import active, {activesRouteParams} from './actives/routes';
import company, {companiesRouteParams} from './companies/routes';
import publications, {publicationsRouteParams} from './publications/routes';
import project, {projectsRouteParams} from './projects/routes';
import inspiringBlog, {inspiringBlogRouteParams} from './inspiringBlog/routes';
import page from './page/routes';

export const HOME = 'home';

const routeParams = {
  ...delegationsRouteParams,
  ...eventsRouteParams,
  ...infrastrcuturesRouteParams,
  ...inspiringStoriesRouteParams,
  ...labServicesRouteParams,
  ...pressReleasesRouteParams,
  ...ambitsRouteParams,
  ...technologiesRouteParams,
  ...newsRouteParams,
  ...searchRoutesParams,
  ...activesRouteParams,
  ...companiesRouteParams,
  ...publicationsRouteParams,
  ...projectsRouteParams,
  ...inspiringBlogRouteParams,
};

export const getRestRoutes = routes =>
  Object.keys(routes).map(keyRoute => ({paths: routes[keyRoute], ...routeParams[keyRoute], exact: true, strict: true}));

const routes = {
  ...delegations,
  ...events,
  ...infrastructures,
  ...inspiringStories,
  ...labServices,
  ...pressReleases,
  ...ambits,
  ...technologies,
  ...news,
  ...search,
  ...active,
  ...company,
  ...publications,
  ...project,
  ...inspiringBlog,
};

export default {
  [HOME]: {
    es: '/(.*)?',
    en: '/(.*)?',
    eu: '/(.*)?',
    fr: '/(.*)?',
  },
  ...routes,
  ...page,
};
