import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import isSSR from './../../../config/isSSR';

import {ReactComponent as Close} from './../../svg/iconography/close.svg';
import {ReactComponent as Download} from './../../svg/iconography/download.svg';
import {ReactComponent as Instagram} from './../../svg/iconography/instagram.svg';
import {ReactComponent as Youtube} from './../../svg/iconography/youtube.svg';
import {ReactComponent as Flickr} from './../../svg/iconography/flickr.svg';
import {ReactComponent as Plus} from './../../svg/iconography/plus.svg';
import {ReactComponent as Menu} from './../../svg/iconography/menu.svg';
import {ReactComponent as Search} from './../../svg/iconography/search.svg';
import {ReactComponent as Pyke} from './../../svg/iconography/pyke.svg';
import {ReactComponent as RightArrow} from './../../svg/iconography/right-arrow.svg';
import {ReactComponent as Mail} from './../../svg/iconography/mail.svg';
import {ReactComponent as Twitter} from './../../svg/iconography/twitter.svg';
import {ReactComponent as Whatsapp} from './../../svg/iconography/whatsapp.svg';
import {ReactComponent as Share} from './../../svg/iconography/share.svg';
import {ReactComponent as Linkedin} from './../../svg/iconography/linkedin.svg';
import {ReactComponent as Facebook} from './../../svg/iconography/facebook.svg';
import {ReactComponent as Rss} from './../../svg/iconography/rss.svg';
import {ReactComponent as iVoox} from './../../svg/iconography/ivoox.svg';
import {ReactComponent as TikTok} from './../../svg/iconography/tik-tok.svg';
import {ReactComponent as UpArrow} from './../../svg/iconography/up-arrow.svg';
import {ReactComponent as Less} from './../../svg/iconography/less.svg';
import {ReactComponent as ResearchGate} from './../../svg/iconography/researchgate.svg';
import {ReactComponent as Place} from './../../svg/iconography/place.svg';
import {ReactComponent as Fullscreen} from './../../svg/iconography/fullscreen.svg';
import {ReactComponent as FullscreenClose} from './../../svg/iconography/fullscreen-close.svg';
import {ReactComponent as ForwardArrow} from './../../svg/iconography/forward-arrow.svg';
import {ReactComponent as Play} from './../../svg/iconography/play.svg';
import {ReactComponent as Open} from './../../svg/iconography/open.svg';
import {ReactComponent as ChevronUp} from './../../svg/iconography/chevron-up.svg';
import {ReactComponent as Filter} from './../../svg/iconography/filter.svg';

import './Iconography.scss';

const ICON = {
  CLOSE: 'CLOSE',
  DOWNLOAD: 'DOWNLOAD',
  INSTAGRAM: 'INSTAGRAM',
  YOUTUBE: 'YOUTUBE',
  FLICKR: 'FLICKR',
  PLUS: 'PLUS',
  MENU: 'MENU',
  SEARCH: 'SEARCH',
  PYKE: 'PYKE',
  RIGHT_ARROW: 'RIGHT_ARROW',
  MAIL: 'MAIL',
  TWITTER: 'TWITTER',
  WHATSAPP: 'WHATSAPP',
  SHARE: 'SHARE',
  LINKEDIN: 'LINKEDIN',
  FACEBOOK: 'FACEBOOK',
  RSS: 'RSS',
  IVOOX: 'IVOOX',
  TIKTOK: 'TIKTOK',
  UP_ARROW: 'UP_ARROW',
  LESS: 'LESS',
  RESEARCHGATE: 'RESEARCHGATE',
  PLACE: 'PLACE',
  FULLSCREEN: 'FULLSCREEN',
  FULLSCREEN_CLOSE: 'FULLSCREEN_CLOSE',
  FORWARD_ARROW: 'FORWARD_ARROW',
  PLAY: 'PLAY',
  OPEN: 'OPEN',
  CHEVRON_UP: 'CHEVRON_UP',
  FILTER: 'FILTER',
};

const ICON_MAP = {
  [ICON.CLOSE]: Close,
  [ICON.DOWNLOAD]: Download,
  [ICON.INSTAGRAM]: Instagram,
  [ICON.YOUTUBE]: Youtube,
  [ICON.FLICKR]: Flickr,
  [ICON.PLUS]: Plus,
  [ICON.MENU]: Menu,
  [ICON.SEARCH]: Search,
  [ICON.PYKE]: Pyke,
  [ICON.RIGHT_ARROW]: RightArrow,
  [ICON.MAIL]: Mail,
  [ICON.TWITTER]: Twitter,
  [ICON.WHATSAPP]: Whatsapp,
  [ICON.SHARE]: Share,
  [ICON.LINKEDIN]: Linkedin,
  [ICON.FACEBOOK]: Facebook,
  [ICON.RSS]: Rss,
  [ICON.IVOOX]: iVoox,
  [ICON.TIKTOK]: TikTok,
  [ICON.UP_ARROW]: UpArrow,
  [ICON.LESS]: Less,
  [ICON.RESEARCHGATE]: ResearchGate,
  [ICON.PLACE]: Place,
  [ICON.FULLSCREEN]: Fullscreen,
  [ICON.FULLSCREEN_CLOSE]: FullscreenClose,
  [ICON.FORWARD_ARROW]: ForwardArrow,
  [ICON.PLAY]: Play,
  [ICON.OPEN]: Open,
  [ICON.CHEVRON_UP]: ChevronUp,
  [ICON.FILTER]: Filter,
};

const Iconography = ({className, icon, ...rest}) => {
  const IconSvg = ICON_MAP[icon];
  if (!isSSR() && !IconSvg) {
    throw new TypeError(`Provided {icon: ${icon} combination does not match any available Iconography`);
  }

  return <span className={classNames('iconography', className)}>{IconSvg && <IconSvg {...rest} />}</span>;
};

Iconography.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(ICON)).isRequired,
};

export {ICON};
export default React.memo(Iconography);
