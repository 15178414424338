import React from 'react';

import CmsLink from './../../components/CmsLink/CmsLink';
import CmsCard from './../../components/CmsComponents/CmsCard/CmsCard';

const RoutingCmsCard = ({...rest}) => (
  <CmsCard
    renderLink={(children, link) => (link ? <CmsLink slug={link}>{children}</CmsLink> : React.cloneElement(children))}
    {...rest}
  />
);

export default React.memo(RoutingCmsCard);
