import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';

import './Section.scss';

const Section = ({backgroundType, children, className, color, image, pattern, ...rest}) => {
  const sectionStyle = {};
  if (backgroundType === 'color' && color) {
    sectionStyle.backgroundColor = color;
  }
  if (backgroundType === 'image' && image && image.src) {
    sectionStyle.backgroundImage = `url(${image.src.large})`;
  }
  if (backgroundType === 'pattern' && pattern) {
    let patternUrl = '';
    switch (pattern) {
      case 'circles':
        patternUrl = '/images/circles-background.svg';
        break;
      case 'tech_mesh_2':
        patternUrl = '/images/tech-mesh-background-2.svg';
        break;
      default:
        patternUrl = '/images/tech-mesh-background.svg';
    }
    sectionStyle.backgroundImage = `url(${patternUrl})`;
  }
  return (
    <section className={classNames('section', className)} style={sectionStyle} {...rest}>
      <div className={'section__content'}>{React.Children.map(children, item => item)}</div>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  // eslint-disable-next-line react/no-typos
  image: PropTypes.responsiveImagePropType,
  pattern: PropTypes.string,
};

Section.defaultProps = {};

export default compose(React.memo, withSize)(Section);
