import React from 'react';
import {FormattedNavLink} from '@foes/react-i18n-routing';

import {INSPIRING_STORY} from '../../../routing/inspiringStories/routes';

import InspiringStoryPreview from './../../components/InspiringStoryPreview/InspiringStoryPreview';

const RoutingInspiringStoryPreview = ({inspiringStory, ...rest}) => (
  <InspiringStoryPreview
    inspiringStory={inspiringStory}
    renderContainer={children => (
      <FormattedNavLink name={INSPIRING_STORY} params={{slug: inspiringStory.slug}}>
        {children}
      </FormattedNavLink>
    )}
    {...rest}
  />
);
export default RoutingInspiringStoryPreview;
