import PropTypes from 'prop-types';

import responsiveImagePropType from './responsiveImagePropType';

export default PropTypes.shape({
  mediaGallery: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        image: responsiveImagePropType,
        platform: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
});
