import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import VideoComponent from './VideoComponent/VideoComponent';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Picture from './../../atoms/Picture/Picture';
import Content from '../../atoms/Content/Content';

import Slider from './../Slider/Slider';

import FullRow from './../../layouts/FullRow/FullRow';

import './MediaGallery.scss';

const mediaItem = item => {
  switch (item.type) {
    case 'media_gallery_image': {
      return (
        <Picture
          alt={item.alt}
          asyncLoad={false}
          cover={false}
          src={item.image.src.large}
          srcSet={[
            {src: item.image.src.small, maxWidth: 479},
            {src: item.image.src.medium, maxWidth: 1279},
          ]}
        />
      );
    }
    case 'media_gallery_video':
      return <VideoComponent item={item} />;
    default:
      return true;
  }
};

const MediaGallery = ({gallery, intl}) => {
  const items = gallery.elements[0].mediaGallery.items;
  return (
    <FullRow className="media-gallery" scrollAnimation={false}>
      <Typography className="media-gallery__title" tag="h3" variant={VARIANT.HEADING}>
        {gallery.title || intl.formatMessage({id: 'gallery.title'})}
      </Typography>
      <Slider allowFullScreen gallery invertedButtons slidesPerBreakpoint={{S: 1, XL: 2, XXXXL: 3}}>
        {items.map((item, index) => (
          <div className="media-gallery__item" key={index}>
            {mediaItem(item)}
            {item.description && (
              <Content className="media-gallery__item-caption" raw variant={VARIANT.BODY}>
                {item.description}
              </Content>
            )}
          </div>
        ))}
      </Slider>
    </FullRow>
  );
};

export default compose(injectIntl, React.memo)(MediaGallery);
