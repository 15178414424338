import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import {NEWS} from './../../../routing/news/routes';
import {INSPIRING_BLOG} from '../../../routing/inspiringBlog/routes';

import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import RelatedContentCards from './../RelatedContentCards/RelatedContentCards';

import './RelatedNews.scss';

const RelatedNews = ({cards, slidesPerBreakpoint, intl, title}) => (
  <div className="related-news">
    <RelatedContentCards slidesPerBreakpoint={slidesPerBreakpoint} title={title}>
      {cards.map((card, index) => (
        <RoutingGenericPreview
          key={index}
          label={intl.formatMessage({id: card.type === 'news' ? 'news.category' : 'blog.category'})}
          preview={card}
          to={card.type === 'news' ? NEWS : INSPIRING_BLOG}
        />
      ))}
    </RelatedContentCards>
  </div>
);

RelatedNews.propTypes = {
  cards: PropTypes.array,
  slidesPerBreakpoint: PropTypes.object,
  title: PropTypes.string,
};

RelatedNews.defaultProps = {
  slidesPerBreakpoint: {S: 1, L: 2, XXXXL: 3},
};

export default compose(injectIntl, React.memo)(RelatedNews);
