import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography from './../../atoms/Iconography/Iconography';

import Row from './../../layouts/Row/Row';
import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './FeaturedLink.scss';

const FeaturedLink = ({
  className,
  delay,
  icon,
  renderContainer,
  title,
  uppercaseTitle,
  tag: FeaturedLinkTag,
  scrollAnimation,
  ...rest
}) => (
  <FeaturedLinkTag
    className={classNames('featured-link', className)}
    delay={delay}
    scrollAnimation={scrollAnimation}
    {...rest}
  >
    <Column className="featured-link__column">
      {renderContainer(
        <div className="featured-link__wrapper">
          <Typography
            className={classNames('featured-link__title', {'feature-link__title--uppercase': uppercaseTitle})}
            variant={VARIANT.BODY}
            {...rest}
          >
            {title}
          </Typography>
          <Iconography className="featured-link__icon" icon={icon} />
        </div>,
      )}
    </Column>
  </FeaturedLinkTag>
);

FeaturedLink.propTypes = {
  area: PropTypes.object,
  className: PropTypes.string,
  delay: PropTypes.number,
  icon: PropTypes.string.isRequired,
  renderContainer: PropTypes.func,
  tag: PropTypes.oneOf([Row, FullRow]),
  uppercaseTitle: PropTypes.bool,
};

FeaturedLink.defaultProps = {
  delay: 0,
  renderContainer: children => children,
  tag: Row,
  uppercaseTitle: false,
};

export default React.memo(FeaturedLink);
