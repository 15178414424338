import React from 'react';
import {compose} from 'redux';

import './CmsDivider.scss';

const CmsDivider = () => (
  <div>
    <hr className="divider" />
  </div>
);

export default compose(React.memo)(CmsDivider);
