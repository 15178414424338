import PropTypes from 'prop-types';

import responsiveImagePropType from './responsiveImagePropType';

export default PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      image: responsiveImagePropType,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
});
