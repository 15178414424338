import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingInfrastructures from './../../_bridges/RoutingInfrastructures/RoutingInfrastructures';
import RoutingLogosGrid from './../../_bridges/RoutingLogosGrid/RoutingLogosGrid';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import {INFRASTRUCTURE} from './../../../routing/infrastructures/routes';
import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {LABORATORY_SERVICE} from './../../../routing/laboratoryServices/routes';
import {AMBIT} from './../../../routing/ambits/routes';

import Content from './../../atoms/Content/Content';
import {VARIANT} from './../../atoms/Typography/Typography';

import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';

import Hero from './../../compositions/Hero/Hero';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';
import NewsAgendaModule from './../../compositions/NewsAgendaModule/NewsAgendaModule';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './LabService.scss';

const LabService = ({canonical, content: labService, intl}) => (
  <div className="lab-service">
    <Hero
      button={labService.form.find(form => form.type === 'budget').title}
      formId={labService.formId.id}
      formInfo={labService.form.find(form => form.type === 'budget')}
      image={labService.image}
      title={labService.title}
      type={intl.formatMessage({id: 'lab.service.category'})}
    />
    <MainLayout
      asideContent={
        <React.Fragment>
          {labService.relatedContact.items.length > 0 &&
            labService.relatedContact.items.map((contact, index) => (
              <RoutingExpertModule
                expertPageSlug={contact.slug}
                formId={labService.formId.id}
                formInfo={labService.form.find(form => form.type === 'expert')}
                hidden={labService.relatedContact.simplified}
                imgAlt={contact.title}
                imgSrc={contact.image.src.small}
                key={index}
                name={contact.title}
                position={contact.position}
                templateTitle={labService.title}
              />
            ))}

          {labService.catalog &&
            labService.catalog.items.map((catalog, index) => (
              <CatalogueDownload
                catalogueInfo={catalog}
                formId={labService.formId.catalogId}
                formInfo={labService.form.find(form => form.type === 'download')}
                key={index}
              />
            ))}

          {labService.relatedInfrastructure.items.length > 0 && (
            <RoutingInfrastructures
              infrastructures={labService.relatedInfrastructure.items}
              title={labService.relatedInfrastructure.title}
              to={INFRASTRUCTURE}
            />
          )}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <React.Fragment>
          <Content className="lab-service__main-excerpt" raw variant={VARIANT.H3}>
            {labService.detailedExcerpt}
          </Content>

          {labService.ambits && labService.ambits.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={labService.ambits.items}
              title={labService.ambits.title}
              to={AMBIT}
            />
          )}

          {labService.sectors && labService.sectors.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={labService.sectors.items}
              title={labService.sectors.title}
            />
          )}
          {labService.content && <CmsContent content={labService.content} />}

          {labService.relatedCertification.items.length > 0 && (
            <RoutingLogosGrid
              breakpoints={{S: 1, M: 2}}
              hasDescription
              logos={labService.relatedCertification.items}
              maxLogos={6}
              title={labService.relatedCertification.title}
              withBackground
            />
          )}
        </React.Fragment>
      }
      socialText={labService.title}
    />

    {labService.technologies && labService.technologies.items.length > 0 && (
      <RelatedContentCards title={labService.technologies.title}>
        {labService.technologies.items.map((relatedTechnology, index) => (
          <RoutingGenericPreview
            horizontal
            key={index}
            label={intl.formatMessage({id: 'technology.category'})}
            preview={relatedTechnology.fields.relatedPage}
            to={TECHNOLOGY}
          />
        ))}
      </RelatedContentCards>
    )}

    {labService.relatedMediaGallery.elements &&
      labService.relatedMediaGallery.elements.length > 0 &&
      labService.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={labService.relatedMediaGallery} />
      )}

    <NewsAgendaModule newsCards={labService.relatedNews.items} newsTitle={labService.relatedNews.title} />

    {labService.relatedService.items.length > 0 && (
      <RelatedContentCards title={labService.relatedService.title} withBackground>
        {labService.relatedService.items.map((item, index) => (
          <RoutingGenericPreview
            horizontal
            key={index}
            label={intl.formatMessage({id: 'lab.service.category'})}
            preview={item}
            to={LABORATORY_SERVICE}
          />
        ))}
      </RelatedContentCards>
    )}
  </div>
);

export default compose(React.memo, injectIntl)(LabService);
