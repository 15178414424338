import React from 'react';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import './TitleTextComponent.css';

const TitleTextComponent = ({raw, id, text, title, biggerTitle, textVariant, titleTag, textTag}) => (
  <div className="title-text-component" id={id}>
    {title && (
      <Typography
        className="title-text-component__title"
        tag={titleTag}
        variant={biggerTitle ? VARIANT.HEADING : VARIANT.HEADING_SMALL}
      >
        {title}
      </Typography>
    )}
    {text && (
      <Content className="title-text-component__content" raw={raw} tag={textTag} variant={textVariant}>
        {text}
      </Content>
    )}
  </div>
);

TitleTextComponent.propTypes = {
  biggerTitle: PropTypes.bool,
  id: PropTypes.string,
  raw: PropTypes.bool,
  text: PropTypes.string,
  textTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'span']),
  textVariant: PropTypes.oneOf(Object.values(VARIANT)),
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'span']),
};

TitleTextComponent.defaultProps = {
  biggerTitle: false,
  raw: false,
  textVariant: VARIANT.BODY,
  titleTag: 'span',
  textTag: 'span',
};

export default React.memo(TitleTextComponent);
