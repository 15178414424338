const addItems = (setItems, content) => {
  if (content.items.length > 0) {
    content.items.forEach((item) => {
      if (item.anchor) {
        const element = document.querySelector(item.anchor);
        if (element) {
          setItems((elements) => [...elements, element]);
        }
      }
    });
  }
};

const selectItem = (setSelected, items) => {
  let selected = {};
  const offset = 175;
  items.forEach((item) => {
    const itemOffset = item.getBoundingClientRect();
    if (itemOffset.top - offset < 0) {
      if (Object.keys(selected).length) {
        if (itemOffset.top > selected.offset) {
          selected = {
            offset: itemOffset.top,
            el: item,
          };
        }
      } else {
        selected = {
          offset: itemOffset.top,
          el: item,
        };
      }
    }
  });
  if (Object.keys(selected).length) {
    setSelected(selected.el);
  } else {
    setSelected(selected.el);
  }
};

export {addItems, selectItem};
