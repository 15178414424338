import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ControlledPlayer from './../../layouts/ControlledPlayer/ControlledPlayer';

import './Video.scss';

const Video = ({className, controls, playerProps, src, withRatio, ...rest}) => (
  <div className={classNames('video', {'video--with-ratio': withRatio}, className)} {...rest}>
    <ControlledPlayer className="video__player" controls={controls} url={src} {...playerProps} />
  </div>
);

Video.propTypes = {
  className: PropTypes.string,
  controls: PropTypes.bool,
  playerProps: PropTypes.object,
  src: PropTypes.string.isRequired,
  withRatio: PropTypes.bool,
};

Video.defaultProps = {
  controls: true,
  playerProps: {},
  withRatio: true,
};

export default React.memo(Video);
