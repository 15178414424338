export const PAGE = 'page';

export default {
  [PAGE]: {
    es: '/:slug',
    en: '/:slug',
    eu: '/:slug',
    fr: '/:slug',
  },
};
