import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import {getFullFormatDate} from './../../_util/getDateFromString';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import {TECHNOLOGY} from './../../../routing/technologies/routes';

import {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import Hero from './../../compositions/Hero/Hero';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import NewsAgendaModule from './../../compositions/NewsAgendaModule/NewsAgendaModule';

import MainLayout from './../../layouts/MainLayout/MainLayout';

import './News.scss';

const News = ({canonical, content: news, intl}) => (
  <div className="news">
    <Hero
      date={getFullFormatDate(news.createdAt, intl.locale)}
      image={news.image}
      inverted
      title={news.title}
      type={intl.formatMessage({id: 'news.category'})}
    />
    <MainLayout
      asideContent={
        <React.Fragment>
          {news.relatedContact.items.map((contact, index) => (
            <RoutingExpertModule
              expertPageSlug={contact.slug}
              formId={news.formId.id}
              formInfo={news.form.find(form => form.type === 'expert')}
              hidden={news.relatedContact.simplified}
              imgAlt={contact.title}
              imgSrc={contact.image.src.small}
              key={`relatedContact${index}`}
              name={contact.title}
              position={contact.position}
              templateTitle={news.title}
            />
          ))}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <React.Fragment>
          <Content className="news__main-excerpt" raw variant={VARIANT.H3}>
            {news.excerpt}
          </Content>

          <Content className="news__main-content" raw>
            {news.content}
          </Content>
          {news.relatedTechnologies && news.relatedTechnologies.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={news.relatedTechnologies.items}
              title={news.relatedTechnologies.title}
              to={TECHNOLOGY}
            />
          )}
        </React.Fragment>
      }
      socialText={news.title}
    />

    {news.relatedMediaGallery.elements &&
      news.relatedMediaGallery.elements.length > 0 &&
      news.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={news.relatedMediaGallery} />
      )}

    <NewsAgendaModule
      newsCards={news.relatedNews.items}
      newsTitle={news.relatedNews.title}
      slidesPerBreakpoint={{S: 1, L: 2, XL: 3, XXXXL: 4}}
    />
  </div>
);

export default compose(injectIntl, React.memo)(News);
