import React from 'react';
import {FormattedNavLink} from '@foes/react-i18n-routing';

import {LABORATORY_SERVICE} from '../../../routing/laboratoryServices/routes';
import {DELEGATION} from './../../../routing/delegations/routes';

import FeaturedPreviewCard from './../../components/FeaturedPreview/FeaturedPreviewCard/FeaturedPreviewCard';

const getLinkTarget = type => {
  switch (type) {
    case 'laboratory': {
      return LABORATORY_SERVICE;
    }
    case 'delegation': {
      return DELEGATION;
    }
    default: {
      return null;
    }
  }
};

const RoutingFeaturedPreviewCard = ({slug, type, wholeCardIsLink, ...rest}) => (
  <FeaturedPreviewCard
    renderContainer={children =>
      slug && !wholeCardIsLink ? (
        <FormattedNavLink name={getLinkTarget(type)} params={{slug: slug}}>
          {children}
        </FormattedNavLink>
      ) : (
        React.cloneElement(children)
      )
    }
    renderFullContainer={children =>
      slug && wholeCardIsLink ? (
        <FormattedNavLink name={getLinkTarget(type)} params={{slug: slug}}>
          {children}
        </FormattedNavLink>
      ) : (
        React.cloneElement(children)
      )
    }
    wholeCardIsLink={wholeCardIsLink}
    {...rest}
  />
);

export default React.memo(RoutingFeaturedPreviewCard);
