import React from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';

import isExternalUrl from './../../../_util/isExternalUrl';
import {pathValidationRegex, getDomainFromUrl} from './../../../_util/getDomainFromUrl';
import replaceURLForPhoneOrMail from './../../_util/replaceURLForPhoneOrMail';

const ensureInitialSlash = slug => {
  if (!slug || slug.substring(0, 1) === '/' || slug.substring(0, 1) === '#') {
    return slug;
  }
  const domain = getDomainFromUrl(process.env.REACT_APP_HOST_DOMAIN);
  const regex = pathValidationRegex(domain);
  return slug.match(regex)[1];
};

const CmsLink = ({children, forceExternal, slug, ...rest}) =>
  forceExternal || isExternalUrl(slug) ? (
    <a href={replaceURLForPhoneOrMail(slug)} rel="noopener noreferrer nofollow" target="_blank" {...rest}>
      {children}
    </a>
  ) : (
    <NavLink to={ensureInitialSlash(slug)} {...rest}>
      {children}
    </NavLink>
  );

CmsLink.propTypes = {
  children: PropTypes.node.isRequired,
  forceExternal: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

CmsLink.defaultProps = {
  forceExternal: false,
};

export default React.memo(CmsLink);
