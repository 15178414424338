import React from 'react';
import LandingIcon from '../LandingIcon/LandingIcon';
import './LandingButtonLink.scss';

const LandingButtonLink = ({index, url, text}) => (
  <a className="fs--label-small landing-button-link" href={url} key={index}>
    <h2 className="landing-button-link__title">{text}</h2>
    <LandingIcon path={'/icons/arrow-right.svg'}></LandingIcon>
  </a>
);

export default LandingButtonLink;
