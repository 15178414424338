import React from 'react';
import PropTypes from 'prop-types';
import {FormattedLink} from '@foes/react-i18n-routing';

import {EVENTS} from './../../../../routing/events/routes';

import AnimatedInlineButton from './../../../atoms/AnimatedInlineButton/AnimatedInlineButton';
import {VARIANT} from './../../../atoms/Typography/Typography';

import RelatedAgenda from './../../../compositions/RelatedAgenda/RelatedAgenda';

import FullRow from './../../../layouts/FullRow/FullRow';

import './AgendaModule.scss';

const AgendaModule = ({agenda, linkText, title, scrollAnimation, subtitle}) => (
  <FullRow className="agenda-module" scrollAnimation={scrollAnimation}>
    <RelatedAgenda agenda={agenda} subtitle={subtitle} title={title} titleVariant={VARIANT.H1} />
    <div className="agenda-module__link-button">
      <FormattedLink className="agenda-module__link" name={EVENTS}>
        <AnimatedInlineButton text={linkText} />
      </FormattedLink>
    </div>
  </FullRow>
);

AgendaModule.propTypes = {
  agenda: PropTypes.array,
  linkText: PropTypes.string.isRequired,
  scrollAnimation: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

AgendaModule.defaultProps = {
  scrollAnimation: true,
};

export default React.memo(AgendaModule);
