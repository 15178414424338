import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import menuChildItemsPropType from './../../../_propTypes/menuChildItemsPropType';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

import CmsLink from './../../../components/CmsLink/CmsLink';

import FullRow from './../../../layouts/FullRow/FullRow';
import Grid from './../../../layouts/Grid/Grid';

import './MainMenuChild.scss';

const MainMenuChild = ({onSelected, items: {items, options = {}, title}}) => (
  <FullRow
    className={classNames('main-menu-child')}
    key={title}
    scrollAnimation={false}
    style={{background: `${options.color}`}}
  >
    {items && (
      <Grid className="main-menu-child__grid" columnsPerBreakpoint={{S: 1, M: 2, L: 4}} scrollAnimation={false}>
        {items.map(element => (
          <CmsLink
            className="main-menu-child__grid-item-link"
            key={element.menuId}
            onClick={() => onSelected()}
            slug={element.slug}
          >
            <Typography key={element.menuId} variant={VARIANT.BODY_SMALL}>
              {element.title}
            </Typography>
          </CmsLink>
        ))}
      </Grid>
    )}
  </FullRow>
);

MainMenuChild.propTypes = {
  isScrolled: PropTypes.bool.isRequired,
  items: menuChildItemsPropType,
  onSelected: PropTypes.func.isRequired,
};

MainMenuChild.defaultProps = {
  isScrolled: false,
};

export default React.memo(MainMenuChild);
