export const CMS_HOMEPAGE_SLUG = '/home';

export const TEMPLATE = {
  GENERIC_CONTAINER: 'genericContainer',
  DELEGATIONS: 'delegations',
  DELEGATION: 'delegation',
  AGENDA: 'agenda',
  AGENDA_PAST: 'agendaPast',
  EVENT: 'event',
  INFRASTRUCTURE: 'infrastructure',
  INSPIRING_STORY: 'inspiringStory',
  INSPIRING_BLOG_POST: 'inspiringBlogPost',
  INSPIRING_BLOG_POSTS: 'inspiringBlogPosts',
  INSPIRING_BLOG_CATEGORY: 'inspiringBlogCategory',
  INSPIRING_BLOG_TAG: 'inspiringBlogTag',
  LABORATORY_SERVICE: 'labService',
  PAGE: 'page',
  PRESS_RELEASE: 'pressRelease',
  PROJECT: 'project',
  AMBIT: 'ambit',
  RESEARCHER: 'researcher',
  SECTOR: 'sector',
  TECHNOLOGY: 'technology',
  NEWS: 'news',
  SEARCH: 'search',
  ACTIVE: 'active',
  COMPANY: 'company',
  PUBLICATIONS: 'publications',
  PUBLICATION: 'publication',
};

export const PAGE_TEMPLATE = {
  HOME: 'index',
  GENERIC: 'generic',
  LEGAL: 'legal',
  FLEXIBLE: 'flexible',
  DEFAULT: '',
  JOBS: 'job',
  ONEPAGE: 'onepage',
  LANDING: 'landing',
  LANDING_THANK_YOU: 'landing_thank_you',
};
