const pagePrintClick = () => ({
  event: 'Imprimir',
});

const pageLoadError404 = () => ({
  event: 'Error 404',
});

const pageLoadSuccess = (locale, templateType, inventory) => {
  const dataLayer = {
    language: locale,
    category: templateType,
    cod: null,
    type: null,
    une: null,
    div: null,
  };

  if (inventory) {
    dataLayer.cod = inventory.cod || null;
    dataLayer.type = inventory.type || null;
    dataLayer.une = inventory.une || null;
    dataLayer.div = inventory.div || null;
  }

  return dataLayer;
};

const commentFormSubmitOK = () => ({
  event: 'Comment form submit OK',
});
const commentFormSubmitKO = () => ({
  event: 'Comment form submit KO',
});

export {pagePrintClick, pageLoadError404, pageLoadSuccess, commentFormSubmitOK, commentFormSubmitKO};
