import PropTypes from 'prop-types';

import responsiveImagePropType from './responsiveImagePropType';

export default PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      excerpt: PropTypes.string,
      id: PropTypes.number,
      image: responsiveImagePropType.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      quote: PropTypes.shape({
        name: PropTypes.string,
        partner: PropTypes.object,
        position: PropTypes.string,
        quote: PropTypes.string,
      }),
    }),
  ),
  title: PropTypes.string,
});
