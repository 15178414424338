const initialState = {
  ssrWindowWidth: null, // This value will be set in the middleware as the preloadedState
};

export default (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
