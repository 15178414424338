const getRelNext = (currentPage, totalPages, route) =>
  parseInt(currentPage, 10) < parseInt(totalPages, 10) ? `${route}?page=${parseInt(currentPage, 10) + 1}` : null;

const getRelNextCategory = slug => (...params) =>
  getRelNext(...params) ? getRelNext(...params).replace(':slug', slug) : null;

const getRelPrev = (currentPage, route) => (currentPage > 1 ? `${route}?page=${parseInt(currentPage, 10) - 1}` : null);

const getRelPrevCategory = slug => (...params) =>
  getRelPrev(...params) ? getRelPrev(...params).replace(':slug', slug) : null;

export {getRelNext, getRelPrev, getRelNextCategory, getRelPrevCategory};
