import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'redux';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

import {getMonthFromDate, getDayNumberFromDate} from '../../_util/getDateFromString';
import FormattedLink from '../../_util/FormattedLink';

import Picture, {RATIO} from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import AnimatedInlineButton from '../../atoms/AnimatedInlineButton/AnimatedInlineButton';

import RoutingSocialSharePrint from '../../_bridges/RoutingSocialSharePrint/RoutingSocialSharePrint';

import './InspiringBlogPostPreview.scss';
import {INSPIRING_BLOG_CATEGORY} from '../../../routing/inspiringBlog/routes';

const InspiringBlogPostPreview = ({biggerImage, className, horizontal, preview, renderContainer, intl}) => (
  <div className={classNames('inspiring-blog-post-preview__wrapper', className)}>
    {renderContainer(
      <article
        className={classNames('inspiring-blog-post-preview', {
          'inspiring-blog-post-preview--horizontal': horizontal,
          'inspiring-blog-post-preview--bigger-image': biggerImage,
        })}
      >
        <div className="inspiring-blog-post-preview__picture">
          {preview.image && (
            <Picture
              alt={preview.image.alt || preview.image.title}
              className="inspiring-blog-post-preview__picture-image"
              cover
              ratio={RATIO.R_533_409}
              src={preview.image.src.medium}
              srcSet={[
                {
                  src: preview.image.src.small,
                  maxWidth: 480,
                },
              ]}
            />
          )}
          {preview.date && (
            <div className="inspiring-blog-post-preview__date">
              <Typography variant={VARIANT.BODY_SMALLER}>{getDayNumberFromDate(preview.date)}</Typography>
              <Typography variant={VARIANT.BODY_XSMALL_REGULAR}>
                {getMonthFromDate(preview.date, intl.locale)}
              </Typography>
            </div>
          )}
          <div className="inspiring-blog-post-preview__share">
            <RoutingSocialSharePrint print={false} text={preview.title} url={preview.permalink} white />
          </div>
        </div>

        <div className="inspiring-blog-post-preview__content">
          <div className="inspiring-blog-post-preview__title">
            {renderContainer(
              <Typography className="inspiring-blog-post-preview__title--content" variant={VARIANT.BODY_SMALL}>
                {preview.title}
              </Typography>,
            )}
          </div>
          <div className="inspiring-blog-post-preview__excerpt">
            <Typography
              className="inspiring-blog-post-preview__excerpt--content"
              variant={VARIANT.BODY_XSMALL_LOWERCASE}
            >
              <span dangerouslySetInnerHTML={{__html: preview.excerpt}} />
            </Typography>
          </div>
          {preview.categories && (preview.categories.length > 0 || typeof preview.categories === 'object') && (
            <div className="inspiring-blog-post-preview__category">
              <FormattedLink
                name={INSPIRING_BLOG_CATEGORY}
                params={{
                  slug: preview.categories.items
                    ? preview.categories.items[0].slug
                    : Object.values(preview.categoriesName)[0].slug,
                }}
                query=""
              >
                <Typography variant={VARIANT.BODY_XSMALL_LOWERCASE}>
                  {preview.categories.items
                    ? preview.categories.items[0].title
                    : preview.categoriesName[parseInt(preview.categories[0], 10)].name}
                </Typography>
              </FormattedLink>
            </div>
          )}
          <div className="inspiring-blog-post-preview__bottom">
            <div className="inspiring-blog-post-preview__left">
              {preview.author && preview.author.displayName && (
                <Typography variant={VARIANT.BODY_XSMALL_LOWERCASE}>
                  <FormattedHTMLMessage
                    className="inpiring-blog-post-preview__author"
                    id="blog.card.author"
                    values={{name: preview.author.displayName}}
                  />
                </Typography>
              )}
            </div>
            <div className="inspiring-blog-post-preview__right">
              {renderContainer(<AnimatedInlineButton text={<FormattedMessage id="inspiringBlog.read_more" />} />)}
            </div>
          </div>
        </div>
      </article>,
    )}
  </div>
);
InspiringBlogPostPreview.propTypes = {
  biggerImage: PropTypes.bool,
  content: PropTypes.node,
  horizontal: PropTypes.bool,
  label: PropTypes.string,
  preview: PropTypes.object,
  renderContainer: PropTypes.func,
};

InspiringBlogPostPreview.defaultProps = {
  biggerImage: false,
  horizontal: false,
  renderContainer: children => children,
};

export default compose(injectIntl, React.memo)(InspiringBlogPostPreview);
