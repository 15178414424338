import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Trail} from 'react-spring/renderprops.cjs.js';
import {compose} from 'redux';

import withSize from './../../../../_packages/resize/ui/withSize';
import BREAKPOINTS from './../../../_config/breakpoints';

import HeroCategoryTitle from './../../../components/HeroCategoryTitle/HeroCategoryTitle';

import FullRow from './../../../layouts/FullRow/FullRow';
import Breadcrumbs from './../../../layouts/Breadcrumbs/Breadcrumbs';

import './DelegationsHero.scss';

const getiframeheight = windowWidth => (windowWidth < BREAKPOINTS.M ? 300 : windowWidth < BREAKPOINTS.L ? 400 : 500);

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const DelegationsHero = ({component: HeroWrapper, title, type, windowWidth}) => {
  const [overlayIsClicked, setOverlayIsClicked] = useState(false);
  const overlayStyles = {
    background: 'transparent',
    position: 'relative',
    width: '100%',
    height: getiframeheight(windowWidth),
    top: getiframeheight(windowWidth),
    marginTop: getiframeheight(windowWidth) * -1,
    pointerEvents: overlayIsClicked ? 'none' : 'auto',
  };
  return (
    <HeroWrapper className="delegations-hero">
      <div className="delegations-hero__column delegations-hero__column-content">
        <Trail
          config={{duration: 1500}}
          from={{opacity: 0, transform: 'translateY(100px)'}}
          items={[
            <Breadcrumbs key="breadcrumbs" />,
            <div className="delegations-hero__common-content" key="content">
              <HeroCategoryTitle title={title} type={type} />
            </div>,
          ]}
          keys={item => item.key}
          to={{opacity: 1, transform: 'translateY(0)'}}
        >
          {(item, index) => props => (
            <div className={classNames({'delegations-hero__common-content': index === 1})} style={props}>
              {item}
            </div>
          )}
        </Trail>
      </div>
      <div className="delegations-hero__overlay" onClick={() => setOverlayIsClicked(true)} style={overlayStyles} />
      <iframe
        allowFullScreen
        height={getiframeheight(windowWidth)}
        src="https://www.google.com/maps/d/embed?mid=1ToW-gYoFik0hSn0NSrqP2vFHeVmV06n2&z=3"
        title="delegations-map"
        width="100%"
      />
    </HeroWrapper>
  );
};

DelegationsHero.propTypes = {
  component: PropTypes.shape(),
  title: PropTypes.string,
  type: PropTypes.string,
};

DelegationsHero.defaultProps = {
  component: FullRow,
};

export default compose(withSize, React.memo)(DelegationsHero);
