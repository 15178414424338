import React, {useState} from 'react';

import Typography, {VARIANT} from '../../atoms/Typography/Typography';
import {PrimaryButton} from './../../atoms/Button/Button';
import Content from './../../atoms/Content/Content';
import Modal from './../../atoms/Modal/Modal';

import Form from './../Forms/Form';
import CmsLink from './../CmsLink/CmsLink';

import Column from './../../layouts/Column/Column';
import FullRow from './../../layouts/FullRow/FullRow';

import './TitleTextButton.scss';

const TitleTextButton = ({
  title,
  text,
  hideForm,
  formExtraInfo,
  formInfo,
  formId,
  isExternal,
  url,
  templateTitle,
  scrollAnimation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FullRow className="title-text-button" scrollAnimation={scrollAnimation}>
      {title && (
        <Typography className="title-text-button__title" tag="h3" variant={VARIANT.HEADING_MEDIUM}>
          {title}
        </Typography>
      )}
      {text && (
        <Content className="title-text-button__text" raw variant={VARIANT.BODY_XSMALL_LOWERCASE}>
          {text}
        </Content>
      )}
      {!hideForm && !isExternal && (
        <PrimaryButton className="title-text-button__button" id="pardot" onClick={() => setIsOpen(true)}>
          <Typography variant={VARIANT.BODY_SMALL}>{formInfo.buttonTitle}</Typography>
        </PrimaryButton>
      )}
      {isExternal && (
        <CmsLink slug={url}>
          <PrimaryButton className="title-text-button__button" id="external" link_url={url}>
            <Typography variant={VARIANT.BODY_SMALL}>{formInfo.buttonTitle}</Typography>
          </PrimaryButton>
        </CmsLink>
      )}

      <FullRow>
        <Column>
          <Modal
            className="title-text-button__modal"
            fullHeight={true}
            isOpen={isOpen}
            onCloseClick={() => setIsOpen(false)}
          >
            <Form formExtraInfo={formExtraInfo} formId={formId} formInfo={formInfo} title={templateTitle} />
          </Modal>
        </Column>
      </FullRow>
    </FullRow>
  );
};

TitleTextButton.defaultProps = {
  isExternal: false,
  hideForm: false,
  scrollAnimation: true,
};

export default React.memo(TitleTextButton);
