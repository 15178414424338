import React from 'react';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from '../../atoms/Typography/Typography';

import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';

import './TitleComponent.css';

const TitleComponent = ({id, title, scrollAnimation, small}) => (
  <Row scrollAnimation={scrollAnimation}>
    <Column>
      <div className="title-component" id={id}>
        <Typography className="title-component__title" variant={small ? VARIANT.H2 : VARIANT.HUGE}>
          {title}
        </Typography>
      </div>
    </Column>
  </Row>
);

TitleComponent.propTypes = {
  id: PropTypes.string,
  small: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

TitleComponent.defaultProps = {
  scrollAnimation: true,
};

export default React.memo(TitleComponent);
