import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Trail} from 'react-spring/renderprops.cjs.js';

import {TECHNOLOGY} from './../../../routing/technologies/routes';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import Picture, {RATIO} from './../../atoms/Picture/Picture';

import HeroCategoryTitle from './../../components/HeroCategoryTitle/HeroCategoryTitle';

import FullRow from './../../layouts/FullRow/FullRow';
import Breadcrumbs from './../../layouts/Breadcrumbs/Breadcrumbs';

import './OnepageHero.scss';

const OnepageHero = ({color, title, type, backgroundImage, technologies}) => (
  <FullRow
    className={classNames('onepage-hero', {
      'onepage-hero--with-background': backgroundImage,
      'onepage-hero--gradient': color,
      [`onepage-hero--${color}`]: color && color !== 'none',
      [`onepage-hero--red`]: color && color === 'none',
    })}
    scrollAnimation={false}
  >
    <div className="onepage-hero__column onepage-hero__column-content">
      <Trail
        config={{tension: 210, friction: 35, delay: 500}}
        from={{opacity: 0, transform: 'translateY(100px)'}}
        items={[<Breadcrumbs key="breadcrumbs" />, <HeroCategoryTitle key="content" title={title} type={type} />]}
        keys={item => item.key}
        to={{opacity: 1, transform: 'translateY(0)'}}
      >
        {item => props => <div style={props}>{item}</div>}
      </Trail>
    </div>

    <div className="onepage-hero__column-full">
      {technologies && technologies.items.length > 0 && (
        <RoutingRelatedTags
          breakpoints={{S: 2, XL: 3, XXXL: 4}}
          className="onepage-hero__technologies"
          tags={technologies.items}
          title={technologies.title}
          to={TECHNOLOGY}
          withBorder={false}
        />
      )}
    </div>
    {backgroundImage && (
      <Picture
        alt={backgroundImage.alt}
        asyncLoad={false}
        className="onepage-hero__background"
        cover
        ratio={RATIO.R_4_3}
        src={backgroundImage.src.large}
        srcSet={[
          {src: backgroundImage.src.medium, maxWidth: 639},
          {src: backgroundImage.src.large, maxWidth: 1279},
        ]}
      />
    )}
  </FullRow>
);

OnepageHero.propTypes = {
  backgroundImage: responsiveImagePropType,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

OnepageHero.defaultProps = {
  color: '',
};

export default React.memo(OnepageHero);
