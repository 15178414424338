import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import classNames from 'classnames';

import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';

import Picture from './../../atoms/Picture/Picture';

import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';
import TitleTextButton from './../../components/TitleTextButton/TitleTextButton';
import AnchorMenu from './../../components/AnchorMenu/AnchorMenu';

import OnepageHero from './../../compositions/OnepageHero/OnepageHero';

import CmsContent from './../../layouts/CmsContent/CmsContent';
import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';
import GenericItems from './../../layouts/GenericItems/GenericItems';
import MainLayout from './../../layouts/MainLayout/MainLayout';
import Page from './../../layouts/Page/Page';

import './Onepage.scss';

const Onepage = ({canonical, content, intl}) => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    function changeSticky() {
      const onepageHero = document.querySelector('.onepage-hero');
      const onepageHeroOffset = onepageHero.getBoundingClientRect();
      if (onepageHeroOffset.bottom <= 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }

    window.addEventListener('scroll', changeSticky);
    return () => {
      window.removeEventListener('scroll', changeSticky);
    };
  }, []);
  return (
    <Page canonical={canonical} className="onepage" title={content.title}>
      <OnepageHero
        backgroundImage={content.image}
        color={content.gradient.type}
        sectors={content.sectors}
        technologies={content.technologies}
        title={content.title}
        type={intl.formatMessage({id: 'site.title'})}
      />
      <AnchorMenu content={content.menu} gradient={content.gradient.type} sticky={sticky}></AnchorMenu>
      <MainLayout
        asideContent={
          <React.Fragment>
            {content.relatedContact.items.length > 0 &&
              content.relatedContact.items.map((contact, index) => (
                <RoutingExpertModule
                  expertPageSlug={contact.slug}
                  formId={content.formId.id}
                  formInfo={content.form.find(form => form.type === 'expert')}
                  hidden={content.relatedContact.simplified}
                  imgAlt={contact.title}
                  imgSrc={contact.image.src.small}
                  key={index}
                  name={contact.title}
                  position={contact.position}
                  scrollAnimation={false}
                  templateTitle={content.title}
                />
              ))}
            {content.catalog.items.map((catalog, index) => (
              <CatalogueDownload
                catalogueInfo={catalog}
                formId={content.formId.catalogId}
                formInfo={content.form.find(form => form.type === 'download')}
                key={index}
                scrollAnimation={false}
              />
            ))}
            {content.whatWeSeek.title && (
              <TitleTextButton
                formId={content.formId.id}
                formInfo={content.form.find(form => form.type === 'expert')}
                hideForm={content.whatWeSeek.hideForm}
                scrollAnimation={false}
                templateTitle={content.title}
                text={content.whatWeSeek.text}
                title={content.whatWeSeek.title}
              />
            )}
          </React.Fragment>
        }
        canonical={canonical}
        className={classNames({
          'main-layout__sticky': sticky,
        })}
        mainContent={
          <div className="onepage__top">
            <div className="onepage__top-logo">
              <Picture
                alt={content.landingInfo.logo.alt}
                caption={content.landingInfo.logo.caption}
                showCaption={content.landingInfo.logo.showCaption}
                src={content.landingInfo.logo.src.thumbnail}
              />
            </div>
            {content.content && (
              <CmsContent className="onepage__top-content" content={content.content} scrollAnimation={false} />
            )}
          </div>
        }
        scrollAnimation={false}
        socialText={content.title}
      />
      <div className="opepage__content">
        <Row scrollAnimation={false}>
          <Column className="opepage__column">
            {content.fields && (
              <GenericItems className="onepage__main-content" items={content.fields.items} scrollAnimation={false} />
            )}

            {content.sectors && content.sectors.items.length > 0 && (
              <RoutingRelatedTags
                breakpoints={{S: 2, XL: 3, XXXL: 4}}
                className="onepage-hero__sectors"
                tags={content.sectors.items}
                title={content.sectors.title}
                withBorder={false}
              />
            )}
          </Column>
        </Row>
      </div>
    </Page>
  );
};

export default compose(injectIntl, React.memo)(Onepage);
