import React from 'react';

import './LandingImage.scss';
import Picture from '../../../atoms/Picture/Picture';

const LandingImage = ({label, labelColor, image, cover, techMesh}) => {
  const labelStyle = {
    color: labelColor,
  };

  return (
    <div className={`image__top-wrapper ${cover ? 'image__top-wrapper--cover' : 'image__top-wrapper--contain'}`}>
      {label && (
        <h2 className="fs--label" style={labelStyle}>
          {label}
        </h2>
      )}
      <div
        className={`image__wrapper ${cover ? 'image__wrapper--cover' : 'image__wrapper--contain'} ${
          techMesh ? 'image__wrapper--tech-mesh' : ''
        }`}
      >
        <Picture
          alt={image.alt}
          asyncLoad={false}
          className={`image ${cover ? 'image--cover' : 'image--contain'}`}
          cover={cover}
          imageClassName=""
          pictureClassName=""
          src={image.src.large}
          srcSet={[{src: image.src.small}, {src: image.src.medium}]}
        />

        {techMesh && (
          <img
            alt="point grid decoration"
            className="image__tech-mesh image__tech-mesh--top"
            src="/images/tech-mesh.svg"
          />
        )}
        {techMesh && (
          <img
            alt="point grid decoration"
            className="image__tech-mesh image__tech-mesh--bottom"
            src="/images/tech-mesh-2.svg"
          />
        )}
      </div>
    </div>
  );
};

export default LandingImage;
