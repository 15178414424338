import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Transition, animated} from 'react-spring/renderprops.cjs.js';
import get from 'lodash.get';
import {compose} from 'redux';
import objectFitImages from 'object-fit-images';
import {getLocale} from '@foes/react-i18n-routing/dist/common/locale';
import {pushDataLayer} from 'lin3s-react-analytics';

import ScrollToTop from './../../_behaviours/ScrollToTop';
import {extractSeoOpengraph, extractSeoTwitter} from './../../_util/seoExtract';
import {pageLoadSuccess} from './../../_util/dataLayerTransformation';
import {TEMPLATE, PAGE_TEMPLATE} from './../../../config/cmsSettings';
import isSSR from './../../../config/isSSR';
import i18n from './../../../i18n';
import {defaultUrl} from './../../seo/url';
import Loader from './../../atoms/Loader/Loader';
import Helmet from './../../seo/Helmet';
import BreadcrumbsMicrodata from './../../seo/Microdata/BreadcrumbsMicrodata';
import {getListRoute} from '../../_util/routes';

import BusinessOpportunity from './../../templates/BusinessOpportunity/BusinessOpportunity';
import GenericContainer from './../../templates/GenericContainer/GenericContainer';
import Agenda from './../../templates/Agenda/Agenda';
import Event from './../../templates/Event/Event';
import Infrastructure from './../../templates/Infrastructure/Infrastructure';
import InspiringStory from './../../templates/InspiringStory/InspiringStory';
import LabService from './../../templates/LabService/LabService';
import PressRelease from './../../templates/PressRelease/PressRelease';
import Ambit from './../../templates/Ambit/Ambit';
import Expert from './../../templates/Expert/Expert';
import Technology from './../../templates/Technology/Technology';
import News from './../../templates/News/News';
import Home from './../../templates/Homepage/Homepage';
import Search from './../../templates/Search/Search';
import Page from './../../templates/Page/Page';
import JobsPage from './../../templates/JobsPage/JobsPage';
import Delegations from './../../templates/Delegations/Delegations';
import Delegation from './../../templates/Delegation/Delegation';
import Publications from './../../templates/Publications/Publications';
import Onepage from './../../templates/Onepage/Onepage';
import Landing from './../../templates/Landing/Landing';
import LandingThankYou from './../../templates/Landing/LandingThankYou';
import Project from './../../templates/Project/Project';
import InspiringBlogPost from '../../templates/InspiringBlogPost/InspiringBlogPost';
import InspiringBlogPosts from '../../templates/InspiringBlogPosts/InspiringBlogPosts';
import InspiringBlogCategory from '../../templates/InspiringBlogCategory/InspiringBlogCategory';

import './BackendPage.css';

const componentForTemplateKey = {
  [TEMPLATE.GENERIC_CONTAINER]: GenericContainer,
  [TEMPLATE.AGENDA]: Agenda,
  [TEMPLATE.AGENDA_PAST]: Agenda,
  [TEMPLATE.EVENT]: Event,
  [TEMPLATE.INFRASTRUCTURE]: Infrastructure,
  [TEMPLATE.INSPIRING_STORY]: InspiringStory,
  [TEMPLATE.LABORATORY_SERVICE]: LabService,
  [TEMPLATE.PRESS_RELEASE]: PressRelease,
  [TEMPLATE.AMBIT]: Ambit,
  [TEMPLATE.RESEARCHER]: Expert,
  [TEMPLATE.TECHNOLOGY]: Technology,
  [TEMPLATE.INSPIRING_BLOG_POST]: InspiringBlogPost,
  [TEMPLATE.INSPIRING_BLOG_POSTS]: InspiringBlogPosts,
  [TEMPLATE.INSPIRING_BLOG_CATEGORY]: InspiringBlogCategory,
  [TEMPLATE.INSPIRING_BLOG_TAG]: InspiringBlogCategory,
  [TEMPLATE.NEWS]: News,
  [TEMPLATE.SEARCH]: Search,
  [TEMPLATE.DELEGATIONS]: Delegations,
  [TEMPLATE.DELEGATION]: Delegation,
  [TEMPLATE.ACTIVE]: BusinessOpportunity,
  [TEMPLATE.COMPANY]: BusinessOpportunity,
  [TEMPLATE.PUBLICATIONS]: Publications,
  [TEMPLATE.PROJECT]: Project,
  [TEMPLATE.PAGE]: {
    [PAGE_TEMPLATE.HOME]: Home,
    [PAGE_TEMPLATE.LEGAL]: Page,
    [PAGE_TEMPLATE.FLEXIBLE]: Page,
    [PAGE_TEMPLATE.GENERIC]: Page,
    [PAGE_TEMPLATE.DEFAULT]: Page,
    [PAGE_TEMPLATE.JOBS]: JobsPage,
    [PAGE_TEMPLATE.ONEPAGE]: Onepage,
    [PAGE_TEMPLATE.LANDING]: Landing,
    [PAGE_TEMPLATE.LANDING_THANK_YOU]: LandingThankYou,
  },
};

const generateHrefLangs = template => translatedSlugs =>
  [TEMPLATE.INSPIRING_BLOG_CATEGORY, TEMPLATE.INSPIRING_BLOG_TAG].includes(template)
    ? generateHrefLangsWithTemplate(template, translatedSlugs)
    : generateHrefLangsDefault(translatedSlugs);

const generateHrefLangsWithTemplate = (template, translatedSlugs) => {
  const updatedHrefLangs = {};
  Object.keys(translatedSlugs).forEach(key => {
    updatedHrefLangs[key] = translatedSlugs[key];

    if (key !== 'current') {
      const route = getListRoute(template, 0, key);
      updatedHrefLangs[key] = route ? getListRoute(template, 0, key).replace(':slug', translatedSlugs[key]) : '';
    }
  });
  return generateHrefLangsDefault(updatedHrefLangs);
};

const generateHrefLangsDefault = translatedSlugs => {
  const hrefBase = defaultUrl();
  const hrefLangs = [];
  i18n.locales.forEach(locale => {
    const slug = get(translatedSlugs, locale, '');
    const href = `${hrefBase}${i18n.prependLocale(locale, slug)}`;
    if (
      translatedSlugs.current.replace(`${locale}/`, '') === '/' ||
      i18n.prependLocale(locale, slug).replace(`${locale}/`, '') !== '/'
    ) {
      if (locale === i18n.defaultLocale) {
        hrefLangs.push({href: href, hrefLang: 'x-default', rel: 'alternate'});
      }

      hrefLangs.push({href: href, hrefLang: locale, rel: 'alternate'});
    }
  });

  return hrefLangs;
};

const BackendPageContent = ({common, isIE, page, translatedSlugs}) => {
  useEffect(() => {
    if (!isSSR()) {
      setTimeout(objectFitImages(), 1000);
    }
  }, []);
  const Template =
    (componentForTemplateKey[page.resource.template] &&
      componentForTemplateKey[page.resource.template][page.resource.content.template]) ||
    componentForTemplateKey[page.resource.template];
  const {content, pagination, template} = page.resource;
  const seo = page.resource.seo || content.yoast || {};
  const seoOG = extractSeoOpengraph(seo);
  const seoTW = extractSeoTwitter(seo);
  const canonical = page.resource.seo.canonical;

  const image = page.resource.image;
  const title = page.resource.title;
  const type = page.resource.type;
  const fetching = page.fetching;
  const filters = page.resource.filters || [];
  const categories = page.resource.categories || [];
  return (
    <>
      <ScrollToTop />
      <Helmet
        canonical={seo.canonical}
        description={seo.metadesc || seo.description}
        fullTitle={seo.title}
        image={seoOG.image}
        links={generateHrefLangs(page.resource.template)(translatedSlugs)}
        opengraph={seoOG}
        twitter={seoTW}
      />
      <Template
        canonical={canonical}
        categories={categories}
        common={common}
        content={content}
        fetching={fetching}
        filters={filters}
        image={image}
        isIE={isIE}
        pagination={pagination}
        template={template}
        title={title}
        type={type}
      />
      <BreadcrumbsMicrodata breadcrumbs={page.resource.breadcrumbs} />
    </>
  );
};

const BackendPage = ({common, isIE, page, translatedSlugs}) => {
  const loading = !page || !page.resource; /* || !common*/
  if (loading) {
    return <Loader />;
  }

  const backendPageContent = (
    <BackendPageContent common={common} isIE={isIE} page={page} translatedSlugs={translatedSlugs} />
  );

  pushDataLayer(pageLoadSuccess(getLocale(), page.resource.dataLayerType, page.resource.content.inventory));

  if (isIE) {
    return backendPageContent;
  }

  return isSSR() ? (
    <div style={{opacity: 0}}>{backendPageContent}</div>
  ) : (
    <Transition
      enter={{opacity: 1, transform: 'translate3d(0, 0px, 0)'}}
      from={{opacity: 0.1, transform: 'translate3d(0, 100px, 0)'}}
    >
      {() => style => {
        const filteredStyle = {
          opacity: style.opacity,
          ...(style.transform !== 'translate3d(0, 0px, 0)' && {transform: style.transform}),
        };

        return <animated.div style={filteredStyle}>{backendPageContent}</animated.div>;
      }}
    </Transition>
  );
};

const mapStateToProps = state => ({
  page: get(state, 'api.backendPage'),
  common: get(state, 'api.backendContext.resource.common', null),
  isIE: state.ui.isIE,
  translatedSlugs:
    state.api.backendPage && state.api.backendPage.resource
      ? state.api.backendPage.resource.translatedSlugs
      : i18n.defaultTranslatedRoutes,
});

export default compose(connect(mapStateToProps), React.memo)(BackendPage);
