import React from 'react';
import classNames from 'classnames';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {compose} from 'redux';
import {Redirect} from 'react-router-dom';
import {withI18nRouting} from '@foes/react-i18n-routing';

import i18n from './../../../i18n';

import Error from './../../templates/Error/Error';
import Header from './../../compositions/Header/Header';
import Prefooter from './../Prefooter/Prefooter';
import Footer from './../Footer/Footer';

import LandingHeader from './../LandingHeader/LandingHeader';
import LandingFooter from './../LandingFooter/LandingFooter';

import './Body.scss';
import Loader from '../../atoms/Loader/Loader';

const selectTemplate = state =>
  state.api.backendTemplate && state.api.backendTemplate.resource && state.api.backendTemplate.resource.template;
const selectHeader = state =>
  state.api.backendTemplate && state.api.backendTemplate.resource && state.api.backendTemplate.resource.header;
const selectHeaderMode = state =>
  state.api.backendTemplate && state.api.backendTemplate.resource && state.api.backendTemplate.resource.header_mode;
// const selectFooter = state =>
//   state.api.backendTemplate && state.api.backendTemplate.resource && state.api.backendTemplate.resource.header;
const selectTemplateFetching = state => !state.api.backendTemplate || state.api.backendTemplate.fetching;

const Body = ({error, route, i18nRouting}) => {
  const template = useSelector(state => selectTemplate(state));

  const header = useSelector(state => selectHeader(state));
  const headerMode = useSelector(state => selectHeaderMode(state));
  // const footer = useSelector(state => selectFooter(state));
  const templateFetching = useSelector(state => selectTemplateFetching(state));

  if (templateFetching) {
    return <Loader></Loader>;
  }

  if (error && error.redirect) {
    return <Redirect to={i18n.prependLocale(i18nRouting.locale, error.redirect.to)} />;
  }

  if (template === 'landing') {
    return (
      <div className={classNames('body')}>
        {header === 'landing' ? <LandingHeader mode={headerMode} /> : <Header />}
        {error ? <Error error={error} /> : renderRoutes(route.routes)}
        {header === 'landing' ? <LandingFooter /> : <Footer />}
      </div>
    );
  }

  if (template === 'landing_thank_you') {
    return (
      <div className={classNames('body')}>
        {header === 'landing' ? <LandingHeader mode={headerMode} /> : <Header />}
        {error ? <Error error={error} /> : renderRoutes(route.routes)}
        {header === 'landing' ? <LandingFooter /> : <Footer />}
      </div>
    );
  }

  return (
    <div className={classNames('body', 'body--header-padding')}>
      <Header />
      {error ? <Error error={error} /> : renderRoutes(route.routes)}
      <Prefooter />
      <Footer />
    </div>
  );
};

Body.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        exact: PropTypes.bool,
        strict: PropTypes.bool,
        sensitive: PropTypes.bool,
        component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        render: PropTypes.func,
        loadData: PropTypes.func,
      }),
    ).isRequired,
  }).isRequired,
  status: PropTypes.number,
};

const mapStateToProps = state => ({
  error: state.error.backendPage,
});

export default compose(withI18nRouting, connect(mapStateToProps), React.memo)(Body);
