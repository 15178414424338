import {getLocale} from '@foes/react-i18n-routing/dist/common/locale';

export const API_FETCHING = '[API] Resource fetching';
export const API_RECEIVED = '[API] Resource received';
export const API_FAILED = '[API] Resource request failed';
export const API_CANCELED = '[API] Resource request canceled';

const initialState = {};

const shouldComponentFullyReload = action =>
  !(action.currentCustomRouteAndPath && action.currentCustomRouteAndPath.shouldPartiallyRender);

const shouldDeletePreviousResource = (action, state) =>
  !state || !state[action.resourceType]
    ? true
    : state[action.resourceType].resource &&
      action.currentCustomRouteAndPath.path !== state[action.resourceType].resource.translatedSlugs[getLocale()]
    ? true
    : false;

export default (state = initialState, action) => {
  switch (action.type) {
    case API_FETCHING: {
      const checkFullReload = shouldComponentFullyReload(action);
      return {
        ...state,
        [action.resourceType]: {
          state: action.type,
          resource: checkFullReload
            ? null
            : shouldDeletePreviousResource(action, state)
            ? null
            : state[action.resourceType].resource,
          fetching: true,
        },
      };
    }
    case API_RECEIVED: {
      return {
        ...state,
        [action.resourceType]: {state: action.type, resource: action.resource, fetching: false},
      };
    }
    case API_FAILED: {
      return {
        ...state,
        [action.resourceType]: {state: action.type, resource: null, fetching: false},
      };
    }
    case API_CANCELED:
    default: {
      return state;
    }
  }
};
