import React from 'react';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

import CmsLink from './../../CmsLink/CmsLink';

import './CmsCtaText.scss';

const CmsCtaText = ({content}) => (
  <div className="cms-cta-text">
    <CmsLink slug={content.ctaUrl}>
      <Typography className="cms-cta-text__link" variant={VARIANT.BODY_XSMALL}>
        {content.title}
      </Typography>
    </CmsLink>
    {content.labels.map((label, index) => (
      <Typography key={index} variant={VARIANT.BODY_XSMALL_LOWERCASE}>
        {label}
      </Typography>
    ))}
  </div>
);

export default React.memo(CmsCtaText);
