import {cancelableGet} from './Client';
import {CMS_HOMEPAGE_SLUG} from './../../config/cmsSettings';

const normalizeSlug = slug => (slug === '/' ? CMS_HOMEPAGE_SLUG : slug);

export default locale => ({
  byPath: ({page = 1, perPage = 12, slug = '', ...restParams}) =>
    cancelableGet(`/api/page?path=${normalizeSlug(slug)}&page=${page}&per_page=${perPage}&lang=${locale}`, {
      params: restParams,
      headers: {
        'Accept-Language': locale,
      },
    }),
});
