import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import withSize from './../../../_packages/resize/ui/withSize';
import BREAKPOINTS from './../../_config/breakpoints';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import TableCell from './../../atoms/TableCell/TableCell';

import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';

import './DataTable.scss';

const DataTable = ({intl, keys, patents, title, windowWidth}) => (
  <Row className="data-table">
    <Column className="data-table__column">
      <Typography className="data-table__title" tag="h3" variant={VARIANT.HEADING}>
        {title}
      </Typography>
      {windowWidth > BREAKPOINTS.XL ? (
        <table className="data-table__table">
          <thead>
            <tr>
              {keys.map((key, index) => (
                <th className="data-table__table-th" key={index}>
                  <Typography className="data-table__table-header" tag="p" variant={VARIANT.BODY_XSMALL_REGULAR}>
                    {intl.formatMessage({id: key})}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {patents.map((patent, index) => (
              <tr className="data-table__table-content" key={index}>
                <TableCell columns={keys.length} text={patent.title} />
                <TableCell columns={keys.length} text={patent.publicationNumber} />
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <React.Fragment>
          {patents.map((patent, index) => (
            <div className="data-table__table-content" key={index}>
              <TableCell tag="span" text={patent.title} />
              <TableCell tag="span" text={patent.publicationNumber} />
            </div>
          ))}
        </React.Fragment>
      )}
    </Column>
  </Row>
);

DataTable.propTypes = {
  tableData: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.objectOf(PropTypes.string),
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }),
};

export default compose(withSize, injectIntl, React.memo)(DataTable);
