import React from 'react';

import Content from './../../../atoms/Content/Content';
import {VARIANT} from './../../../atoms/Typography/Typography';
import Picture from './../../../atoms/Picture/Picture';

import CmsLink from './../../../components/CmsLink/CmsLink';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';

import './InspiringStoryHeroQuote.scss';

const InspiringStoryHeroQuote = ({quote}) => (
  <div className="inspiring-story-hero-quote">
    <Row className="inspiring-story-hero-quote__row">
      <Column className="inspiring-story-hero-quote__text">
        <Content raw variant={VARIANT.EXCERPT}>
          {quote.quote}
        </Content>
        <Content className="inspiring-story-hero-quote__author" variant={VARIANT.CONTACT}>
          {quote.name}
        </Content>
        <Content className="inspiring-story-hero-quote__position" variant={VARIANT.CONTACT}>
          {quote.position}
        </Content>
      </Column>
      <Column className="inspiring-story-hero-quote__logo">
        <CmsLink slug={quote.partner[0].link.linkUrl}>
          <Picture
            alt={quote.partner[0].bWLogo.alt}
            className="inspiring-story-hero-quote__logo-picture"
            src={quote.partner[0].bWLogo.src.small}
          />
        </CmsLink>
      </Column>
    </Row>
  </div>
);

export default React.memo(InspiringStoryHeroQuote);
