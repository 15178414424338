import queryString from 'query-string';

import i18n from './../../i18n';

import {TECHNOLOGIES, TECHNOLOGY} from '../../routing/technologies/routes';
import {INSPIRING_STORIES, INSPIRING_STORY} from '../../routing/inspiringStories/routes';
import {INFRASTRUCTURES, INFRASTRUCTURE} from '../../routing/infrastructures/routes';
import {LABORATORY_SERVICES, LABORATORY_SERVICE} from '../../routing/laboratoryServices/routes';
import {NEWS_LIST, NEWS} from '../../routing/news/routes';
import {PAGE} from '../../routing/page/routes';
import {PRESS_RELEASES, PRESS_RELEASE} from '../../routing/pressReleases/routes';
import {EVENTS, EVENTS_PAST} from '../../routing/events/routes';
import {SEARCH} from '../../routing/search/routes';
import {AMBIT} from '../../routing/ambits/routes';
import {COMPANIES, COMPANY} from '../../routing/companies/routes';
import {ACTIVE, ACTIVES} from '../../routing/actives/routes';
import {PUBLICATIONS} from '../../routing/publications/routes';
import {PROJECT, PROJECTS} from '../../routing/projects/routes';
import {
  INSPIRING_BLOG,
  INSPIRING_BLOG_CATEGORY,
  INSPIRING_BLOG_POST,
  INSPIRING_BLOG_TAG,
} from '../../routing/inspiringBlog/routes';

export const getQueryStringByPage = (page, initialParams = {}) => {
  const {page: initialParamsPage, ...rest} = initialParams; // eslint-disable-line no-unused-vars
  return `${
    page > 1 || Object.keys(rest).length > 0 ? `?${queryString.stringify({...rest, ...(page > 1 ? {page} : {})})}` : ''
  }`;
};

export const getRoute = route => ({page, locale = i18n.defaultLocale, initialParams = {}, routeParams = {}}) =>
  `${i18n.formatIntlRoute(route, routeParams, locale)}${getQueryStringByPage(page, initialParams)}`;

export const getSearchRoute = (locale, initialParams) => getRoute(SEARCH)({locale, initialParams});

export const getPostType = type => {
  switch (type) {
    case 'technologies': {
      return TECHNOLOGIES;
    }
    case 'technology': {
      return TECHNOLOGY;
    }
    case 'inspiringStories': {
      return INSPIRING_STORIES;
    }
    case 'inspiring_story': {
      return INSPIRING_STORY;
    }
    case 'post': {
      return INSPIRING_BLOG_POST;
    }
    case 'inspiringBlog': {
      return INSPIRING_BLOG;
    }
    case 'inspiringBlogCategory': {
      return INSPIRING_BLOG_CATEGORY;
    }
    case 'inspiringBlogTag': {
      return INSPIRING_BLOG_TAG;
    }
    case 'infrastructures': {
      return INFRASTRUCTURES;
    }
    case 'infrastructure': {
      return INFRASTRUCTURE;
    }
    case 'labServices': {
      return LABORATORY_SERVICES;
    }
    case 'laboratory': {
      return LABORATORY_SERVICE;
    }
    case 'newsList': {
      return NEWS_LIST;
    }
    case 'news': {
      return NEWS;
    }
    case 'pressReleases': {
      return PRESS_RELEASES;
    }
    case 'press_release': {
      return PRESS_RELEASE;
    }
    case 'ambit': {
      return AMBIT;
    }
    case 'active': {
      return ACTIVE;
    }
    case 'actives': {
      return ACTIVES;
    }
    case 'publications': {
      return PUBLICATIONS;
    }
    case 'company': {
      return COMPANY;
    }
    case 'companies': {
      return COMPANIES;
    }
    case 'project': {
      return PROJECT;
    }
    case 'projects': {
      return PROJECTS;
    }
    default: {
      return PAGE;
    }
  }
};

export const getTemplate = template => {
  switch (template) {
    case 'agendaPast': {
      return EVENTS_PAST;
    }
    case 'agenda': {
      return EVENTS;
    }
  }
};

export const getListRoute = (type, page, locale, initialParams) =>
  getRoute(getPostType(type))({page, locale, initialParams});

export const getEventsRoutes = (page, locale, initialParams) => getRoute(EVENTS)({page, locale, initialParams});

export const getTemplateRoute = (template, page, locale, initialParams) =>
  getRoute(getTemplate(template))({page, locale, initialParams});
