import queryString from 'query-string';
import {getLocale} from '@foes/react-i18n-routing/dist/common/locale';

import routes, {HOME, getRestRoutes} from './routes';

import Body from './../ui/layouts/Body/Body';
import BackendPage from './../ui/pages/BackendPage/BackendPage';
import {
  getBackendPage,
  getBackendContext,
  getBackendTemplate,
  getBackendAvailableLanguages,
} from '../store/Api/ApiActions';

import i18n from './../i18n';

const getMenuKey = locale => locale;

export default [
  {
    paths: routes[HOME],
    component: Body,
    exact: false,
    loadData: data => async dispatch => {
      const locale = getLocale();
      await dispatch(
        getBackendTemplate(locale)({
          params: data.url,
        }),
      );
      await dispatch(
        getBackendAvailableLanguages(locale)({
          params: data.url,
        }),
      );
      await dispatch(getBackendContext(locale)({key: getMenuKey(locale)}));
    },
    routes: [
      {
        path: '*',
        component: BackendPage,
        loadData: (match, {pathname, search, currentCustomRouteAndPath}) => async dispatch => {
          const {locale, path} = i18n.localeAndPathFromUrl(match.url);
          const stringifiedSearch = queryString.stringify(search);
          const key = `${pathname}${stringifiedSearch ? `?${stringifiedSearch}` : ''}`;
          await dispatch(
            getBackendPage(locale)({
              params: {slug: path, ...search},
              key: key,
              keyFilter: reqKey => key !== reqKey && reqKey !== getMenuKey(locale),
              currentCustomRouteAndPath: currentCustomRouteAndPath,
            }),
          );
        },
      },
      ...getRestRoutes(routes),
    ],
  },
];
