export const INFRASTRUCTURES = 'infrastructures';
export const INFRASTRUCTURE = 'infrastructure';

export const infrastrcuturesRouteParams = {
  [INFRASTRUCTURES]: {
    shouldPartiallyRender: true,
  },
  [INFRASTRUCTURE]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [INFRASTRUCTURES]: {
    es: '/infraestructuras',
    en: '/infrastructure',
    eu: '/azpiegiturak',
    fr: '/infrastructures',
  },
  [INFRASTRUCTURE]: {
    es: '/infraestructuras/:slug',
    en: '/infrastructure/:slug',
    eu: '/azpiegiturak/:slug',
    fr: '/infrastructures/:slug',
  },
};
