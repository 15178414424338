import React, {useState} from 'react';
import classNames from 'classnames';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import {Transition, animated} from 'react-spring/renderprops.cjs.js';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import {PrimaryButton} from './../../../atoms/Button/Button';
import Picture, {RATIO} from './../../../atoms/Picture/Picture';
import AnimatedInlineButton from '../../../atoms/AnimatedInlineButton/AnimatedInlineButton';

import CmsLink from './../../../components/CmsLink/CmsLink';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';

import './ServicesModule.scss';

const ServicesModule = ({content, intl}) => {
  const [selected, setSelected] = useState(0);
  return (
    <Row className="services-module" tag="section">
      <Column className="services-module__header">
        <Typography className="services-module__header-category" tag="p" variant={VARIANT.HEADING_SMALL}>
          {content.subtitle}
        </Typography>
        <Typography tag="h2" variant={VARIANT.H1}>
          {content.title}
        </Typography>
      </Column>
      <Column className="services-module__buttons">
        {content.slides.map((slide, index) => (
          <PrimaryButton
            className={classNames('service-module__button', {'services-module__buttons--selected': selected === index})}
            key={index}
            onClick={() => setSelected(index)}
          >
            <Typography variant={VARIANT.BODY_SMALL}>{slide.title}</Typography>
          </PrimaryButton>
        ))}
      </Column>

      <Transition
        enter={{opacity: 1, transition: 'opacity .3s ease-in-out'}}
        from={{opacity: 0}}
        items={selected}
        leave={{display: 'none'}}
      >
        {index => style => (
          <animated.div className="services-module__items" style={style}>
            <Column className="services-module__content">
              <Row className="services-module__content-wrapper" scrollAnimation={false}>
                <Column className="services-module__content-text">
                  <Typography className="services-module__content-text-title" tag="h3" variant={VARIANT.HEADING_LOWER}>
                    {content.slides[index].title}
                  </Typography>
                  <Typography className="services-module__content-text-text" tag="p" variant={VARIANT.BODY}>
                    {content.slides[index].number}
                  </Typography>
                  <CmsLink slug={content.slides[index].url}>
                    <AnimatedInlineButton text={intl.formatMessage({id: 'button.know.more'})} />
                  </CmsLink>
                </Column>
                <Picture
                  alt={content.slides[index].image.alt}
                  className="services-module__content-picture"
                  cover
                  ratio={RATIO.R_16_9}
                  src={content.slides[index].image.src.large}
                  srcSet={[{src: content.slides[index].image.src.small, maxWidth: 639}]}
                />
              </Row>
            </Column>
          </animated.div>
        )}
      </Transition>
    </Row>
  );
};

export default compose(injectIntl, React.memo)(ServicesModule);
