import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RelatedAgenda from './../../compositions/RelatedAgenda/RelatedAgenda';
import RelatedNews from './../../compositions/RelatedNews/RelatedNews';

import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './NewsAgendaModule.scss';

const NewsAgendaModule = ({agendaItems, newsCards, newsTitle, agendaTitle}) => {
  const slidesPerBreakpoint =
    agendaItems && agendaItems.length > 0 ? {S: 1, L: 2, XXXXL: 3} : {S: 1, L: 2, XL: 3, XXXL: 4};
  return (
    <FullRow className="news-agenda-module">
      {agendaItems && agendaItems.length > 0 && (
        <Column className="news-agenda-module__related-events">
          <RelatedAgenda agenda={agendaItems} title={agendaTitle} />
        </Column>
      )}
      {newsCards && newsCards.length > 0 && (
        <Column
          className={classNames('news-agenda-module__related-news', {
            'news-agenda-module__related-news--padding': agendaItems && agendaItems.length > 0,
          })}
        >
          <RelatedNews cards={newsCards} slidesPerBreakpoint={slidesPerBreakpoint} title={newsTitle} />
        </Column>
      )}
    </FullRow>
  );
};

NewsAgendaModule.protpTypes = {
  agendaItems: PropTypes.array,
  agendaTitle: PropTypes.string,
  newsCards: PropTypes.array,
};

export default React.memo(NewsAgendaModule);
