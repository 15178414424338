import React, {useState} from 'react';
import classNames from 'classnames';
import {FormattedLink} from '@foes/react-i18n-routing';
import {Spring} from 'react-spring/renderprops.cjs.js';

import {AMBIT} from './../../../../routing/ambits/routes';

import {ICON} from './../../../atoms/Iconography/Iconography';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Picture from './../../../atoms/Picture/Picture';
import Content from './../../../atoms/Content/Content';
import AnimatedInlineButton from './../../../atoms/AnimatedInlineButton/AnimatedInlineButton';

import FeaturedLink from './../../../components/FeaturedLink/FeaturedLink';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';

import './TechnologiesModule.scss';

const TechnologiesModule = ({content}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  return (
    <Row className="technologies-module" tag="section">
      <Column className="technologies-module__header">
        <Typography className="technologies-module__header-category" tag="p" variant={VARIANT.HEADING_SMALL}>
          {content.subtitle}
        </Typography>
        <Typography tag="h2" variant={VARIANT.H1}>
          {content.title}
        </Typography>
      </Column>
      <Column className="technologies-module__items">
        {content.ambits.map((ambit, index) => (
          /* eslint-disable jsx-a11y/click-events-have-key-events */
          /* eslint-disable jsx-a11y/no-static-element-interactions */
          <div
            className={classNames(
              'technologies-module__items-wrapper',
              `technologies-module__items-wrapper--${ambit.gradient.type}`,
              {
                'technologies-module__items-wrapper--active': selectedIndex === index,
              },
            )}
            key={index}
            onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
          >
            <FeaturedLink icon={ICON.PLUS} key={index} scrollAnimation={false} title={ambit.title} uppercaseTitle />
            <div
              className={classNames(
                'technologies-module__items-technologies',
                [`technologies-module__items-technologies--${ambit.gradient.type}`],
                {
                  'technologies-module__items-technologies--active': selectedIndex === index,
                },
              )}
            >
              <div className="technologies-moudle__items-ambit">
                <Content className="technologies-module__items-ambit-content" raw>
                  {ambit.text}
                </Content>
                <FormattedLink name={AMBIT} params={{slug: ambit.slug}}>
                  <AnimatedInlineButton inverted text={ambit.buttonText} />
                </FormattedLink>
              </div>
              {selectedIndex === index && (
                <Spring
                  className="testing"
                  delay={1300}
                  from={{transform: 'scale(0)'}}
                  to={{transform: 'scale(1)', position: 'relative'}}
                >
                  {props => (
                    <div style={props}>
                      <Picture
                        alt={ambit.image.alt}
                        className="technologies-module__picture"
                        src={ambit.image.src.large}
                        srcSet={[
                          {src: ambit.image.src.small, maxWidth: 639},
                          {src: ambit.image.src.medium, maxWidth: 1279},
                        ]}
                      />
                    </div>
                  )}
                </Spring>
              )}
            </div>
          </div>
          /* eslint-enable jsx-a11y/click-events-have-key-events */
          /* eslint-enable jsx-a11y/no-static-element-interactions */
        ))}
      </Column>
    </Row>
  );
};

export default React.memo(TechnologiesModule);
