import React from 'react';
import PropTypes from 'prop-types';
import {Trail} from 'react-spring/renderprops.cjs.js';

import HeroCategoryTitle from '../../components/HeroCategoryTitle/HeroCategoryTitle';

import HeroSlider from '../../compositions/HeroSlider/HeroSlider';

import FullRow from '../../layouts/FullRow/FullRow';
import Breadcrumbs from '../../layouts/Breadcrumbs/Breadcrumbs';

import './InspiringBlogPostsHero.scss';

const InspiringBlogPostsHero = ({title, type, items}) => (
  <FullRow className="inspiring-blog-posts-hero" scrollAnimation={false}>
    <div className="inspiring-blog-posts-hero__column inspiring-blog-posts-hero__column-content">
      <Trail
        config={{tension: 210, friction: 35, delay: 500}}
        from={{opacity: 0, transform: 'translateY(100px)'}}
        items={[
          <Breadcrumbs key="breadcrumbs" />,
          <HeroCategoryTitle key="content" title={title} titleIcon="TITLE_ICON" type={type} />,
        ]}
        keys={item => item.key}
        to={{opacity: 1, transform: 'translateY(0)'}}
      >
        {item => props => <div style={props}>{item}</div>}
      </Trail>
    </div>
    <div className="inspiring-blog-posts-hero__column inspiring-blog-posts-hero__column-content-slider">
      <Trail
        config={{tension: 210, friction: 35, delay: 500}}
        from={{opacity: 0, transform: 'translateY(100px)'}}
        items={[<HeroSlider items={items} />]}
        keys={item => item.key}
        to={{opacity: 1, transform: 'translateY(0)'}}
      >
        {item => props => <div style={props}>{item}</div>}
      </Trail>
    </div>
  </FullRow>
);

InspiringBlogPostsHero.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default React.memo(InspiringBlogPostsHero);
