import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';
import {valuePerBreakpointForConfig} from './../../_config/breakpoints';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Slider from './../../compositions/Slider/Slider';

import FullRow from './../../layouts/FullRow/FullRow';

import './RelatedContentCards.scss';

const RelatedContentCards = ({
  children,
  className,
  invertedButtons,
  slidesPerBreakpoint,
  scrollAnimation,
  subtitle,
  title,
  titleTag,
  titleVariant,
  windowWidth,
  withBackground,
  withHorizontalCards,
}) => (
  <FullRow
    className={classNames(
      'related-content-cards',
      {
        'related-content-cards--background': withBackground,
        'related-content-cards--with-button':
          children.length > valuePerBreakpointForConfig(slidesPerBreakpoint)(windowWidth),
      },
      className,
    )}
    scrollAnimation={scrollAnimation}
  >
    {subtitle && (
      <Typography className="related-content-cards__subitle" tag="p" variant={VARIANT.HEADING_SMALL}>
        {subtitle}
      </Typography>
    )}
    {title && (
      <Typography className="related-content-cards__title" tag={titleTag} variant={titleVariant}>
        {title}
      </Typography>
    )}
    <Slider
      className="related-content-cards__wrapper"
      horizontalCards={withHorizontalCards}
      invertedButtons={invertedButtons}
      slidesPerBreakpoint={slidesPerBreakpoint}
    >
      {children}
    </Slider>
  </FullRow>
);

RelatedContentCards.propTypes = {
  className: PropTypes.string,
  invertedButtons: PropTypes.bool,
  scrollAnimation: PropTypes.bool,
  slidesPerBreakpoint: PropTypes.object,
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p']),
  titleVariant: PropTypes.any,
  withBackground: PropTypes.bool,
  withHorizontalCards: PropTypes.bool,
};

RelatedContentCards.defaultProps = {
  invertedButtons: false,
  scrollAnimation: true,
  slidesPerBreakpoint: {S: 1, L: 2, XXXXL: 3},
  titleTag: 'h3',
  titleVariant: VARIANT.HEADING,
  withBackground: false,
  withHorizontalCards: false,
};

export default compose(withSize, React.memo)(RelatedContentCards);
