import React, {useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import RoutingSocialSharePrint from './../../_bridges/RoutingSocialSharePrint/RoutingSocialSharePrint';

import Row from './../Row/Row';
import Column from './../Column/Column';

import './MainLayout.scss';

const MainLayout = ({
  canonical,
  className,
  delay,
  mainContent,
  asideContent,
  showSocial,
  print,
  socialText,
  topContent,
  scrollAnimation,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <VisibilitySensor
      active={!visible || !scrollAnimation}
      onChange={isVisible => setVisible(isVisible)}
      partialVisibility
    >
      <>
        {topContent && (
          <Row
            className="main-layout-top-row"
            delay={delay}
            scrollAnimation={scrollAnimation}
            shouldDelayAnimation={visible}
          >
            <Column className="main-layout-top-column">{topContent}</Column>
          </Row>
        )}
        <Row
          className={classNames('main-layout', className)}
          delay={delay}
          scrollAnimation={scrollAnimation}
          shouldDelayAnimation={visible}
          {...rest}
        >
          <Column className="main-layout__main">
            {showSocial && <RoutingSocialSharePrint print={print} text={socialText} url={canonical} />}
            <div className="main-layout__main-content">{mainContent}</div>
          </Column>
          {asideContent && <Column className="main-layout__aside">{asideContent}</Column>}
        </Row>
      </>
    </VisibilitySensor>
  );
};

MainLayout.propTypes = {
  aside: PropTypes.node,
  asideContent: PropTypes.node,
  delay: PropTypes.number,
  mainContent: PropTypes.node.isRequired,
  print: PropTypes.bool,
  showSocial: PropTypes.bool,
  topContent: PropTypes.node,
};

MainLayout.defaultProps = {
  delay: 2200,
  showSocial: true,
  print: true,
  scrollAnimation: true,
};

export default React.memo(MainLayout);
