import React from 'react';
import classNames from 'classnames';

import ScrollAnimation from './../../_behaviours/ScrollAnimation';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';
import Picture from './../../atoms/Picture/Picture';

import CmsCookiesList from './../../components/CmsComponents/CmsCookiesList/CmsCookiesList';
import CmsAccordionContent from './../../components/CmsComponents/CmsAccordionContent/CmsAccordionContent';
import CmsDivider from './../../components/CmsComponents/CmsDivider/CmsDivider';
import CmsVideo from './../../components/CmsComponents/CmsVideo/CmsVideo';

import ContentGrid from './../../compositions/ContentGrid/ContentGrid';
import ContentGridList from './../../compositions/ContentGridList/ContentGridList';

import './CmsContent.scss';

const ContentComposition = ({type, fields}) => {
  if (type === 'TitleTextTwoColumnsComponent') {
    return <ContentGrid items={fields.items} />;
  }
  if (type === 'TitleListComponent') {
    return <ContentGridList items={fields.items} />;
  }
  if (type === 'CookieListBlock') {
    return <CmsCookiesList />;
  }
  if (type === 'AccordionComponent') {
    return <CmsAccordionContent content={fields} />;
  }
  if (type === 'DividerComponent') {
    return <CmsDivider />;
  }
  if (type === 'VideoPageComponent') {
    return <CmsVideo scrollAnimation={false} video={fields.videoPage} />;
  }
  if (type === 'ImageBlock') {
    return (
      <Picture
        alt={fields.image.alt || fields.image.title}
        caption={fields.image.caption}
        className="image-block__picture-image"
        cover
        showCaption={fields.image.showCaption}
        src={fields.image.src.medium}
        srcSet={[
          {
            src: fields.image.src.small,
            maxWidth: 480,
          },
        ]}
      />
    );
  }
  return (
    <Content raw variant={fields.small ? VARIANT.BODY_SMALLER : VARIANT.BODY}>
      {fields.text}
    </Content>
  );
};

const CmsContent = ({content, className, scrollAnimation}) => (
  <ScrollAnimation scrollAnimation={scrollAnimation}>
    <div className={classNames('cms-content', className)}>
      {content.map((block, index) => {
        const type = block.type;
        const fields = block.fields;
        return (
          <div className="cms-content__item" key={index}>
            {fields.title && (
              <Typography
                className="cms-content__title"
                tag="h2"
                variant={fields.lowercase ? VARIANT.EXCERPT : VARIANT.HEADING}
              >
                {fields.title}
              </Typography>
            )}
            <ContentComposition fields={fields} type={type} />
          </div>
        );
      })}
    </div>
  </ScrollAnimation>
);

CmsContent.defaultProps = {
  scrollAnimation: true,
};

export default React.memo(CmsContent);
