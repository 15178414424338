import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {FormattedLink} from '@foes/react-i18n-routing';

import {INSPIRING_BLOG} from '../../../../routing/inspiringBlog/routes';

// eslint-disable-next-line max-len
import RoutingInspiringBlogHeroCard from './../../../_bridges/RoutingInspiringBlogHeroCard/RoutingInspiringBlogHeroCard';

import AnimatedInlineButton from './../../../atoms/AnimatedInlineButton/AnimatedInlineButton';
import {VARIANT} from '../../../atoms/Typography/Typography';
import FullRow from './../../../layouts/FullRow/FullRow';
import RelatedContentCards from './../../../compositions/RelatedContentCards/RelatedContentCards';
import Column from './../../../layouts/Column/Column';

import './BlogModule.scss';

const BlogModule = ({content, title, subtitle, intl}) => (
  <FullRow className="blog-module">
    <RelatedContentCards
      scrollAnimation={false}
      slidesPerBreakpoint={{S: 1.2, L: 2.2}}
      subtitle={subtitle || content.subtitle}
      title={title || content.title}
      titleTag="h1"
      titleVariant={VARIANT.H1}
    >
      {content.items
        .filter((item, index) => index < 6)
        .map((item, index) => (
          <RoutingInspiringBlogHeroCard item={item} key={index} />
        ))}
    </RelatedContentCards>
    <Column>
      <div className="blog-module__link-button">
        <FormattedLink className="blog-module__link" name={INSPIRING_BLOG}>
          <AnimatedInlineButton text={intl.formatMessage({id: 'show.all'})} />
        </FormattedLink>
      </div>
    </Column>
  </FullRow>
);

BlogModule.propTypes = {
  cards: PropTypes.array,
  linkText: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default compose(injectIntl, React.memo)(BlogModule);
