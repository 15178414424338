import React from 'react';
import PropTypes from 'prop-types';

import Picture from './../../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import InlineButton from '../../../atoms/InlineButton/InlineButton';

import Grid from './../../../layouts/Grid/Grid';
// import FullRow from './../../../layouts/FullRow/FullRow';

import './CmsRelatedCertifications.scss';

const CmsRelatedCertifications = ({content, renderContainer}) => (
  <Grid
    className="cms-related-certifications"
    columnsPerBreakpoint={{S: 1, L: 4}}
    itemClassName="cms-related-certifications__item"
    title={content.title}
  >
    {content.items.map((file, index) => (
      <div className="cms-related-certifications__item-wrapper" key={index}>
        <Picture alt={file.image.alt} className="cms-related-certifications__item-image" src={file.image.src.medium} />
        <Typography className="cms-related-certifications__item-title" tag="h2" variant={VARIANT.BODY}>
          {file.title}
        </Typography>
        {renderContainer(
          <InlineButton className="cms-related-certifications__item-link" tag="div" variant={VARIANT.CONTACT}>
            <Typography tag="p" variant={VARIANT.BODY_SMALL}>{file.file.text}</Typography>
          </InlineButton>,
          index,
        )}
      </div>
    ))}
  </Grid>
);

CmsRelatedCertifications.propTypes = {
  renderContainer: PropTypes.func,
};

CmsRelatedCertifications.defaultProps = {
  renderContainer: children => children,
};

export default React.memo(CmsRelatedCertifications);
