/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {useLocation} from 'react-router';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import classNames from 'classnames';
import {getLocale} from '@foes/react-i18n-routing/dist/common/locale';
import DatePicker, {registerLocale} from 'react-datepicker';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import eu from 'date-fns/locale/eu';
import fr from 'date-fns/locale/fr';

import moment from 'moment';

import Iconography, {ICON} from '../../../atoms/Iconography/Iconography';
import FormCheckbox, {CHECKBOX_TYPE} from '../../../atoms/FormCheckbox/FormCheckbox';
import InlineButton from '../../../atoms/InlineButton/InlineButton';
import Typography, {VARIANT} from '../../../atoms/Typography/Typography';
import {PrimaryButton} from '../../../atoms/Button/Button';

import 'react-datepicker/dist/react-datepicker.css';
import './Filters.scss';

const FILTERS = {
  CATEGORIES: 'category',
  AMBITS: 'ambit',
  BEFORE: 'before',
  AFTER: 'after',
  AUTHORS: 'author',
  TAGS: 'tag',
};

const FILTERS_SPLIT_CHAR = ',';

registerLocale('es', es);
registerLocale('en', en);
registerLocale('eu', eu);
registerLocale('fr', fr);

const filtersToQueryParams = filters =>
  Object.keys(filters)
    .filter(key =>
      [FILTERS.AFTER, FILTERS.BEFORE].includes(key)
        ? filters[FILTERS.AFTER].length !== 0 && filters[FILTERS.BEFORE].length !== 0
        : filters[key].length !== 0,
    )
    .map(key => filters[key] && `${key}=${filters[key].join(FILTERS_SPLIT_CHAR)}`)
    .join('&');

const openFilterReducer = (state, action) => {
  switch (action.type) {
    case 'toggleCategories':
      return {...state, categories: !state.categories};
    case 'toggleAuthors':
      return {...state, authors: !state.authors};
    case 'toggleAmbits':
      return {...state, ambits: !state.ambits};
    case 'toggleDates':
      return {...state, dates: !state.dates};
    case 'toggleTags':
      return {...state, tags: !state.tags};
    case 'closeCategories':
      return {...state, categories: false};
    case 'closeAuthors':
      return {...state, authors: false};
    case 'closeAmbits':
      return {...state, ambits: false};
    case 'closeDates':
      return {...state, dates: false};
    case 'closeTags':
      return {...state, tags: false};
    case 'closeAll':
      return {
        categories: false,
        authors: false,
        ambits: false,
        dates: false,
        tags: false,
      };
    default:
      return {...state};
  }
};

const InspiringBlogPostFilters = ({categories, ambits, authors, tags, pushRoute, intl}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search !== '' ? location.search : '');
  const [openMobile, setOpenMobile] = useState(false);

  const [filters, setFilters] = useState({
    [FILTERS.CATEGORIES]:
      params.get(FILTERS.CATEGORIES) !== null
        ? params
            .get(FILTERS.CATEGORIES)
            .split(FILTERS_SPLIT_CHAR)
            .filter(_value => _value !== '')
        : [],
    [FILTERS.TAGS]:
      params.get(FILTERS.TAGS) !== null
        ? params
            .get(FILTERS.TAGS)
            .split(FILTERS_SPLIT_CHAR)
            .filter(_value => _value !== '')
        : [],
    [FILTERS.AUTHORS]:
      params.get(FILTERS.AUTHORS) !== null
        ? params
            .get(FILTERS.AUTHORS)
            .split(FILTERS_SPLIT_CHAR)
            .filter(_value => _value !== '')
        : [],
    [FILTERS.AMBITS]:
      params.get(FILTERS.AMBITS) !== null
        ? params
            .get(FILTERS.AMBITS)
            .split(FILTERS_SPLIT_CHAR)
            .filter(_value => _value !== '')
        : [],
    [FILTERS.BEFORE]:
      params.get(FILTERS.BEFORE) !== null && params.get(FILTERS.BEFORE) !== '' ? [params.get(FILTERS.BEFORE)] : [],
    [FILTERS.AFTER]:
      params.get(FILTERS.AFTER) !== null && params.get(FILTERS.AFTER) !== '' ? [params.get(FILTERS.AFTER)] : [],
  });

  const [startDate, setStartDate] = useState(
    params.get(FILTERS.AFTER) ? moment(params.get(FILTERS.AFTER), 'YYYY-MM-DD').toDate() : null,
  );
  const [endDate, setEndDate] = useState(
    params.get(FILTERS.BEFORE) ? moment(params.get(FILTERS.BEFORE), 'YYYY-MM-DD').toDate() : null,
  );

  const openFiltersState = {
    categories: filters[FILTERS.CATEGORIES].length > 0,
    tags: filters[FILTERS.TAGS].length > 0,
    authors: filters[FILTERS.AUTHORS].length > 0,
    ambits: filters[FILTERS.AMBITS].length > 0,
    dates:
      filters[FILTERS.AFTER].length > 0 &&
      filters[FILTERS.BEFORE].length > 0 &&
      filters[FILTERS.AFTER][0] !== '' &&
      filters[FILTERS.BEFORE][0] !== '',
  };

  const [openFilters, dispatchOpenFilters] = useReducer(openFilterReducer, openFiltersState);

  const dispachActionOpenFilters = type => dispatchOpenFilters({type});

  const dispachToggleFilter = type => () => {
    dispachActionOpenFilters(type);
  };

  const clearFilters = () => {
    setFilters({
      [FILTERS.CATEGORIES]: [],
      [FILTERS.TAGS]: [],
      [FILTERS.AUTHORS]: [],
      [FILTERS.AMBITS]: [],
      [FILTERS.BEFORE]: [],
      [FILTERS.AFTER]: [],
    });

    dispachActionOpenFilters('clearAll');
  };

  useEffect(() => {
    pushRoute({
      pathname: location.pathname,
      search: filtersToQueryParams(filters),
    });

    if (filters[FILTERS.CATEGORIES].length === 0) {
      dispachActionOpenFilters('closeCategories');
    }

    if (filters[FILTERS.TAGS].length === 0) {
      dispachActionOpenFilters('closeTags');
    }

    if (filters[FILTERS.AUTHORS].length === 0) {
      dispachActionOpenFilters('closeAuthors');
    }

    if (filters[FILTERS.AMBITS].length === 0) {
      dispachActionOpenFilters('closeAmbits');
    }

    if (filters[FILTERS.AFTER].length === 0 && filters[FILTERS.BEFORE].length === 0) {
      dispachActionOpenFilters('closeDates');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleDateChanged = value => {
    const [start, end] = value;
    setStartDate(start);
    setEndDate(end);

    if (value[0] !== null && value[1] !== null) {
      filters[FILTERS.AFTER] = [moment(start).format('YYYY-MM-DD')];
      filters[FILTERS.BEFORE] = [moment(end).format('YYYY-MM-DD')];
      setFilters({...filters});
    }

    if (value[0] === null && value[1] === null) {
      filters[FILTERS.AFTER] = [];
      filters[FILTERS.BEFORE] = [];
      setFilters({...filters});
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleFilter = useCallback((filter, value) => {
    filters[filter].indexOf(value) > -1
      ? filters[filter].splice(filters[filter].indexOf(value), 1).filter(_value => _value !== '')
      : filters[filter].push(value);

    setFilters({...filters});
  });

  return (
    <>
      <div className="filter--mobile">
        <div className={classNames('filter__section')}>
          <div aria-hidden="true" className={classNames('filter__title')} onClick={() => setOpenMobile(!openMobile)}>
            <Typography className={classNames('filter__title-text')} variant={VARIANT.BODY_SMALL}>
              <FormattedMessage id="inspiringBlog.filters.title_mobile" />
            </Typography>
            <Iconography className={classNames('filter__title-icon')} icon={ICON.FILTER} />
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className={classNames('filter', {open: openMobile})}>
        <Iconography
          className={classNames('filter__close-icon')}
          icon={ICON.CLOSE}
          onClick={() => setOpenMobile(false)}
        />

        <Typography className={classNames('filter__aside-title')} variant={VARIANT.HEADING_MEDIUM}>
          <FormattedMessage id="inspiringBlog.filters.title" />
        </Typography>
        <div className={classNames('filter__section', {'filter__section--selected': openFilters.categories})}>
          <div
            aria-hidden="true"
            className={classNames('filter__title')}
            onClick={dispachToggleFilter('toggleCategories')}
          >
            <Typography className={classNames('filter__title-text')} variant={VARIANT.BODY_SMALL}>
              <FormattedMessage id="inspiringBlog.filters.category" />
              {filters[FILTERS.CATEGORIES].length > 0 ? ` (${filters[FILTERS.CATEGORIES].length})` : ''}
            </Typography>
            <Iconography className={classNames('filter__title-icon')} icon={ICON.CHEVRON_UP} />
          </div>
          <div className="filter__items">
            {Array.from(categories).map((category, index) => (
              <FormCheckbox
                checked={filters[FILTERS.CATEGORIES].indexOf(`${category.id}`) > -1}
                className="filter__item"
                key={index}
                label={category.name}
                onChange={() => toggleFilter(FILTERS.CATEGORIES, `${category.id}`)}
                type={CHECKBOX_TYPE.TYPE_FILTER}
              />
            ))}
          </div>
        </div>

        {Array.from(tags).length > 0 && (
          <div className={classNames('filter__section', {'filter__section--selected': openFilters.tags})}>
            <div aria-hidden="true" className={classNames('filter__title')} onClick={dispachToggleFilter('toggleTags')}>
              <Typography className={classNames('filter__title-text')} variant={VARIANT.BODY_SMALL}>
                <FormattedMessage id="inspiringBlog.filters.tag" />
                {filters[FILTERS.TAGS].length > 0 ? ` (${filters[FILTERS.TAGS].length})` : ''}
              </Typography>
              <Iconography className={classNames('filter__title-icon')} icon={ICON.CHEVRON_UP} />
            </div>
            <div className="filter__items">
              {Array.from(tags).map((tag, index) => (
                <FormCheckbox
                  checked={filters[FILTERS.TAGS].indexOf(`${tag.id}`) > -1}
                  className="filter__item"
                  key={index}
                  label={tag.name}
                  onChange={() => toggleFilter(FILTERS.TAGS, `${tag.id}`)}
                  type={CHECKBOX_TYPE.TYPE_FILTER}
                />
              ))}
            </div>
          </div>
        )}

        <div className={classNames('filter__section', {'filter__section--selected': openFilters.ambits})}>
          <div aria-hidden="true" className={classNames('filter__title')} onClick={dispachToggleFilter('toggleAmbits')}>
            <Typography className={classNames('filter__title-text')} variant={VARIANT.BODY_SMALL}>
              <FormattedMessage id="inspiringBlog.filters.ambit" />
              {filters[FILTERS.AMBITS].length > 0 ? ` (${filters[FILTERS.AMBITS].length})` : ''}
            </Typography>
            <Iconography className={classNames('filter__title-icon')} icon={ICON.CHEVRON_UP} />
          </div>
          <div className="filter__items">
            {ambits.map((ambit, index) => (
              <FormCheckbox
                checked={filters[FILTERS.AMBITS].indexOf(`${ambit.id}`) > -1}
                className="filter__item"
                key={index}
                label={ambit.name}
                onChange={() => toggleFilter(FILTERS.AMBITS, `${ambit.id}`)}
                type={CHECKBOX_TYPE.TYPE_FILTER}
              />
            ))}
          </div>
        </div>

        <div className={classNames('filter__section', {'filter__section--selected': openFilters.dates})}>
          <div aria-hidden="true" className={classNames('filter__title')} onClick={dispachToggleFilter('toggleDates')}>
            <Typography className={classNames('filter__title-text')} variant={VARIANT.BODY_SMALL}>
              <FormattedMessage id="inspiringBlog.filters.date" />
            </Typography>
            <Iconography className={classNames('filter__title-icon')} icon={ICON.CHEVRON_UP} />
          </div>
          <div className="filter__items">
            <DatePicker
              className="filter__item-date"
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              isClearable={true}
              locale={getLocale()}
              onChange={handleDateChanged}
              placeholderText={intl.formatMessage({id: 'inspiringBlog.filters.date.placeholder'})}
              selected={startDate}
              selectsRange
              startDate={startDate}
            />
          </div>
        </div>

        <div className={classNames('filter__section', {'filter__section--selected': openFilters.authors})}>
          <div
            aria-hidden="true"
            className={classNames('filter__title')}
            onClick={dispachToggleFilter('toggleAuthors')}
          >
            <Typography className={classNames('filter__title-text')} variant={VARIANT.BODY_SMALL}>
              <FormattedMessage id="inspiringBlog.filters.author" />
              {filters[FILTERS.AUTHORS].length > 0 ? ` (${filters[FILTERS.AUTHORS].length})` : ''}
            </Typography>
            <Iconography className={classNames('filter__title-icon')} icon={ICON.CHEVRON_UP} />
          </div>
          <div className="filter__items">
            {authors.map((author, index) => (
              <FormCheckbox
                checked={filters[FILTERS.AUTHORS].indexOf(`${author.id}`) > -1}
                className="filter__item"
                key={index}
                label={author.name}
                onChange={() => toggleFilter(FILTERS.AUTHORS, `${author.id}`)}
                type={CHECKBOX_TYPE.TYPE_FILTER}
              />
            ))}
          </div>
        </div>

        <div className="filter__section-clean">
          <InlineButton onClick={clearFilters}>
            <Typography className={classNames('filter__clean')} variant={VARIANT.BODY_SMALL}>
              <FormattedMessage id="inspiringBlog.filters.clear" />
            </Typography>
          </InlineButton>
          <PrimaryButton onClick={() => setOpenMobile(false)}>
            <FormattedMessage id="inspiringBlog.filters.apply" />
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  pushRoute: bindActionCreators(push, dispatch),
});

export default injectIntl(connect(null, mapDispatchToProps)(InspiringBlogPostFilters));
