export const INSPIRING_BLOG = 'blog';
export const INSPIRING_BLOG_POST = 'blogPost';
export const INSPIRING_BLOG_CATEGORY = 'blogCategory';
export const INSPIRING_BLOG_TAG = 'blogTag';

export const inspiringBlogRouteParams = {
  [INSPIRING_BLOG]: {
    shouldPartiallyRender: true,
  },
  [INSPIRING_BLOG_POST]: {
    shouldPartiallyRender: false,
  },
  [INSPIRING_BLOG_CATEGORY]: {
    shouldPartiallyRender: false,
  },
  [INSPIRING_BLOG_TAG]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [INSPIRING_BLOG]: {
    es: '/blog',
    en: '/blog',
    eu: '/blog',
    fr: '/blog',
  },
  [INSPIRING_BLOG_POST]: {
    es: '/blog/:slug',
    en: '/blog/:slug',
    eu: '/blog/:slug',
    fr: '/blog/:slug',
  },
  [INSPIRING_BLOG_CATEGORY]: {
    es: '/blog/categoria/:slug',
    en: '/blog/category/:slug',
    eu: '/blog/kategoria/:slug',
    fr: '/blog/categorie/:slug',
  },
  [INSPIRING_BLOG_TAG]: {
    es: '/blog/etiqueta/:slug',
    en: '/blog/tag/:slug',
    eu: '/blog/etiketa/:slug',
    fr: '/blog/etiquette/:slug',
  },
};
