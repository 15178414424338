import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Trail} from 'react-spring/renderprops.cjs.js';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import {SecondaryButton, PrimaryButton} from './../../atoms/Button/Button';
import Modal from './../../atoms/Modal/Modal';

import Form from './../Forms/Form';

import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './HeroCategoryTitle.scss';

const HeroCategoryTitle = ({
  button,
  type,
  title,
  titleTag,
  inverted,
  date,
  extraInfo,
  subtitle,
  fullWidth,
  formInfo,
  formId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ButtonTag = inverted ? PrimaryButton : SecondaryButton;

  const elements = [
    <Typography className="hero-category-title__category" key="type" tag="p" variant={VARIANT.HEADING_SMALL}>
      {type}
    </Typography>,
    <Typography className="hero-category-title__title" key="title" tag={titleTag} variant={VARIANT.H1}>
      {title}
    </Typography>,
  ];
  subtitle &&
    elements.push(
      <Typography className="hero-category-title__position" key="subtitle" tag="h2" variant={VARIANT.HEADING_MEDIUM}>
        {subtitle}
      </Typography>,
    );
  date &&
    elements.push(
      <Typography className="hero-category-title__date" key={date} variant={VARIANT.BODY_XSMALL_LOWERCASE}>
        {date}
      </Typography>,
    );
  extraInfo && elements.push(<div className="hero-category-title__extra">{extraInfo}</div>);
  button &&
    elements.push(
      <div className="hero-category-title__button">
        <ButtonTag
          className="hero-category-title__button-button"
          key="button"
          onClick={() => setIsOpen(true)}
          variant={VARIANT.BODY_SMALL}
        >
          {button}
        </ButtonTag>
        <FullRow scrollAnimation={false}>
          <Column>
            <Modal
              className="hero-category-title__modal"
              fullHeight={true}
              isOpen={isOpen}
              onCloseClick={() => setIsOpen(false)}
            >
              <Form formId={formId} formInfo={formInfo} />
            </Modal>
          </Column>
        </FullRow>
      </div>,
    );

  return (
    <div
      className={classNames(
        'hero-category-title',
        `hero-category-title--${type}`,
        {'hero-category-title--inverted': inverted},
        {'hero-category-title--full-width': fullWidth},
      )}
    >
      <Trail
        config={{duration: 1500}}
        from={{opacity: 0, transform: 'translateY(100px)'}}
        items={elements}
        keys={item => item.key}
        to={{opacity: 1, transform: 'translateY(0)'}}
      >
        {item => props => <div style={props}>{item}</div>}
      </Trail>
    </div>
  );
};

HeroCategoryTitle.propTypes = {
  button: PropTypes.string,
  date: PropTypes.string,
  extraInfo: PropTypes.node,
  fullWidth: PropTypes.bool,
  inverted: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p']),
  type: PropTypes.string,
};

HeroCategoryTitle.defaultProps = {
  inverted: false,
  titleTag: 'h1',
  fullWidth: false,
};

export default React.memo(HeroCategoryTitle);
