export const PRESS_RELEASE = 'pressRelease';
export const PRESS_RELEASES = 'pressReleases';

export const pressReleasesRouteParams = {
  [PRESS_RELEASES]: {
    shouldPartiallyRender: true,
  },
  [PRESS_RELEASE]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [PRESS_RELEASES]: {
    es: '/sala-de-prensa',
    en: '/press-room',
    eu: '/prentsa-aretoa',
    fr: '/salle-de-presse',
  },
  [PRESS_RELEASE]: {
    es: '/sala-de-prensa/:slug',
    en: '/press-room/:slug',
    eu: '/prentsa-aretoa/:slug',
    fr: '/salle-de-presse/:slug',
  },
};
