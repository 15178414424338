import React from 'react';
import PropTypes from 'prop-types';

import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import CmsLink from './../../components/CmsLink/CmsLink';
import LogosGrid from './../../components/LogosGrid/LogosGrid';

/* eslint-disable indent */
const RoutingLogosGrid = ({logos, ...rest}) => (
  <LogosGrid
    logos={logos}
    renderContainer={(children, index) =>
      logos[index] &&
      ((logos[index].link && logos[index].link.linkUrl) || (logos[index].file && logos[index].file.file)) ? (
        <CmsLink slug={logos[index].link ? logos[index].link.linkUrl : logos[index].file.file}>{children}</CmsLink>
      ) : (
        React.cloneElement(children)
      )
    }
    {...rest}
  />
);
/* eslint-enable indent */

RoutingLogosGrid.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      image: responsiveImagePropType.isRequired,
      file: PropTypes.shape({
        file: PropTypes.string,
        text: PropTypes.string,
      }),
      link: PropTypes.shape({
        linkUrl: PropTypes.string,
        linkText: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default React.memo(RoutingLogosGrid);
