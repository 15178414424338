import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography, {VARIANT} from '../Typography/Typography';

import './AnimatedInlineButton.scss';

const AnimatedInlineButton = ({inverted, text, ...rest}) => (
  <div className={classNames('animated-inline-button', {'animated-inline-button--inverted': inverted})} {...rest}>
    <span className="animated-inline-button__arrow animated-inline-button__arrow--left">
      <span className="animated-inline-button__shaft" />
    </span>
    <span className="animated-inline-button__main">
      <Typography className="animated-inline-button__text" tag="span" variant={VARIANT.BODY_SMALL}>
        {text}
      </Typography>
      <span className="animated-inline-button__arrow animated-inline-button__arrow--right">
        <span className="animated-inline-button__shaft" />
      </span>
    </span>
  </div>
);

AnimatedInlineButton.propTypes = {
  inverted: PropTypes.bool,
  text: PropTypes.string,
};

AnimatedInlineButton.defaultProps = {
  inverted: false,
};

export default React.memo(AnimatedInlineButton);
