import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import Page from './../../layouts/Page/Page';

import LandingButtonLink from '../../components/Landing/LandingButtonLink/LandingButtonLink.js';
import LandingIcon from '../../components/Landing/LandingIcon/LandingIcon.js';
import LandingImageLink from '../../components/Landing/LandingImageLink/LandingImageLink.js';
import './LandingThankYou.scss';

const LandingThankYou = ({canonical, content}) => (
  <Page canonical={canonical} className="landing" title={content.title}>
    <div className="landing-thank-you">
      <div
        className={`landing-thank-you__background ${
          content.fields.background === 'circles'
            ? 'landing-thank-you__background--circles'
            : 'landing-thank-you__background--tech-mesh'
        }`}
      />

      <div className="landing-thank-you__content">
        <div className="landing-thank-you__header">
          <h1 className="fs-sec--lg landing-thank-you__title">{content.fields.title}</h1>
          {content.fields.description && (
            <div
              className="fs--md landing-thank-you__description"
              dangerouslySetInnerHTML={{__html: content.fields.description}}
            ></div>
          )}
          {content.fields.backButton && (
            <a className="fs-sec--md landing-thank-you__back-button" href={content.fields.backButtonUrl}>
              <LandingIcon path={'/icons/arrow-left-circle.svg'} />
              <span>{content.fields.backButtonText}</span>
            </a>
          )}
        </div>

        <div className={`landing-thank-you__links landing-thank-you__links--${content.fields.linkType}`}>
          {content.fields.linkType === 'none' || !content.fields.links.length ? (
            <LandingIcon path={'/icons/tecnalia-dots.svg'}></LandingIcon>
          ) : (
            content.fields.links.map((item, index) =>
              content.fields.linkType === 'image' ? (
                <LandingImageLink image={item.image} index={index} text={item.title} url={item.url}></LandingImageLink>
              ) : (
                <LandingButtonLink
                  image={item.image}
                  index={index}
                  text={item.title}
                  url={item.url}
                ></LandingButtonLink>
              ),
            )
          )}
        </div>
      </div>
    </div>
  </Page>
);

export default compose(injectIntl, React.memo)(LandingThankYou);
