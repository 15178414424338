import React from 'react';
import classNames from 'classnames';

import './LandingSection.scss';
import Section from '../../../layouts/Section/Section';
import LandingComponent from '../../../layouts/LandingComponent/LandingComponent';

const LandingSection = ({campaignKey, content, webKey}) => (
  <Section
    backgroundType={content.backgroundType}
    className={classNames('cms-section')}
    color={content.color}
    image={content.image}
    layout={content.layout}
    pattern={content.pattern}
  >
    {content.layout === 'single_column' && (
      <LandingComponent campaignKey={campaignKey} items={content.singleColumn} webKey={webKey} />
    )}
    {content.layout === 'two_columns' && (
      <div className="section__two-columns">
        <LandingComponent
          campaignKey={campaignKey}
          className="section__left-column"
          items={content.leftColumn}
          webKey={webKey}
        />
        <LandingComponent
          campaignKey={campaignKey}
          className="section__right-column"
          items={content.rightColumn}
          webKey={webKey}
        />
      </div>
    )}
  </Section>
);
export default React.memo(LandingSection);
