import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography, {VARIANT} from './../Typography/Typography';
import Iconography from './../Iconography/Iconography';

import './Input.scss';

const Input = ({
  label,
  className,
  defaultValue,
  icon,
  inputRef,
  onIconClick,
  placeholder,
  type,
  secondary,
  ...rest
}) => (
  <div className={classNames('input', {'input--secondary': secondary}, className)}>
    {label && (
      <label>
        <Typography className="input__label" variant={VARIANT.LABEL}>
          {label}
        </Typography>
      </label>
    )}
    <input
      className="input__input"
      defaultValue={defaultValue}
      placeholder={placeholder}
      ref={inputRef}
      type={type}
      {...rest}
    />
    {icon && <Iconography className="input__icon" icon={icon} onClick={onIconClick} />}
  </div>
);

Input.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  secondary: PropTypes.bool,
  type: PropTypes.string,
};

Input.defaultProps = {
  handleChange: () => {},
  icon: false,
  secondary: false,
  type: 'text',
};

export default React.memo(Input);
