import React, {useEffect, useState, useRef} from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import HompageMicrodata from './../../seo/Microdata/HomepageMicrodata';

import TalentModule from './TalentModule/TalentModule';
import ContactModule from './ContactModule/ContactModule';
import ServicesModule from './ServicesModule/ServicesModule';
import TechnologiesModule from './TechnologiesModule/TechnologiesModule';
import AgendaModule from './AgendaModule/AgendaModule';
import BlogModule from './BlogModule/BlogModule';
import NewsModule from './NewsModule/NewsModule';
import TecnaliaModule from './TecnaliaModule/TecnaliaModule';
import FixedModule from './FixedModule/FixedModule';

import HomepageHero from './../../compositions/HomepageHero/HomepageHero';

import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';

import './Homepage.scss';

const Homepage = ({content: page, intl}) => {
  const [showButton, setShowButton] = useState(false);
  const refContainer = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', checkScrolled);

    if (!window.document.getElementById('gtag')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'gtag';
      script.src = `https://www.googletagmanager.com/gtag/js?id=DC-11472116`;
      script.async = true;
      script.onload = function() {
        window.dataLayer.push('js', new Date());
        window.dataLayer.push('config', 'DC-11472116');
        window.dataLayer.push('event', 'conversion', {
          allow_custom_scripts: true,
          send_to: 'DC-11472116/parad0/hpg_t0+standard',
        });
      };
      refContainer.current.appendChild(script);
    }

    return () => {
      window.removeEventListener('scroll', checkScrolled);
    };
  }, []);

  const checkScrolled = () => {
    window.requestAnimationFrame(() => {
      const top = document.querySelector('.homepage-hero').getBoundingClientRect().top;
      setShowButton(top < 0);
    }, [setShowButton]);
  };

  return (
    <div className="homepage" ref={refContainer} title={page.title}>
      <HomepageHero video={page.video} />
      <ServicesModule content={page.services} />
      <TechnologiesModule content={page.technologies} />
      <TecnaliaModule content={page.numbersSlider} />
      {page.latestPosts.items && page.latestPosts.items.length > 0 && (
        <BlogModule
          content={page.latestPosts}
          subtitle={intl.formatMessage({id: 'blog.homepage.subtitle'})}
          title={intl.formatMessage({id: 'blog.section.title'})}
        />
      )}
      <Row className="homepage__agenda-blog-module">
        <Column className="homepage__blog-module">
          {page.relatedNews.items && page.relatedNews.items.length > 0 && <NewsModule content={page.relatedNews} />}
        </Column>
        <Column className="homepage__agenda-module">
          <AgendaModule
            agenda={page.nextEvents.items}
            linkText={intl.formatMessage({id: 'show.all'})}
            scrollAnimation={false}
            subtitle={page.nextEvents.subtitle}
            title={page.nextEvents.title}
          />
        </Column>
      </Row>
      <TalentModule content={page.talent} />
      {page.form && page.form.length > 0 && page.form.find(form => form.type === 'general') && showButton && (
        <FixedModule content={page.form.find(form => form.type === 'general')} />
      )}
      <ContactModule content={page.contact} />
      <HompageMicrodata />
    </div>
  );
};

export default compose(injectIntl, React.memo)(Homepage);
