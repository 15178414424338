import {connect} from 'react-redux';
import queryString from 'query-string';

const withLocationSearch = Component => connect(mapStateToProps)(Component);

const searchFromLocation = location => queryString.parse(location.search);

const mapStateToProps = state => ({
  search: searchFromLocation(state.router.location),
});

export default withLocationSearch;
