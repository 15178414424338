import React from 'react';
import PropTypes from 'prop-types';

import responsiveImagePropType from './../../../_propTypes/responsiveImagePropType';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Picture, {RATIO} from './../../../atoms/Picture/Picture';
import {SecondaryButton} from './../../../atoms/Button/Button';

import Column from './../../../layouts/Column/Column';
import FullRow from './../../../layouts/FullRow/FullRow';

import Content from './../../../atoms/Content/Content';

import CmsLink from './../../../components/CmsLink/CmsLink';

import './TalentModule.scss';

const TalentModule = ({content}) => (
  <FullRow className="talent-module" tag="section">
    <Column className="talent-module__column talent-module__column-content">
      <div className="talent-module__content">
        <div className="talent-module__content-header">
          <Typography className="talent-module__content-category" tag="h3" variant={VARIANT.HEADING_SMALL}>
            {content.pretitle}
          </Typography>
          <Typography className="talent-module__content-title" tag="h2" variant={VARIANT.H1}>
            {content.title}
          </Typography>
        </div>
        <div className="talent-module__content-text">
          <Content className="talent-module__content-desc" raw tag="p" variant={VARIANT.BODY}>
            {content.description}
          </Content>
          <CmsLink className="talent-module__content-button" slug={content.linkUrl}>
            <SecondaryButton>
              <Typography variant={VARIANT.BODY_SMALL}>{content.linkTitle}</Typography>
            </SecondaryButton>
          </CmsLink>
        </div>
      </div>
    </Column>
    <Picture
      alt={content.image.alt}
      className="talent-module__column"
      cover
      imageClassName="talent-module__image"
      pictureClassName="talent-module__picture"
      ratio={RATIO.R_4_3}
      src={content.image.src.large}
      srcSet={[
        {src: content.image.src.small, maxWidth: 639},
        {src: content.image.src.medium, maxWidth: 1279},
      ]}
    />
  </FullRow>
);

TalentModule.propTypes = {
  content: PropTypes.shape({
    category: PropTypes.string,
    title: PropTypes.string,
    text1: PropTypes.string,
    text2: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    image: responsiveImagePropType,
  }),
};

export default React.memo(TalentModule);
