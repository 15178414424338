import dayjs from 'dayjs';

import 'dayjs/locale/es';
import 'dayjs/locale/eu';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';

import localizedFormat from 'dayjs/plugin/localizedFormat';

const adaptLocale = locale => {
  switch (locale) {
    case 'es':
      return 'es';
    case 'en':
      return 'en-gb';
    case 'fr':
      return 'fr';
    case 'eu':
      return 'eu';
    default:
      return 'es';
  }
};

const getMonthFromDate = (date, locale) => {
  dayjs.locale(adaptLocale(locale));

  return dayjs(date).format('MMM');
};

const getDayNumberFromDate = date => new Date(date).getDate();

const getFullFormatDate = (date, locale) => {
  dayjs.locale(adaptLocale(locale));
  dayjs.extend(localizedFormat);

  return dayjs(date).format('LL');
};

export {getMonthFromDate, getFullFormatDate, getDayNumberFromDate};
