import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import get from 'lodash.get';

import withSize from './../../../_packages/resize/ui/withSize';
import BREAKPOINTS from './../../_config/breakpoints';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import {AMBIT} from './../../../routing/ambits/routes';
import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {LABORATORY_SERVICE} from './../../../routing/laboratoryServices/routes';
import {PROJECT} from './../../../routing/projects/routes';
import {DELEGATION} from './../../../routing/delegations/routes';

import Typography from './../../atoms/Typography/Typography';

import Hero from './../../compositions/Hero/Hero';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './Delegation.scss';

const Delegation = ({canonical, content: delegation, intl, windowWidth}) => {
  const {lat, lon} = get(delegation, 'googleMaps.map');
  return (
    <div className="delegation">
      <Hero
        content={
          delegation.ambits &&
          delegation.ambits.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XXXXL: 3}}
              tags={delegation.ambits.items}
              title={delegation.ambits.title}
              to={AMBIT}
            />
          )
        }
        image={delegation.image}
        title={delegation.title}
        type={intl.formatMessage({
          id: delegation.categories.type === 'office' ? 'delegation.office.category' : 'delegation.category',
        })}
      />
      <MainLayout
        asideContent={
          <React.Fragment>
            {delegation.relatedContact.items.map((contact, index) => (
              <RoutingExpertModule
                expertPageSlug={contact.slug}
                formId={delegation.formId.id}
                formInfo={delegation.form.find(form => form.type === 'expert')}
                hidden={delegation.relatedContact.simplified}
                imgAlt={contact.title}
                imgSrc={contact.image.src.small}
                key={`relatedContact${index}`}
                name={contact.title}
                position={contact.position}
                templateTitle={delegation.title}
              />
            ))}
          </React.Fragment>
        }
        canonical={canonical}
        /* In the iframe url the parameter t=k is for the satellite view, if we want normal map is t=m */
        mainContent={
          <React.Fragment>
            <div className="delegation__map-wrapper">
              <iframe
                allowFullScreen
                height={windowWidth < BREAKPOINTS.M ? 300 : 600}
                src={`https://maps.google.com/?q=${lat},${lon}&ll=${lat},${lon}&z=18&t=k&output=embed`}
                title="delegation-map"
                width="100%"
              />
            </div>
            {delegation.content && <CmsContent className="delegation__content" content={delegation.content} />}
            {delegation.ambits.items.length > 0 && (
              <RoutingRelatedTags
                breakpoints={{S: 2, XXXXL: 3}}
                tags={delegation.ambits.items}
                title={delegation.ambits.title}
                to={AMBIT}
              />
            )}
            {delegation.technologies.items.length > 0 && (
              <RoutingRelatedTags
                breakpoints={{S: 2, XXXXL: 3}}
                tags={delegation.technologies.items}
                title={delegation.technologies.title}
                to={TECHNOLOGY}
              />
            )}
            {delegation.sectors.items.length > 0 && (
              <RoutingRelatedTags
                breakpoints={{S: 2, XXXXL: 3}}
                tags={delegation.sectors.items}
                title={delegation.sectors.title}
              />
            )}
          </React.Fragment>
        }
      />
      {delegation.relatedService.items.length > 0 && (
        <RelatedContentCards
          slidesPerBreakpoint={{S: 1, XL: 2, XXXXXL: 3}}
          title={delegation.relatedService.title}
          withHorizontalCards
        >
          {delegation.relatedService.items.map((service, index) => (
            <RoutingGenericPreview
              horizontal
              key={index}
              label={intl.formatMessage({id: 'service.category'})}
              preview={service.detailedRelationInfo}
              to={LABORATORY_SERVICE}
            />
          ))}
        </RelatedContentCards>
      )}
      {delegation.referenceProject.items.length > 0 && (
        <RelatedContentCards
          slidesPerBreakpoint={{S: 1, L: 2, XXL: 4}}
          title={intl.formatMessage({id: 'related.projects.title'})}
        >
          {delegation.referenceProject.items.map((project, index) => (
            <RoutingGenericPreview
              key={index}
              label={intl.formatMessage({id: 'project.category'})}
              preview={project}
              to={PROJECT}
            />
          ))}
        </RelatedContentCards>
      )}

      {delegation.relatedMediaGallery.elements &&
        delegation.relatedMediaGallery.elements.length > 0 &&
        delegation.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
          <MediaGallery gallery={delegation.relatedMediaGallery} />
        )}

      {delegation.relatedDelegation.items.length > 0 && (
        <RelatedContentCards
          className="delegation__related-delegations"
          slidesPerBreakpoint={{S: 1, XL: 3, XXXL: 4}}
          title={delegation.relatedDelegation.title}
          withBackground
        >
          {delegation.relatedDelegation.items.map((delegationItem, index) => (
            <RoutingGenericPreview
              content={
                delegationItem.cityProvince.cityProvince && (
                  <React.Fragment>
                    <Typography tag="p">—</Typography>
                    <Typography>{delegationItem.cityProvince.cityProvince}</Typography>
                  </React.Fragment>
                )
              }
              key={index}
              label={intl.formatMessage({
                id: delegationItem.categories.type === 'office' ? 'delegation.office.category' : 'delegation.category',
              })}
              preview={delegationItem}
              to={DELEGATION}
            />
          ))}
        </RelatedContentCards>
      )}
    </div>
  );
};

export default compose(injectIntl, withSize, React.memo)(Delegation);
