import React, {useEffect} from 'react';

import Page from './../Page/Page';

const JobsPage = ({canonical, content}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://careers.talentclue.com/sites/static/widget/jswidget.min.js';
    script.type = 'application/javascript';
    script.id = 'tc-jswidget-script';
    document.getElementsByTagName('body')[0].appendChild(script);
  }, []);

  return <Page canonical={canonical} content={content} />;
};

export default React.memo(JobsPage);
