import React from 'react';
import {FormattedNavLink} from '@foes/react-i18n-routing';

import {INSPIRING_BLOG_POST} from '../../../routing/inspiringBlog/routes';

import InspiringBlogHeroCard from './../../components/InspiringBlogHeroCard/InspiringBlogHeroCard';

const RoutingInspiringBlogHeroCard = ({item}) => (
  <InspiringBlogHeroCard
    post={item}
    renderContainer={children => (
      <FormattedNavLink name={INSPIRING_BLOG_POST} params={{slug: item.slug}}>
        {children}
      </FormattedNavLink>
    )}
    {...item}
  />
);
export default RoutingInspiringBlogHeroCard;
