import React from 'react';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';

import './ExpertSocial.scss';

const ExpertSocial = ({linkedinSlug, socialTitle, renderLinkedin, renderResearchGate, researchgateSlug}) => (
  <div className="expert-social">
    <Typography className="expert-social__title" tag="h3" variant={VARIANT.HEADING}>
      {socialTitle}
    </Typography>
    {linkedinSlug && (
      <div className="expert-social__linkedin">
        <Iconography className="expert-social__icon" icon={ICON.LINKEDIN} />
        {renderLinkedin(<Typography variant={VARIANT.BODY_SMALL}>LinkedIn</Typography>)}
      </div>
    )}
    {researchgateSlug && (
      <div className="expert-social__research-gate">
        <Iconography className="expert-social__icon" icon={ICON.RESEARCHGATE} />
        {renderResearchGate(<Typography variant={VARIANT.BODY_SMALL}>Research Gate</Typography>)}
      </div>
    )}
  </div>
);

ExpertSocial.propTypes = {
  linkedinSlug: PropTypes.string,
  renderLinkedin: PropTypes.func,
  renderResearchGate: PropTypes.func,
  researchgateSlug: PropTypes.string,
  socialTitle: PropTypes.string.isRequired,
};

ExpertSocial.defaultProps = {
  renderLinkedin: children => children,
  renderResearchGate: children => children,
};

export default React.memo(ExpertSocial);
