import React from 'react';

import RoutingFeaturedLink from './../../../_bridges/RoutingFeaturedLink/RoutingFeaturedLink';

import {ICON} from './../../../atoms/Iconography/Iconography';

import Grid from './../../../layouts/Grid/Grid';

import './CmsFeaturedLinkGrid.scss';

const CmsFeaturedLinkGrid = ({content, scrollAnimation}) => (
  <Grid
    className="cms-featured-link-grid"
    columnsPerBreakpoint={{S: 1}}
    scrollAnimation={scrollAnimation}
    title={content.title}
  >
    {content.files.map((file, index) => (
      <RoutingFeaturedLink
        icon={ICON.DOWNLOAD}
        key={index}
        scrollAnimation={false}
        slug={file.file}
        title={file.text}
        uppercaseTitle
      />
    ))}
  </Grid>
);

export default React.memo(CmsFeaturedLinkGrid);
