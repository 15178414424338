import React from 'react';
import LandingSection from '../../components/Landing/LandingSection/LandingSection';
import LandingTitle from '../../components/Landing/LandingTitle/LandingTitle';
import LandingForm from '../../components/Landing/LandingForm/LandingForm';
import LandingAccordionBlock from '../../components/Landing/LandingAccordionBlock/LandingAccordionBlock';
import LandingImage from '../../components/Landing/LandingImage/LandingImage';
import LandingGallery from '../../components/Landing/LandingGallery/LandingGallery';
import LandingVideo from '../../components/Landing/LandingVideo/LandingVideo';
import LandingLogos from '../../components/Landing/LandingLogos/LandingLogos';

const LandingComponent = ({campaignKey, items, webKey}) =>
  items &&
  items.map((item, index) => {
    if (item.type === 'flexible_section_block') {
      return <LandingSection campaignKey={campaignKey} content={item.values} key={index} webKey={webKey} />;
    }
    if (item.type === 'section_title_block') {
      return <LandingTitle content={item.values} key={index} />;
    }

    if (item.type === 'section_form_selector') {
      return (
        <LandingForm
          campaignKey={campaignKey}
          catalogId={item.values.catalogId}
          description={item.values.description}
          document={item.values.document}
          form={item.values.form[0]}
          textColor={item.values.textColor}
          title={item.values.title}
          webKey={webKey}
        />
      );
    }

    if (item.type === 'section_accordion_block') {
      return (
        <LandingAccordionBlock
          accordion={item.values.accordion}
          description={item.values.description}
          key={index}
          label={item.values.label}
          title={item.values.title}
        />
      );
    }

    if (item.type === 'section_image_block') {
      return (
        <LandingImage
          cover={item.values.cover}
          image={item.values.image}
          key={index}
          label={item.values.label}
          labelColor={item.values.labelColor}
          techMesh={item.values.techMesh}
        />
      );
    }

    if (item.type === 'section_gallery_block') {
      return <LandingGallery images={item.values.images} label={item.values.label} />;
    }

    if (item.type === 'section_video_block') {
      return (
        <LandingVideo key={index} label={item.values.label} labelColor={item.values.labelColor} url={item.values.url} />
      );
    }
    if (item.type === 'section_logos_block') {
      return (
        <LandingLogos
          key={index}
          label={item.values.label}
          labelColor={item.values.labelColor}
          logos={item.values.logos}
        />
      );
    }
    return null;
  });

export default React.memo(LandingComponent);
