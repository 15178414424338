import React from 'react';
import PropTypes from 'prop-types';

const EventMicrodata = ({post, canonical}) => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Article',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': canonical,
        },
        headline: post.title,
        description: post.excerpt,
        image: post.image.src.original,
        author: {
          '@type': 'Person',
          name: post.author.displayName,
        },
        publisher: {
          '@type': 'Organization',
          name: 'Tecnalia',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.tecnalia.com/images/logo.svg',
          },
        },
        datePublished: post.createdAt,
        dateModified: post.createdAt,
      }),
    }}
    type="application/ld+json"
  />
);

EventMicrodata.propTypes = {
  canonical: PropTypes.string.isRequired,
  event: PropTypes.shape({
    description: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default React.memo(EventMicrodata);
