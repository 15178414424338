import React from 'react';
import {FormattedMessage} from 'react-intl';

import Comment from './Comment';

import Row from '../../../layouts/Row/Row';
import Column from '../../../layouts/Column/Column';

import Typography, {VARIANT} from '../../../atoms/Typography/Typography';

import './Comments.scss';

const Comments = ({comments}) => {
  const parents = comments.filter(comment => !(comment._embedded['in-reply-to'] || false));
  const children = comments.filter(comment => comment._embedded['in-reply-to'] || false);

  return (
    <Row className="comments-wrapper">
      <Column>
        <Typography variant={VARIANT.HEADING}>
          <FormattedMessage id="inspiringBlog.comments.title" />
        </Typography>
      </Column>
      <Column>
        <Typography variant={VARIANT.HEADING_MEDIUM}>
          {comments.length === 1 ? (
            <FormattedMessage id="inspiringBlog.comments.count.singular" values={{count: comments.length}} />
          ) : (
            <FormattedMessage id="inspiringBlog.comments.count.plural" values={{count: comments.length}} />
          )}
        </Typography>
      </Column>
      {parents &&
        parents.map((comment, key) => (
          <Column className="comments" key={key}>
            <Comment comment={comment} />
            {children
              .filter(child => (child._embedded['in-reply-to'] && child._embedded['in-reply-to'][0].id) === comment.id)
              .map((child, _key) => (
                <Comment className="child" comment={child} key={_key} />
              ))}
          </Column>
        ))}
    </Row>
  );
};

export default Comments;
