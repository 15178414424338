import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {getSearchRoute} from './../../_util/routes';
import removeParamsFromQueryStringByStrategy, {
  FIELDS_FILTER,
} from './../../_util/removeParamsFromQueryStringByStrategy';
import withLocationSearch from './../../_hocs/withLocationSearch';

import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import Input from './../../atoms/Input/Input';

import Column from './../../layouts/Column/Column';

import './SearchBox.scss';

const handleSubmit = ({filter, inputEl, pushRoute, locale, onClick}) => event => {
  event.preventDefault();
  onClick && onClick();
  pushRoute(getSearchRoute(locale, {...filter, search: inputEl.current.value}));
};

const SearchBox = ({
  className,
  defaultValue,
  filter,
  forceClean,
  id,
  intl: {locale, formatMessage},
  onClick,
  placeholderTextId,
  pushRoute,
  search,
  showCloseIcon,
}) => {
  const inputEl = useRef(null);
  filter =
    filter || forceClean
      ? filter
      : removeParamsFromQueryStringByStrategy({queryString: search, strategyKeys: FIELDS_FILTER});
  return (
    <Column className={classNames('search-box', className)}>
      {showCloseIcon && <Iconography className="search-box__close" icon={ICON.CLOSE} onClick={() => onClick()} />}
      <form onSubmit={handleSubmit({filter, inputEl, pushRoute, locale, onClick})}>
        <Input
          className="search-box__input"
          defaultValue={defaultValue}
          icon={ICON.SEARCH}
          id={id}
          inputRef={inputEl}
          onIconClick={handleSubmit({filter, inputEl, pushRoute, locale})}
          placeholder={formatMessage({id: placeholderTextId})}
        />
      </form>
    </Column>
  );
};

const mapDispatchToProps = dispatch => ({
  pushRoute: bindActionCreators(push, dispatch),
});

SearchBox.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  filter: PropTypes.objectOf(PropTypes.string),
  forceClean: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  placeholderTextId: PropTypes.string,
  showCloseIcon: PropTypes.bool,
};

SearchBox.defaultProps = {
  forceClean: false,
  id: 'general',
  placeholderTextId: 'common.search',
  showCloseIcon: true,
};

export default compose(React.memo, connect(null, mapDispatchToProps), withLocationSearch, injectIntl)(SearchBox);
