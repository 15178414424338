export const LABORATORY_SERVICE = 'laboratoryService';
export const LABORATORY_SERVICES = 'laboratoryServices';

export const labServicesRouteParams = {
  [LABORATORY_SERVICES]: {
    shouldPartiallyRender: true,
  },
  [LABORATORY_SERVICE]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [LABORATORY_SERVICES]: {
    es: '/servicios-de-laboratorio',
    en: '/lab-services',
    eu: '/laborategi-zerbitzuak',
    fr: '/services-de-laboratoire',
  },
  [LABORATORY_SERVICE]: {
    es: '/servicios-de-laboratorio/:slug',
    en: '/lab-services/:slug',
    eu: '/laborategi-zerbitzuak/:slug',
    fr: '/services-de-laboratoire/:slug',
  },
};
