import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import {useLocation} from 'react-router';

import {getListRoute, getPostType} from '../../_util/routes';
import {getRelNextCategory, getRelPrevCategory} from '../../_util/pagination';
import withLocationPage from './../../_hocs/withLocationPage';
// eslint-disable-next-line max-len
import RoutingInspiringBlogPostPreview from '../../_bridges/RoutingInspiringBlogPostPreview/RoutingInspiringBlogPostPreview';

import Helmet from './../../seo/Helmet';

import Loader from './../../atoms/Loader/Loader';
import Typography from './../../atoms/Typography/Typography';

import Paginator from './../../components/Paginator/Paginator';

import GenericHero from './../../compositions/GenericHero/GenericHero';

import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';
import Grid from './../../layouts/Grid/Grid';
import Row from './../../layouts/Row/Row';

import './InspiringBlogCategory.scss';

// eslint-disable-next-line max-params
const getNewUrl = (type, page, locale, initialParams, baseUrl) => {
  let url = getListRoute(type, page, locale, initialParams);
  if (baseUrl) {
    const pathnameParts = baseUrl.split('/');
    const slug = pathnameParts[pathnameParts.length - 1];
    url = url.replace(':slug', slug);
  }
  return url;
};

const getSlug = baseUrl => {
  const pathnameParts = baseUrl.split('/');
  const slug = pathnameParts[pathnameParts.length - 1];

  return slug;
};

const formatListRoute = (locale, type, baseUrl = null) => initialParams => page =>
  getNewUrl(type, page, locale, initialParams, baseUrl);

const InspiringBlogCategory = ({content, title, image, intl, pagination, page, type, fetching}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search !== '' ? location.search : '');
  const initialParams = {};

  // eslint-disable-next-line no-return-assign
  Array.from(params.entries()).map(entry => (initialParams[entry[0]] = entry[1]));

  const listBaseUrl = getListRoute(type, 0, intl.locale, initialParams);
  return (
    <div className="generic-container">
      <GenericHero backgroundImage={image} title={title} type={title} />
      <Helmet
        forceNoIndex={content.length === 0}
        relNext={getRelNextCategory(getSlug(location.pathname))(page, pagination.totalPages, listBaseUrl)}
        relPrev={getRelPrevCategory(getSlug(location.pathname))(page, listBaseUrl)}
        title={title}
      />
      <Row className="generic-container__content">
        <Column className="generic-container__content-main">
          {!fetching ? (
            content.length > 0 ? (
              <Grid
                className="generic-container__grid"
                columnsPerBreakpoint={{S: 1, M: 2, XXL: 3}}
                scrollAnimation={false}
                tag={FullRow}
              >
                {content.map((card, index) => (
                  <RoutingInspiringBlogPostPreview key={index} preview={card} to={getPostType(card.type)} />
                ))}
              </Grid>
            ) : (
              <Typography className="generic-container__non-content">
                {intl.formatMessage({id: type === 'pressReleases' ? 'list.press.release.none' : 'list.news.none'})}
              </Typography>
            )
          ) : (
            <Loader />
          )}
          {pagination.totalPages > 1 && (
            <Paginator
              currentPage={page}
              formatPageRoute={formatListRoute(intl.locale, type, location.pathname)(initialParams)}
              totalPages={pagination.totalPages}
            />
          )}
        </Column>
      </Row>
    </div>
  );
};

export default compose(React.memo, injectIntl, withLocationPage)(InspiringBlogCategory);
