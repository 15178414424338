import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Iconography, {ICON} from './../Iconography/Iconography';

import './Modal.scss';

const customStyles = {
  overlay: {
    zIndex: 'var(--z-index-modal)',
  },
};

ReactModal.setAppElement('#root');

const Modal = ({children, className, contentLabel, closeModal, fullHeight, isOpen, onCloseClick}) => (
  <ReactModal
    bodyOpenClassName="modal--opened"
    className={classNames('modal', {'modal--full': fullHeight}, className)}
    contentLabel={contentLabel}
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={customStyles}
  >
    <div className="modal__body">
      <Iconography className="modal-close" icon={ICON.CLOSE} onClick={onCloseClick} />
      {children}
    </div>
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeModal: PropTypes.func,
  contentLabel: PropTypes.string,
  fullHeight: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
};

export default React.memo(Modal);
