import React from 'react';
import {FormattedLink} from '@foes/react-i18n-routing';
// import PropTypes from 'prop-types';

import {EVENT} from './../../../routing/events/routes';

import Agenda from './../../components/Agenda/Agenda';

const RoutingAgenda = ({item, ...rest}) => (
  <Agenda
    item={item}
    renderContainer={children => (
      <FormattedLink className="agenda__content-link" name={EVENT} params={{slug: item.slug}}>
        {children}
      </FormattedLink>
    )}
    {...rest}
  />
);

RoutingAgenda.propTypes = {
  // TODO the items are hardcoded, need to check Proptypes once we know how it is
};

export default React.memo(RoutingAgenda);
