export const getPostTypeSingularLabel = type => {
  switch (type) {
    case 'technology': {
      return 'technology.category';
    }
    case 'inspiring_story': {
      return 'inspiringStories.category';
    }
    case 'post': {
      return 'inspiringBlog.category';
    }
    case 'business_opportunity': {
      return 'business.opportunity.category';
    }
    case 'id_service': {
      return 'lab.service.category';
    }
    case 'infrastructure': {
      return 'infrastructures.category';
    }
    case 'laboratory': {
      return 'lab.service.category';
    }
    case 'news': {
      return 'news.category';
    }
    case 'press_release': {
      return 'press.release.category';
    }
    case 'project': {
      return 'project.category';
    }
    case 'sector': {
      return 'sectors.category';
    }
    case 'ambit': {
      return 'ambit.category';
    }
    case 'active': {
      return 'business.opportunity.active';
    }
    case 'company': {
      return 'business.opportunity.company';
    }
    case 'publication': {
      return 'publications.category';
    }
    default: {
      return '';
    }
  }
};
