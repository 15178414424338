import React from 'react';
import PropTypes from 'prop-types';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';

import Content from './../../atoms/Content/Content';

import FullRow from './../../layouts/FullRow/FullRow';

import './Infrastructures.scss';

const Infrastructures = ({infrastructures, renderInfrastructureLocation, renderInfrastructurePage, title}) => (
  <FullRow className="infrastructures">
    <Typography className="infrastructures__title" tag="h3" variant={VARIANT.HEADING_MEDIUM}>
      {title}
    </Typography>
    {infrastructures.map((infrastructure, index) => (
      <div className="infrastructures__item" key={index}>
        <Iconography className="infrastructures__icon" icon={ICON.PLACE} />
        <div className="infrastructures__info">
          {infrastructure.title &&
            renderInfrastructurePage(
              <React.Fragment>
                <Typography className="infrastructure__page-text" tag="p" variant={VARIANT.BODY_SMALL}>
                  {infrastructure.title}
                </Typography>
              </React.Fragment>,
              index,
            )}
          {infrastructure.detailedExcerpt &&
            renderInfrastructureLocation(
              <React.Fragment>
                <Content raw tag="p" variant={VARIANT.CONTACT}>
                  {infrastructure.detailedExcerpt}
                </Content>
              </React.Fragment>,
              index,
            )}
        </div>
      </div>
    ))}
  </FullRow>
);

Infrastructures.propTypes = {
  infrastructures: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  renderInfrastructureLocation: PropTypes.func,
  renderInfrastructurePage: PropTypes.func,
  title: PropTypes.string.isRequired,
};

Infrastructures.defaultProps = {
  renderInfrastructureLocation: children => children,
  renderInfrastructurePage: children => children,
};

export default React.memo(Infrastructures);
