import React from 'react';
import Helmet from 'react-helmet';

const formatBreadcrumbs = breadcrumbs => {
  const elements = [];

  breadcrumbs.map((item, index) =>
    elements.push({
      '@type': 'ListItem',
      position: index + 1,
      name: item.title,
      item: `${process.env.REACT_APP_HOST_DOMAIN}${item.url}`,
    }),
  );

  return elements;
};

const BreadcrumbsMicrodata = ({breadcrumbs}) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        itemListElement: formatBreadcrumbs(breadcrumbs),
      })}
    </script>
  </Helmet>
);

export default BreadcrumbsMicrodata;
