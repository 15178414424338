import React, {useState} from 'react';

import Picture from './../../../atoms/Picture/Picture';
import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';
import Video from './../../../atoms/Video/Video';

import './VideoComponent.css';

const VideoComponent = ({item}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <div className="video-component">
      <Picture
        alt={item.description}
        asyncLoad={false}
        className="video-component__image"
        src={`https://i.ytimg.com/vi/${item.id}/hqdefault.jpg`}
      />
      <Iconography className="video-component__icon" icon={ICON.PLAY} onClick={() => setIsPlaying(true)} />
      {isPlaying && <Video src={`https://www.youtube.com/embed/${item.id}?autoplay=1`} />}
    </div>
  );
};

export default React.memo(VideoComponent);
