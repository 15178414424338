import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import RoutingFeaturedPreviewCard from './../../_bridges/RoutingFeaturedPreviewCard/RoutingFeaturedPreviewCard';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import FullRow from './../../layouts/FullRow/FullRow';

import './FeaturedPreview.scss';

const FeaturedPreview = ({items, intl, maxItems, title}) => {
  const [opened, setOpened] = useState(false);

  const itemsToShow = opened ? items : items.slice(0, maxItems);
  return (
    <FullRow>
      <div className="featured-preview">
        <Typography className="featured-preview__title" variant={VARIANT.HEADING}>
          {title}
        </Typography>
        {itemsToShow.map((item, index) => (
          <RoutingFeaturedPreviewCard
            image={item.detailedRelationInfo.image}
            index={index}
            intl={intl}
            key={item + index}
            slug={item.slug}
            text={item.detailedRelationInfo.text}
            title={item.detailedRelationInfo.title}
            type={item.type}
          />
        ))}
      </div>
      {items.length > maxItems && (
        <Typography
          className="featured-preview__show-button"
          onClick={() => setOpened(!opened)}
          variant={VARIANT.BODY_SMALL}
        >
          <FormattedMessage id={opened ? 'show.less' : 'show.all'} />
          <Iconography className="featured-preview__icon" icon={opened ? ICON.CLOSE : ICON.PLUS} />
        </Typography>
      )}
    </FullRow>
  );
};

FeaturedPreview.propTypes = {
  maxItems: PropTypes.number,
  title: PropTypes.string,
};

export default compose(injectIntl, React.memo)(FeaturedPreview);
