import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {compose} from 'redux';

import {addItems, selectItem} from './AnchorMenu.utils';

import BREAKPOINTS from './../../_config/breakpoints';

import withSize from './../../../_packages/resize/ui/withSize';

import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Column from './../../layouts/Column/Column';
import FullRow from './../../layouts/FullRow/FullRow';

import './AnchorMenu.scss';

const AnchorMenu = ({content, gradient, sticky, windowWidth}) => {
  const [isMobileOpen, setMobileOpened] = useState(false);

  const [selected, setSelected] = useState();

  const [items, setItems] = useState([]);

  useEffect(() => {
    addItems(setItems, content);
  }, [content]);

  useEffect(() => {
    function setItem() {
      selectItem(setSelected, items);
    }

    window.addEventListener('scroll', setItem);
    return () => {
      window.removeEventListener('scroll', setItem);
    };
  }, [items]);

  const handleScrollTo = (e, anchor) => {
    e.preventDefault();
    if (anchor) {
      const element = document.querySelector(anchor);
      if (element) {
        const elementClientRect = element.getBoundingClientRect().top;
        const offset = 150;

        const elementPosition = elementClientRect - offset;

        window.scrollBy({
          top: elementPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleMenuMobileClicked = () => {
    setMobileOpened(!isMobileOpen);
  };

  return (
    <div
      className={classNames('anchor-menu', {
        'anchor-menu--mobile': windowWidth < BREAKPOINTS.XL,
        'anchor-menu--open': windowWidth < BREAKPOINTS.XL && isMobileOpen,
        'anchor-menu--sticky': sticky,
        'anchor-menu--gradient': gradient,
        [`anchor-menu--${gradient}`]: gradient && gradient !== 'none',
        [`anchor-menu--red`]: gradient && gradient === 'none',
      })}
    >
      <FullRow className="anchor-menu__wrapper" scrollAnimation={false}>
        {windowWidth >= BREAKPOINTS.XL &&
          content.items.length > 0 &&
          content.items.map(({anchor, text}, index) => (
            <Column key={index}>
              <a
                className={classNames('anchor__item', {
                  'anchor__item-selected': anchor && selected && `#${selected.id}` === anchor,
                })}
                href={anchor}
                id={`anchor__item${index + 1}`}
                onClick={(e) => {
                  handleScrollTo(e, anchor);
                }}
              >
                {text}
              </a>
            </Column>
          ))}

        {windowWidth < BREAKPOINTS.XL && (
          <Column className="anchor-menu__switch" onClick={handleMenuMobileClicked}>
            <Typography className="header-menu-mobile__item-typo" variant={VARIANT.MENU}>
              MENU
            </Typography>
            <Iconography className="anchor-menu__switch-icon" icon={ICON.PYKE} />
          </Column>
        )}
      </FullRow>
      {windowWidth < BREAKPOINTS.XL && isMobileOpen && (
        <div className="anchor-menu-mobile">
          {content.items.length > 0 &&
            content.items.map(({anchor, text}, index) => (
              <Column className="anchor-menu-mobile__column">
                <a
                  className={classNames('anchor__item', 'anchor-menu-mobile__item', {
                    // 'anchor__item-selected': anchor && selected && `#${selected.id}` === anchor,
                  })}
                  href={anchor}
                  key={index}
                  onClick={(e) => {
                    setMobileOpened(false);
                    handleScrollTo(e, anchor);
                  }}
                >
                  {text}
                </a>
              </Column>
            ))}
        </div>
      )}
    </div>
  );
};

AnchorMenu.defaultProps = {
  gradient: '',
};

export default compose(withSize, React.memo)(AnchorMenu);
