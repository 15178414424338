import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import classNames from 'classnames';

import withLocationPage from './../../_hocs/withLocationPage';

import {getListRoute} from './../../_util/routes';
import {getRelNext, getRelPrev} from './../../_util/pagination';

import Helmet from './../../seo/Helmet';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import AnchorMenu from './../../components/AnchorMenu/AnchorMenu';
import CatalogueDownload from '../../components/CatalogueDownload/CatalogueDownload';
import CatalogueNoForm from '../../components/CatalogueNoForm/CatalogueNoForm';
import CmsDivider from './../../components/CmsComponents/CmsDivider/CmsDivider';

import OnepageHero from './../../compositions/OnepageHero/OnepageHero';

import Column from './../../layouts/Column/Column';
import CmsContent from './../../layouts/CmsContent/CmsContent';
import Grid from './../../layouts/Grid/Grid';
import MainLayout from './../../layouts/MainLayout/MainLayout';
import Row from './../../layouts/Row/Row';

import './Publications.scss';

/* eslint-disable react-hooks/exhaustive-deps */

const CatalogGrid = ({category}) => (
  <Grid className="publication__cards" columnsPerBreakpoint={{S: 1, M: 3, XL: 4}} scrollAnimation={false}>
    {category.items.map((catalog, index) => (
      <div className="publication__info" key={`publication_${index}`}>
        {catalog.formId.catalogId ? (
          <CatalogueDownload
            catalogueInfo={catalog}
            formId={catalog.formId.catalogId}
            formInfo={catalog.form.find(form => form.type === 'download')}
            scrollAnimation={false}
          />
        ) : (
          <CatalogueNoForm catalogueInfo={catalog} key={`publication_form_${index}`} scrollAnimation={false} />
        )}
      </div>
    ))}
  </Grid>
);

const CatalogGridWrapper = ({category, categoryIndex, intl}) => (
  <>
    {category.items
      .filter(subcategory => subcategory.slug === '')
      .map((subcategory, subcategoryIndex) => (
        <React.Fragment key={`publication_${categoryIndex}_${subcategoryIndex}`}>
          {category.items.length > 1 && subcategory.slug === '' ? (
            <Typography className="publication__subcategories-title" tag="h3" variant={VARIANT.H4}>
              {intl.formatMessage({id: subcategory.title})}
            </Typography>
          ) : (
            <>
              {subcategory.slug !== '' && (
                <Typography className="publication__subcategories-title" tag="h3" variant={VARIANT.H4}>
                  {subcategory.title}
                </Typography>
              )}
            </>
          )}
          <CatalogGrid category={subcategory} />
        </React.Fragment>
      ))}
    {category.items
      .filter(subcategory => subcategory.slug !== '')
      .map((subcategory, subcategoryIndex) => (
        <React.Fragment key={`publication_${categoryIndex}_${subcategoryIndex}`}>
          {category.items.length > 1 && subcategory.slug === '' ? (
            <Typography className="publication__subcategories-title" tag="h3" variant={VARIANT.H4}>
              {intl.formatMessage({id: subcategory.title})}
            </Typography>
          ) : (
            <>
              {subcategory.slug !== '' && (
                <Typography className="publication__subcategories-title" tag="h3" variant={VARIANT.H4}>
                  {subcategory.title}
                </Typography>
              )}
            </>
          )}
          <CatalogGrid category={subcategory} />
        </React.Fragment>
      ))}
  </>
);

const Publications = ({content, title, image, intl, pagination, page, type}) => {
  const listBaseUrl = getListRoute(type, 0, intl.locale, {});

  const [publications, setPublications] = useState();

  const [menu, setMenu] = useState();

  const [sticky, setSticky] = useState(false);

  const groupPublication = (carry, item) => {
    if (!carry[item.type]) {
      carry[item.type] = {
        type: item.type,
        items: [],
      };
    }
    carry[item.type].items.push(item);
    return carry;
  };

  const groupPublicationsMenu = elements => {
    const currentMenu = {
      items: [],
    };

    const ebook = elements.find(element => element.type === 'ebook');

    if (typeof ebook !== 'undefined') {
      currentMenu.items.push({
        anchor: `#${ebook.type}`,
        text: intl.formatMessage({id: ebook.type}),
        type: ebook.type,
      });
    }

    const catalogue = elements.find(element => element.type === 'catalogues');

    if (typeof catalogue !== 'undefined') {
      currentMenu.items.push({
        anchor: `#${catalogue.type}`,
        text: intl.formatMessage({id: catalogue.type}),
        type: catalogue.type,
      });
    }

    const presentation = elements.find(element => element.type === 'presentations');

    if (typeof presentation !== 'undefined') {
      currentMenu.items.push({
        anchor: `#${presentation.type}`,
        text: intl.formatMessage({id: presentation.type}),
        type: presentation.type,
      });
    }

    const report = elements.find(element => element.type === 'report');

    if (typeof report !== 'undefined') {
      currentMenu.items.push({
        anchor: `#${report.type}`,
        text: intl.formatMessage({id: report.type}),
        type: report.type,
      });
    }

    const magazine = elements.find(element => element.type === 'magazine');

    if (typeof magazine !== 'undefined') {
      currentMenu.items.push({
        anchor: `#${magazine.type}`,
        text: intl.formatMessage({id: magazine.type}),
        type: magazine.type,
      });
    }

    return currentMenu;
  };

  const groupCategoryItems = (carry, item) => {
    if (item.ambits.items.length > 0 && !carry[item.ambits.items[0].title]) {
      carry[item.ambits.items[0].title] = {
        title: item.ambits.items[0].title,
        slug: item.ambits.items[0].slug,
        items: [],
      };
    } else if (item.ambits.items.length === 0 && !carry.other) {
      carry.other = {
        title: 'corporate_catalogs',
        slug: '',
        items: [],
      };
    }
    carry[item.ambits.items.length > 0 ? item.ambits.items[0].title : 'other'].items.push(item);
    return carry;
  };

  const orderPublications = elements =>
    elements.map(item => {
      if (item.type !== 'report') {
        return {type: item.type, items: Object.values(item.items.reduce(groupCategoryItems, []))};
      }
      return item;
    });

  const groupPublications = elements => Object.values(elements.reduce(groupPublication, []));
  const pipe = (...fns) => item => fns.reduce((c, fn) => fn(c), item);

  useEffect(() => {
    setPublications(pipe(groupPublications, orderPublications)(content.publications));
    setMenu(pipe(groupPublicationsMenu)(content.publications));
  }, []);

  useEffect(() => {
    function changeSticky() {
      const onepageHero = document.querySelector('.onepage-hero');
      const onepageHeroOffset = onepageHero.getBoundingClientRect();
      if (onepageHeroOffset.bottom <= 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }

    window.addEventListener('scroll', changeSticky);
    return () => {
      window.removeEventListener('scroll', changeSticky);
    };
  }, []);

  return (
    <div className="publications">
      <OnepageHero backgroundImage={image} color="fuchsia-pink" title={title} type={title} />
      <Helmet
        relNext={getRelNext(page, pagination.totalPages, listBaseUrl)}
        relPrev={getRelPrev(page, listBaseUrl)}
        title={title}
      />
      {menu && <AnchorMenu content={menu} gradient="fuchsia-pink" sticky={sticky}></AnchorMenu>}
      <Row className="generic-container__content" scrollAnimation={false}>
        <Column className="generic-container__content-main">
          <MainLayout
            asideContent={<React.Fragment></React.Fragment>}
            className={classNames({
              'main-layout__sticky': sticky,
            })}
            mainContent={content.content && <CmsContent content={content.content} />}
            scrollAnimation={false}
            socialText={content.title}
          />
          {publications &&
            publications
              .filter(category => category.type === 'ebook')
              .map((category, categoryIndex) => (
                <React.Fragment key={`publication_${categoryIndex}`}>
                  <Typography
                    className="publication__categories-title"
                    id={category.type}
                    tag="h2"
                    variant={VARIANT.HEADING}
                  >
                    {intl.formatMessage({id: category.type})}
                  </Typography>
                  <CmsDivider />
                  <CatalogGridWrapper
                    category={category}
                    categoryIndex={categoryIndex}
                    intl={intl}
                  ></CatalogGridWrapper>
                </React.Fragment>
              ))}
          {publications &&
            publications
              .filter(category => category.type === 'catalogues')
              .map((category, categoryIndex) => (
                <React.Fragment key={`publication_${categoryIndex}`}>
                  <Typography
                    className="publication__categories-title"
                    id={category.type}
                    tag="h2"
                    variant={VARIANT.HEADING}
                  >
                    {intl.formatMessage({id: category.type})}
                  </Typography>
                  <CmsDivider />
                  <CatalogGridWrapper
                    category={category}
                    categoryIndex={categoryIndex}
                    intl={intl}
                  ></CatalogGridWrapper>
                </React.Fragment>
              ))}

          {publications &&
            publications
              .filter(category => category.type === 'presentations')
              .map((category, categoryIndex) => (
                <React.Fragment key={`publication_${categoryIndex}`}>
                  <Typography
                    className="publication__categories-title"
                    id={category.type}
                    tag="h2"
                    variant={VARIANT.HEADING}
                  >
                    {intl.formatMessage({id: category.type})}
                  </Typography>
                  <CmsDivider />

                  <CatalogGridWrapper
                    category={category}
                    categoryIndex={categoryIndex}
                    intl={intl}
                  ></CatalogGridWrapper>
                </React.Fragment>
              ))}

          {publications &&
            publications
              .filter(category => category.type === 'report')
              .map((category, categoryIndex) => (
                <React.Fragment key={`publication_${categoryIndex}`}>
                  <Typography
                    className="publication__categories-title"
                    id={category.type}
                    tag="h2"
                    variant={VARIANT.HEADING}
                  >
                    {intl.formatMessage({id: category.type})}
                  </Typography>
                  <CmsDivider />
                  <CatalogGrid category={category} />
                </React.Fragment>
              ))}

          {publications &&
            publications
              .filter(category => category.type === 'magazine')
              .map((category, categoryIndex) => (
                <React.Fragment key={`publication_${categoryIndex}`}>
                  <Typography
                    className="publication__categories-title"
                    id={category.type}
                    tag="h2"
                    variant={VARIANT.HEADING}
                  >
                    {intl.formatMessage({id: category.type})}
                  </Typography>
                  <CmsDivider />
                  <CatalogGridWrapper
                    category={category}
                    categoryIndex={categoryIndex}
                    intl={intl}
                  ></CatalogGridWrapper>
                </React.Fragment>
              ))}
        </Column>
      </Row>
    </div>
  );
};

export default compose(React.memo, injectIntl, withLocationPage)(Publications);
