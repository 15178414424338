import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Loader from './../../../atoms/Loader/Loader';
import './LandingForm.scss';

const LandingForm = ({campaignKey, catalogId, description, document, form, textColor, title, webKey}) => {
  const iframew = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const formStyle = {
    color: textColor,
  };

  const randomInt = Math.floor(100000 + Math.random() * 900000);
  const iFrameID = `landing-form-${randomInt}`;

  const getUrlWithParams = () => {
    if (form) {
      return (
        `${form.url}?Clave_web=${catalogId || webKey}` +
        `&Catalogo=${(document && document.document && document.document.file && document.document.file.url) ||
          'N/A'}&Titulo=${(document && document.title) || 'N/A'}&Ubicacion=N/A&Codigo_campanya=${campaignKey || 'N/A'}`
      );
    }
  };

  function captureLoad() {
    const currentIframe = iframew.current;
    currentIframe.contentWindow.postMessage({id: iFrameID, color: textColor, windowWidth: window.innerWidth}, '*');

    const setIframeHeight = event => {
      if (event.origin !== 'https://www2.e.tecnalia.com' || event.data.id !== iFrameID) {
        return;
      }
      currentIframe.style.height = `${event.data.height}px`;
    };
    window.addEventListener('message', setIframeHeight);

    window.addEventListener('resize', () => {
      currentIframe.contentWindow.postMessage({id: iFrameID, windowWidth: window.innerWidth}, '*');
    });

    setIsLoading(false);
  }

  return (
    <div className="landing-form__wrapper" style={formStyle}>
      {(title || description) && (
        <div className="landing-form__header">
          {title && <h2 className="fs--lg">{title}</h2>}
          {description && (
            <div className="fs--body landing-form__description" dangerouslySetInnerHTML={{__html: description}}></div>
          )}
        </div>
      )}
      {isLoading && <Loader />}

      <iframe
        className={`landing-form__iframe ${isLoading ? 'landing-form__iframe--loading' : ''}`}
        id={iFrameID}
        onLoad={captureLoad}
        ref={iframew}
        src={getUrlWithParams()}
        title={title}
        type="text/html"
      />
    </div>
  );
};

LandingForm.propTypes = {
  campaignKey: PropTypes.string,
  catalogId: PropTypes.string,
  description: PropTypes.string,
  document: PropTypes.object,
  form: PropTypes.object,
  textColor: PropTypes.string,
  title: PropTypes.string,
  webKey: PropTypes.string,
};
export default LandingForm;
