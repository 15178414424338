import React, {useState} from 'react';
import PropTypes from 'prop-types';

import filePropType from './../../_propTypes/filePropType';
import responsiveImagePropType from './../../_propTypes/responsiveImagePropType';

import InlineButton from './../../atoms/InlineButton/InlineButton';
import Picture from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Modal from './../../atoms/Modal/Modal';

import Form from './../Forms/Form';

import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './CatalogueDownload.scss';

const CatalogueDownload = ({catalogueInfo, delay, formId, formInfo, scrollAnimation}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {document, image} = catalogueInfo;

  return (
    <FullRow className="catalogue-download" delay={delay} scrollAnimation={scrollAnimation} tag="article">
      <Picture alt={image.alt} className="catalogue-download__image" src={image.src.small} />
      <Typography tag="p" variant={VARIANT.BODY}>
        {catalogueInfo.title}
      </Typography>
      <InlineButton className="catalogue-download__button" onClick={() => setIsOpen(true)}>
        <Typography tag="span" variant={VARIANT.BODY_SMALL}>
          {document.linkText}
        </Typography>
      </InlineButton>

      <FullRow>
        <Column>
          <Modal
            className="expert-module__modal"
            fullHeight={true}
            isOpen={isOpen}
            onCloseClick={() => setIsOpen(false)}
          >
            <Form catalog={catalogueInfo} formId={formId} formInfo={formInfo} />
          </Modal>
        </Column>
      </FullRow>
    </FullRow>
  );
};

CatalogueDownload.propTypes = {
  catalogueInfo: PropTypes.shape({
    document: PropTypes.shape({linkText: PropTypes.string, file: filePropType}),
    image: responsiveImagePropType,
    type: PropTypes.string,
    delay: PropTypes.number,
  }),
};

CatalogueDownload.defaultProps = {
  delay: 0,
  scrollAnimation: true,
};

export default React.memo(CatalogueDownload);
