import {FormattedLink} from '@foes/react-i18n-routing';
import get from 'lodash.get';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';
import {HOME} from './../../../routing/routes';
import BREAKPOINTS from './../../_config/breakpoints';

import AnimatedInlineButton from './../../atoms/AnimatedInlineButton/AnimatedInlineButton';
import {SecondaryButton} from './../../atoms/Button/Button';
import Content from './../../atoms/Content/Content';
import Iconography from './../../atoms/Iconography/Iconography';
import Modal from './../../atoms/Modal/Modal';
import Picture from './../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import CmsLink from './../../components/CmsLink/CmsLink';
import Form from './../../components/Forms/Form';

import Column from './../Column/Column';
import FullRow from './../FullRow/FullRow';
import Grid from './../Grid/Grid';
import Row from './../Row/Row';

import './Footer.scss';

const Footer = ({windowWidth}) => {
  const [isNewsletterOpened, setIsNewsletterOpened] = useState(false);
  const [isContactOpened, setIsContactOpened] = useState(false);

  const menus = useSelector(state => get(state, 'api.backendContext.resource.menus'));
  if (!menus) {
    return null;
  }
  const footer = menus.filter(menu => menu.originalSlug === 'footer')[0];
  // const contact = menus.filter(menu => menu.originalSlug === 'contact')[0];
  const {left, center, right, bottom} = footer.options;

  return (
    <footer className="footer">
      <Grid className="footer__grid" columnsPerBreakpoint={{S: 1, L: 3}} scrollAnimation={false}>
        <div className="footer__left">
          <div className="footer__logos footer__logo--left">
            <FormattedLink name={HOME}>
              <Picture alt={left.logo.alt} src={left.logo.src.original} />
            </FormattedLink>
          </div>
          <div className="footer__info">
            <Content className="footer__header" raw variant={VARIANT.HEADING_SMALL}>
              {left.title}
            </Content>
            <Content raw variant={VARIANT.FOOTER}>
              {left.text}
            </Content>
            <CmsLink className="footer__arrow" slug={left.cta.url}>
              <AnimatedInlineButton inverted text={left.cta.text} />
            </CmsLink>
          </div>
        </div>
        <div className="footer__center">
          {windowWidth >= BREAKPOINTS.L && <div className="footer__logos" />}
          <div className="footer__info">
            <Content className="footer__header" raw variant={VARIANT.HEADING_SMALL}>
              {center.title}
            </Content>
            <Content className="footer__info-content" raw variant={VARIANT.FOOTER}>
              {center.text}
            </Content>
            <AnimatedInlineButton inverted onClick={() => setIsContactOpened(true)} text={center.button.text} />
            <FullRow>
              <Column>
                <Modal
                  className="footer__center-modal"
                  fullHeight={true}
                  isOpen={isContactOpened}
                  onCloseClick={() => setIsContactOpened(false)}
                >
                  <Form formUrl={center.button.url} title={center.button.text} type="footer_contact" />
                </Modal>
              </Column>
            </FullRow>
          </div>
        </div>

        <div className="footer__right">
          <div className="footer__logos footer__logo--right">
            <CmsLink slug={right.logo.left.url}>
              <Picture alt={right.logo.left.image.alt} src={right.logo.left.image.src.small} />
            </CmsLink>
            <CmsLink slug={right.logo.center.url}>
              <Picture alt={right.logo.center.image.alt} src={right.logo.center.image.src.small} />
            </CmsLink>
            <CmsLink slug={right.logo.right.url}>
              <Picture alt={right.logo.right.image.alt} src={right.logo.right.image.src.small} />
            </CmsLink>
          </div>
          <div className="footer__info">
            <Content className="footer__header" raw variant={VARIANT.HEADING_SMALL}>
              {right.title}
            </Content>
            <div className="footer__social">
              {right.networks.map((socialLink, index) => (
                <CmsLink key={index} slug={socialLink.url} type={socialLink.type}>
                  <Iconography className="footer__social-icon" icon={socialLink.type} />
                </CmsLink>
              ))}
            </div>
            <SecondaryButton className="footer__newsletter-cta" onClick={() => setIsNewsletterOpened(true)}>
              <Typography variant={VARIANT.BODY_SMALL}>{right.button.text}</Typography>
            </SecondaryButton>
            <FullRow>
              <Column>
                <Modal
                  className="footer__newsleter-modal"
                  fullHeight={true}
                  isOpen={isNewsletterOpened}
                  onCloseClick={() => setIsNewsletterOpened(false)}
                >
                  <Form formUrl={right.button.url} title={right.button.text} type="footer_newsletter" />
                </Modal>
              </Column>
            </FullRow>
          </div>
        </div>
      </Grid>

      <Row className="footer__privacy" scrollAnimation={false}>
        <Column className="footer__privacy-text">
          <Typography variant={VARIANT.FOOTER_SMALL}>{bottom.text}</Typography>
        </Column>

        <Column className="footer__privacy-links">
          {footer.items.map((privacyLink, index) => (
            <CmsLink className="footer__privacy-item" key={index} slug={privacyLink.slug}>
              <Content variant={VARIANT.FOOTER_SMALL}>{privacyLink.title}</Content>
            </CmsLink>
          ))}
        </Column>
      </Row>
    </footer>
  );
};

export default compose(withSize, React.memo)(Footer);
