import React from 'react';

import {PrimaryButton} from './../../../atoms/Button/Button';
import Content from './../../../atoms/Content/Content';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';

import Column from './../../../layouts/Column/Column';
import Row from './../../../layouts/Row/Row';

import CmsLink from './../../../components/CmsLink/CmsLink';
import {useSelector} from 'react-redux';

import './CmsForm.scss';

const getMenus = state =>
  state.api.backendContext && state.api.backendContext.resource && state.api.backendContext.resource.menus;

const CmsForm = ({content, scrollAnimation}) => {
  const {title, description, form} = content;

  const menus = useSelector(state => getMenus(state));
  const contactMenu = menus && menus.filter(menu => menu.originalSlug === 'contact');
  const contact =
    contactMenu && contactMenu[0] && contactMenu[0].items && contactMenu[0].items.length && contactMenu[0].items[0];
  if (!menus) {
    return null;
  }

  return (
    <Row className="cms-form" scrollAnimation={scrollAnimation}>
      <Column className="cms-form__column">
        {title && <Typography variant={VARIANT.HEADING}>{title}</Typography>}
        {description && (
          <Content className="cms-form__description" raw>
            {description}
          </Content>
        )}

        {contact && (
          <CmsLink className="contact-module__button" slug={contact.slug}>
            <PrimaryButton className="contact-module__button">
              <Typography variant={VARIANT.BODY_SMALL}>{form[0].buttonTitle}</Typography>
            </PrimaryButton>
          </CmsLink>
        )}
      </Column>
    </Row>
  );
};

export default React.memo(CmsForm);
