import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';
import Flickity from 'react-flickity-component';

import BREAKPOINTS, {valuePerBreakpointForConfig} from './../../_config/breakpoints';

import withSize from './../../../_packages/resize/ui/withSize';

import {PrimaryButton, SecondaryButton} from './../../atoms/Button/Button';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';

import './Slider.scss';

const getFlickityOptions = (infiniteLoop, groupCells) => ({
  adaptiveHeight: false,
  cellAlign: 'left',
  bgLazyLoad: 1,
  wrapAround: infiniteLoop,
  prevNextButtons: false,
  pageDots: false,
  cellSelector: '.slider__card',
  freeScroll: false,
  contain: false,
  groupCells: groupCells,
});

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFullscreen: false,
    };

    this.myCustomNext = this.myCustomNext.bind(this);
    this.myCustomPrevious = this.myCustomPrevious.bind(this);
    this.getSlidesWidth = this.getSlidesWidth.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  myCustomNext() {
    this.flkty.next();
  }

  myCustomPrevious() {
    this.flkty.previous();
  }

  getSlidesWidth(windowWidth, slidesPerBreakpoint) {
    return `${100 / valuePerBreakpointForConfig(slidesPerBreakpoint)(windowWidth)}%`;
  }

  toggleFullscreen(index) {
    const {isFullscreen} = this.state;
    this.setState({isFullscreen: !isFullscreen}, () => {
      this.flkty.select(index);
    });
  }

  render() {
    const {isFullscreen} = this.state;
    const {
      allowFullScreen,
      children,
      gallery,
      noMargin,
      slidesPerBreakpoint,
      windowWidth,
      withButtons,
      infiniteLoop,
      invertedButtons,
      intl,
      horizontalCards,
      groupCells,
    } = this.props;
    const slideWidth =
      gallery && windowWidth >= BREAKPOINTS.XL ? 'max-content' : this.getSlidesWidth(windowWidth, slidesPerBreakpoint);

    const ButtonComponent = invertedButtons ? SecondaryButton : PrimaryButton;
    const carouselClassName = this.state.isFullscreen ? 'slider__carousel is-fullscreen' : 'slider__carousel';
    const buttonsAreDisabled =
      !gallery && children.length <= valuePerBreakpointForConfig(slidesPerBreakpoint)(windowWidth);

    return (
      <div className={classNames('slider', {'slider--with-horizontal-cards': horizontalCards})}>
        {((withButtons && children.length > valuePerBreakpointForConfig(slidesPerBreakpoint)(windowWidth)) ||
          windowWidth < BREAKPOINTS.XXL ||
          (gallery && children.length > 1)) && (
          <div className={classNames('slider__buttons', {'slider__buttons--inverted': invertedButtons})}>
            <ButtonComponent
              aria-label={intl.formatMessage({id: 'slider.button.previous'})}
              className="slider__button-previous"
              disabled={buttonsAreDisabled}
              onClick={this.myCustomPrevious}
            >
              <Iconography className="slider__button-previous-icon" icon={ICON.UP_ARROW} />
            </ButtonComponent>
            <ButtonComponent
              aria-label={intl.formatMessage({id: 'slider.button.next'})}
              className="slider__button-next"
              disabled={buttonsAreDisabled}
              onClick={this.myCustomNext}
            >
              <Iconography className="slider__button-next-icon" icon={ICON.UP_ARROW} />
            </ButtonComponent>
          </div>
        )}
        <Flickity
          className={carouselClassName}
          disableImagesLoaded={false}
          elementType={'div'}
          flickityRef={c => (this.flkty = c)}
          options={getFlickityOptions(infiniteLoop, groupCells)}
          reloadOnUpdate
          static
        >
          {React.Children.map(children, (child, index) => (
            <div
              className={classNames('slider__card', {'slider__card--no-margin': noMargin})}
              key={index}
              style={{width: isFullscreen ? '100%' : slideWidth}}
            >
              {child}
              {allowFullScreen && windowWidth >= BREAKPOINTS.XXL && (
                <SecondaryButton
                  aria-label={intl.formatMessage({
                    id: isFullscreen ? 'slider.button.fullscreen_close' : 'slider.button.fullscreen_open',
                  })}
                  className={classNames('slider__fullscreen', {'slider__fullscreen--active': isFullscreen})}
                  onClick={() => this.toggleFullscreen(index)}
                >
                  <Iconography icon={isFullscreen ? ICON.FULLSCREEN_CLOSE : ICON.FULLSCREEN} />
                </SecondaryButton>
              )}
            </div>
          ))}
          {allowFullScreen && windowWidth < BREAKPOINTS.XXL && (
            <SecondaryButton
              aria-label={intl.formatMessage({
                id: isFullscreen ? 'slider.button.fullscreen_close' : 'slider.button.fullscreen_open',
              })}
              className={classNames('slider__fullscreen', {'slider__fullscreen--active': isFullscreen})}
              onClick={() => this.toggleFullscreen(this.flkty.selectedIndex)}
            >
              <Iconography icon={isFullscreen ? ICON.FULLSCREEN_CLOSE : ICON.FULLSCREEN} />
            </SecondaryButton>
          )}
        </Flickity>
      </div>
    );
  }
}

Slider.propTypes = {
  allowFullScreen: PropTypes.bool,
  gallery: PropTypes.bool,
  groupCells: PropTypes.oneOf([PropTypes.number, PropTypes.bool]),
  horizontalCards: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  invertedButtons: PropTypes.bool,
  noMargin: PropTypes.bool,
  slidesPerBreakpoint: PropTypes.object,
  withButtons: PropTypes.bool,
};

Slider.defaultProps = {
  allowFullScreen: false,
  gallery: false,
  horizontalCards: false,
  infiniteLoop: false,
  invertedButtons: false,
  noMargin: false,
  slidesPerBreakpoint: {S: 1, L: 2, XXXXL: 3},
  withButtons: true,
};

export default compose(withSize, injectIntl, React.memo)(Slider);
