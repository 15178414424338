export const TECHNOLOGIES = 'technologies';
export const TECHNOLOGY = 'technology';

export const technologiesRouteParams = {
  [TECHNOLOGIES]: {
    shouldPartiallyRender: true,
  },
  [TECHNOLOGY]: {
    shouldPartiallyRender: false,
  },
};

export default {
  [TECHNOLOGY]: {
    es: '/tecnologias/:slug',
    en: '/technologies/:slug',
    eu: '/teknologiak/:slug',
    fr: '/technologies/:slug',
  },
  [TECHNOLOGIES]: {
    es: '/tecnologias',
    en: '/technologies',
    eu: '/teknologiak',
    fr: '/technologies',
  },
};
