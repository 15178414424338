import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';

import './TableOfContents.scss';
import Typography, {VARIANT} from '../../atoms/Typography/Typography';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

const handleScrollTo = (e, anchor) => {
  e.preventDefault();
  if (anchor) {
    const element = document.querySelector(anchor);
    if (element) {
      const elementClientRect = element.getBoundingClientRect().top;
      const offset = 150;

      const elementPosition = elementClientRect - offset;

      window.scrollBy({
        top: elementPosition,
        behavior: 'smooth',
      });
    }
  }
};

const TableOfContents = ({content, setContent, className}) => {
  const [tocItems, setTocItems] = useState([]);

  useEffect(() => {
    const htmlElem = document.createElement('div');
    htmlElem.innerHTML = content;
    const items = [...htmlElem.querySelectorAll('h2,h3')];

    const depth = {
      first: 0,
      second: 0,
    };

    const newTocItems = items.map(node => {
      if (node.tagName === 'H2') {
        depth.first = 0;
        node.id = `target-${++depth.first}`;
        return {
          title: `${depth.first}  ${node.innerText}`,
          type: node.tagName,
          target: node.id,
        };
      }

      node.id = `target-${depth.first}-${++depth.second}`;
      return {
        title: `${depth.first}.${depth.second}  ${node.innerText}`,
        type: node.tagName,
        target: node.id,
      };
    });

    setTocItems(newTocItems);
    setContent(htmlElem.innerHTML);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('table-of-contents', className)}>
      <Typography className={'table-of-contents__title'} variant={VARIANT.BODY_SMALL}>
        <FormattedMessage id="inspiringBlog.tableOfContent.title" />
      </Typography>
      {tocItems.length && (
        <ul className="table-of-content__content">
          {tocItems.map((item, k) => (
            <li key={`toc_idx_${k}`}>
              <a
                href={`#${item.target}`}
                onClick={e => {
                  handleScrollTo(e, `#${item.target}`);
                }}
              >
                <Typography
                  className={classNames('table-of-contents__item', `table-of-contents__item-type-${item.type}`)}
                  variant={VARIANT.BODY_SMALLER}
                >
                  {item.title}
                </Typography>
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

TableOfContents.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  setContent: PropTypes.func,
};

TableOfContents.defaultProps = {
  content: '',
  setContent: () => {},
};

export default compose(React.memo)(TableOfContents);
