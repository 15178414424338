import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingLogosGrid from './../../_bridges/RoutingLogosGrid/RoutingLogosGrid';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {INFRASTRUCTURE} from './../../../routing/infrastructures/routes';

import {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import FeaturedPreview from './../../components/FeaturedPreview/FeaturedPreview';
import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';

import Hero from './../../compositions/Hero/Hero';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import NewsAgendaModule from './../../compositions/NewsAgendaModule/NewsAgendaModule';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './Infrastructure.scss';

const Infrastructure = ({canonical, content: infrastructure, intl}) => (
  <div className="infrastructure">
    <Hero
      image={infrastructure.image}
      title={infrastructure.title}
      type={intl.formatMessage({id: 'infrastructures.category'})}
    />

    <MainLayout
      asideContent={
        <React.Fragment>
          {infrastructure.relatedContact.items.map((contact, index) => (
            <RoutingExpertModule
              expertPageSlug={contact.slug}
              formId={infrastructure.formId.id}
              formInfo={infrastructure.form.find(form => form.type === 'expert')}
              hidden={infrastructure.relatedContact.simplified}
              imgAlt={contact.title}
              imgSrc={contact.image.src.small}
              key={`relatedContact${index}`}
              name={contact.title}
              position={contact.position}
              templateTitle={infrastructure.title}
            />
          ))}

          {infrastructure.catalog &&
            infrastructure.catalog.items.map((catalog, index) => (
              <CatalogueDownload
                catalogueInfo={catalog}
                formId={infrastructure.formId.catalogId}
                formInfo={infrastructure.form.find(form => form.type === 'download')}
                key={`relatedCatalog${index}`}
              />
            ))}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <React.Fragment>
          <Content className="infrastructure__main-excerpt" raw variant={VARIANT.H3}>
            {infrastructure.featuredDescription.featuredDescription}
          </Content>

          {infrastructure.sectors.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={infrastructure.sectors.items}
              title={infrastructure.sectors.title}
            />
          )}

          {infrastructure.content && <CmsContent content={infrastructure.content} />}

          {infrastructure.relatedService.items.length > 0 && (
            <FeaturedPreview
              items={infrastructure.relatedService.items}
              maxItems={2}
              title={infrastructure.relatedService.title}
            />
          )}

          {infrastructure.relatedCertification.items.length > 0 && (
            <RoutingLogosGrid
              breakpoints={{S: 1, M: 2}}
              hasDescription
              logos={infrastructure.relatedCertification.items}
              maxLogos={6}
              title={infrastructure.relatedCertification.title}
              withBackground
            />
          )}

          {infrastructure.relatedPartner &&
            infrastructure.relatedPartner.map((partner, index) => (
              <RoutingLogosGrid
                breakpoints={{S: 2, M: 3, L: 6}}
                key={partner + index}
                logos={partner.items}
                maxLogos={6}
                title={partner.title}
              />
            ))}
        </React.Fragment>
      }
      socialText={infrastructure.title}
    />

    {infrastructure.technologies.items.length > 0 && (
      <RelatedContentCards title={infrastructure.technologies.title}>
        {infrastructure.technologies.items.map((item, index) => (
          <RoutingGenericPreview
            horizontal
            key={`relatedTechnologies${index}`}
            label={intl.formatMessage({id: 'technology.category'})}
            preview={item.fields.relatedPage}
            to={TECHNOLOGY}
          />
        ))}
      </RelatedContentCards>
    )}

    {infrastructure.relatedMediaGallery.elements &&
      infrastructure.relatedMediaGallery.elements.length > 0 &&
      infrastructure.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={infrastructure.relatedMediaGallery} />
      )}

    {((infrastructure.nextEvents && infrastructure.nextEvents.items.length > 0) ||
      infrastructure.relatedNews.items.length > 0) && (
      <NewsAgendaModule
        agendaItems={infrastructure.nextEvents && infrastructure.nextEvents.items}
        agendaTitle={infrastructure.nextEvents && infrastructure.nextEvents.title}
        newsCards={infrastructure.relatedNews.items}
        newsTitle={infrastructure.relatedNews.title}
      />
    )}

    {infrastructure.relatedInfrastructure.items.length > 0 && (
      <RelatedContentCards title={infrastructure.relatedInfrastructure.title} withBackground>
        {infrastructure.relatedInfrastructure.items.map((item, index) => (
          <RoutingGenericPreview
            horizontal
            key={`relatedInfrastructure${index}`}
            label={intl.formatMessage({id: 'infrastructures.title'})}
            preview={item}
            to={INFRASTRUCTURE}
          />
        ))}
      </RelatedContentCards>
    )}
  </div>
);

export default compose(injectIntl, React.memo)(Infrastructure);
