import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import technologyPropType from './../../_propTypes/technologyPropType';

import {LABORATORY_SERVICE} from './../../../routing/laboratoryServices/routes';
import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {AMBIT} from './../../../routing/ambits/routes';
import {PROJECT} from './../../../routing/projects/routes';
import {INFRASTRUCTURE} from './../../../routing/infrastructures/routes';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingLogosGrid from './../../_bridges/RoutingLogosGrid/RoutingLogosGrid';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';
import RoutingInspStoryPreview from './../../_bridges/RoutingInspiringStoryPreview/RoutingInspiringStoryPreview';

import {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';

import Hero from './../../compositions/Hero/Hero';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import RelatedServices from './../../compositions/RelatedServices/RelatedServices';
import NewsAgendaModule from './../../compositions/NewsAgendaModule/NewsAgendaModule';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './Technology.scss';

const Technology = ({canonical, content: technology, intl}) => (
  <div className="technology">
    <Hero
      content={
        technology.ambits &&
        technology.ambits.items.length > 0 && (
          <RoutingRelatedTags
            breakpoints={{S: 2, XXXXL: 3}}
            tags={technology.ambits.items}
            title={technology.ambits.title}
            to={AMBIT}
          />
        )
      }
      image={technology.image}
      title={technology.title}
      type={intl.formatMessage({id: 'technology.category'})}
    />
    <MainLayout
      asideContent={
        <React.Fragment>
          {technology.relatedContact.items.map((contact, index) => (
            <RoutingExpertModule
              expertPageSlug={contact.slug}
              formId={technology.formId.id}
              formInfo={technology.form.find(form => form.type === 'expert')}
              hidden={technology.relatedContact.simplified}
              imgAlt={contact.title}
              imgSrc={contact.image.src.small}
              key={index}
              name={contact.title}
              position={contact.position}
              templateTitle={technology.title}
            />
          ))}
          {technology.catalog.items.map((catalog, index) => (
            <CatalogueDownload
              catalogueInfo={catalog}
              formId={technology.formId.catalogId}
              formInfo={technology.form.find(form => form.type === 'download')}
              key={index}
            />
          ))}
        </React.Fragment>
      }
      canonical={canonical}
      mainContent={
        <React.Fragment>
          <Content className="technology__main-excerpt" raw variant={VARIANT.H3}>
            {technology.detailedExcerpt}
          </Content>

          {technology.sectors.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={technology.sectors.items}
              title={technology.sectors.title}
            />
          )}

          {technology.content && <CmsContent content={technology.content} />}
          {technology.relatedPartnerGroups &&
            technology.relatedPartnerGroups.map((partnerGroup, index) => (
              <RoutingLogosGrid
                breakpoints={{S: 2, M: 3, L: 6}}
                key={index}
                logos={partnerGroup.items}
                maxLogos={6}
                title={partnerGroup.title}
              />
            ))}
        </React.Fragment>
      }
      socialText={technology.title}
    />
    {technology.relatedProject.items.length > 0 && (
      <RelatedContentCards slidesPerBreakpoint={{S: 1, L: 2, XXL: 3, XXXXL: 4}} title={technology.relatedProject.title}>
        {technology.relatedProject.items.map((project, index) => (
          <RoutingGenericPreview
            key={index}
            label={intl.formatMessage({id: 'project.category'})}
            preview={project}
            to={PROJECT}
          />
        ))}
      </RelatedContentCards>
    )}
    {technology.relatedBusinessOpportunity.items.length > 0 && (
      <RelatedServices
        cards={technology.relatedBusinessOpportunity.items}
        title={technology.relatedBusinessOpportunity.title}
      />
    )}
    {technology.relatedService.items.length > 0 && (
      <RelatedContentCards title={technology.relatedService.title}>
        {technology.relatedService.items.map((service, index) => (
          <RoutingGenericPreview
            horizontal
            key={index}
            label={intl.formatMessage({id: 'service.category'})}
            preview={service}
            to={LABORATORY_SERVICE}
          />
        ))}
      </RelatedContentCards>
    )}

    {technology.relatedInfrastructure.items.length > 0 && (
      <RelatedContentCards title={technology.relatedInfrastructure.title}>
        {technology.relatedInfrastructure.items.map((item, index) => (
          <RoutingGenericPreview
            horizontal
            key={`relatedInfrastructure${index}`}
            label={intl.formatMessage({id: 'infrastructures.category'})}
            preview={item}
            to={INFRASTRUCTURE}
          />
        ))}
      </RelatedContentCards>
    )}

    {technology.relatedInspiringStory.items.length > 0 && (
      <RelatedContentCards title={technology.relatedInspiringStory.title} titleTag="h4">
        {technology.relatedInspiringStory.items.map((item, index) => (
          <RoutingInspStoryPreview
            inspiringStory={item}
            key={index}
            label={intl.formatMessage({id: 'inspiringStories.category'})}
          />
        ))}
      </RelatedContentCards>
    )}
    {technology.relatedMediaGallery.elements &&
      technology.relatedMediaGallery.elements.length > 0 &&
      technology.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={technology.relatedMediaGallery} />
      )}

    {(technology.nextEvents.items.length > 0 || technology.relatedNews.items.length > 0) && (
      <NewsAgendaModule
        agendaItems={technology.nextEvents.items}
        agendaTitle={technology.nextEvents.title}
        newsCards={technology.relatedNews.items}
        newsTitle={technology.relatedNews.title}
      />
    )}

    {technology.technologies.items.length > 0 && (
      <RelatedContentCards
        className="technology__related-technologies"
        slidesPerBreakpoint={{S: 1, XL: 2, XXXXXL: 3}}
        title={technology.technologies.title}
        withBackground
        withHorizontalCards
      >
        {technology.technologies.items.map((relatedTechnology, index) => (
          <RoutingGenericPreview
            horizontal
            key={index}
            label={intl.formatMessage({id: 'technology.category'})}
            preview={relatedTechnology.fields.relatedPage}
            to={TECHNOLOGY}
          />
        ))}
      </RelatedContentCards>
    )}
  </div>
);

Technology.propTypes = {
  canonical: PropTypes.string,
  content: technologyPropType,
};

export default compose(injectIntl, React.memo)(Technology);
