import React, {useState} from 'react';
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import classNames from 'classnames';

import RoutingCmsCard from './../../../_bridges/RoutingCmsCard/RoutingCmsCard';
import RoutingFeaturedLink from './../../../_bridges/RoutingFeaturedLink/RoutingFeaturedLink';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../../atoms/Iconography/Iconography.js';
import Content from './../../../atoms/Content/Content';

import CmsCtaText from './../CmsCtaText/CmsCtaText';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';
import Grid from './../../../layouts/Grid/Grid';

import './CmsAccordion.scss';

const getGridItems = items =>
  items.map((item, index) => {
    switch (item.type) {
      case 'grid_small_card_block': {
        return <CmsCtaText content={item.values} key={index} />;
      }
      case 'grid_file_single_field': {
        return (
          <RoutingFeaturedLink
            icon={ICON.DOWNLOAD}
            key={index}
            scrollAnimation={false}
            slug={item.values.file}
            title={item.values.text}
            uppercaseTitle
          />
        );
      }
      case 'grid_card_block': {
        return <RoutingCmsCard card={item.values} key={index} />;
      }
      case 'grid_full_content': {
        return <Content children={item.text} raw />;
      }
      default:
        return null;
    }
  });

const CmsAccordion = ({content, titleVariant}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  return (
    <Row className="cms-accordion">
      {content.title && (
        <Column className="cms-accordion__title">
          <Typography variant={VARIANT.HEADING}>{content.title}</Typography>
        </Column>
      )}
      <Column className="cms-accordion__column">
        <ReactAccordion allowZeroExpanded>
          {content.items.map((item, index) => {
            const {values} = item;
            return (
              <AccordionItem
                className={classNames('cms-accordion__item', {
                  'cms-accordion__item--selected': index === selectedIndex,
                })}
                key={index}
                onClick={() => setSelectedIndex(index === selectedIndex ? null : index)}
              >
                <div className="cms-accordion__item-heading">
                  <AccordionItemHeading aria-level="1" className="cms-accordion__item-title">
                    <AccordionItemButton className="cms-accordion__item-title-button">
                      <Typography variant={titleVariant}>{values.title}</Typography>
                      <Iconography className="cms-accordion__toggle-icon" icon={ICON.CLOSE} />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                </div>
                <AccordionItemState>
                  {({expanded}) => (
                    <AccordionItemPanel
                      className={classNames('cms-accordion__body', {
                        'cms-accordion__body--expanded': expanded,
                      })}
                    >
                      <div className="cms-accordion__body-wrapper">
                        {values.items.map((accordionItem, itemIndex) => {
                          const {values: itemValues} = accordionItem;
                          return (
                            <Grid
                              columnsPerBreakpoint={{
                                S: 1,
                                L: itemValues.breakpoints.tablet || 2,
                                XXXL: itemValues.breakpoints.desktop,
                              }}
                              key={itemIndex}
                              smallerTitle
                              title={itemValues.title}
                            >
                              {getGridItems(itemValues.items)}
                            </Grid>
                          );
                        })}
                      </div>
                    </AccordionItemPanel>
                  )}
                </AccordionItemState>
              </AccordionItem>
            );
          })}
        </ReactAccordion>
      </Column>
    </Row>
  );
};

CmsAccordion.defaultProps = {
  titleVariant: VARIANT.H4,
};

export default React.memo(CmsAccordion);
