import React, {useState} from 'react';
import {injectIntl} from 'react-intl';

import sideExpertProptype from './../../_propTypes/sideExpertProptype';

import {PrimaryButton} from './../../atoms/Button/Button';
import Picture, {RATIO} from './../../atoms/Picture/Picture';
import Modal from './../../atoms/Modal/Modal';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Form from './../Forms/Form';

import FullRow from './../../layouts/FullRow/FullRow';
import Column from './../../layouts/Column/Column';

import './ExpertModule.scss';

const ExpertModule = ({
  imgAlt,
  imgSrc,
  name,
  hidden,
  position,
  renderContainer,
  formInfo,
  formId,
  templateTitle,
  titleTag,
  scrollAnimation,
  isInspiringBlogAuthor,
  intl,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FullRow className="expert-module" scrollAnimation={scrollAnimation} tag="article">
      {!hidden &&
        !isInspiringBlogAuthor &&
        renderContainer(
          <React.Fragment>
            <Picture alt={imgAlt} className="expert-module__image" cover ratio={RATIO.R_1_1} src={imgSrc} />
            <Typography className="expert-module__name" tag={titleTag || 'h1'} variant={VARIANT.BODY}>
              {name}
            </Typography>
            <Typography className="expert-module__description" tag="div" variant={VARIANT.CONTACT}>
              {position}
            </Typography>
          </React.Fragment>,
        )}
      {isInspiringBlogAuthor && (
        <>
          <Typography className="expert-module__name" tag={titleTag || 'h1'} variant={VARIANT.BODY}>
            {intl.formatMessage({id: 'inspiringBlog.author'})}
          </Typography>
          <Typography className="expert-module__name" tag={titleTag || 'h1'} variant={VARIANT.BODY}>
            {name}
          </Typography>
        </>
      )}
      {formInfo && (
        <PrimaryButton className="expert-module__button" onClick={() => setIsOpen(true)}>
          <Typography variant={VARIANT.BODY_SMALL}>{formInfo.buttonTitle}</Typography>
        </PrimaryButton>
      )}
      {formId && formInfo && (
        <FullRow>
          <Column>
            <Modal
              className="expert-module__modal"
              fullHeight={true}
              isOpen={isOpen}
              onCloseClick={() => setIsOpen(false)}
            >
              <Form formId={formId} formInfo={formInfo} title={templateTitle} />
            </Modal>
          </Column>
        </FullRow>
      )}
    </FullRow>
  );
};

ExpertModule.propTypes = sideExpertProptype;

ExpertModule.defaultProps = {
  hidden: false,
  renderContainer: children => children,
  scrollAnimation: true,
  shouldShowImage: false,
};

export default React.memo(injectIntl(ExpertModule));
