import React from 'react';

import Video from './../../../atoms/Video/Video';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';

import './CmsVideo.scss';

const CmsVideo = ({scrollAnimation, video}) => (
  <Row scrollAnimation={scrollAnimation}>
    <Column className="cms-video">
      <div className="cms-video__wrapper">
        <Video src={`https://www.youtube.com/embed/${video}?autoplay=1`} />
      </div>
    </Column>
  </Row>
);

export default React.memo(CmsVideo);
