import React from 'react';
import PropTypes from 'prop-types';

import breadcrumbsPropType from './../../_propTypes/breadcrumbsPropType';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import './BreadcrumbItems.css';

const BreadcrumbItem = ({text}) => {
  const breadcrumbText = text.length > 20 ? `${text.slice(0, 20)}...` : text;
  return (
    <Typography className="breadcrumb-item" variant={VARIANT.BREADCRUMB}>
      {breadcrumbText}
    </Typography>
  );
};

const BreadcrumbItems = ({breadcrumbs, renderContainer}) =>
  breadcrumbs ? (
    breadcrumbs
      .map((breadcrumb, index) => renderContainer(<BreadcrumbItem key={index} text={breadcrumb.title} />, index))
      .reduce(
        (prev, curr, idx, arr) =>
          prev === null ? [curr] : [...prev, <BreadcrumbItem key={arr.length + idx} text="&frasl;" />, curr],
        null,
      )
  ) : (
    <div />
  );

BreadcrumbItems.propTypes = {
  breadcrumbs: breadcrumbsPropType.isRequired,
  renderContainer: PropTypes.func,
};

BreadcrumbItems.defaultProps = {
  renderContainer: children => children,
};

export default React.memo(BreadcrumbItems);
