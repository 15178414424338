import {TwitterAccount} from './../../config/constants';
import {ICON} from './../atoms/Iconography/Iconography';

const fbBaseUrl = 'https://www.facebook.com/sharer/sharer.php';
const twBaseUrl = 'https://twitter.com/intent/tweet';
const inBaseUrl = 'https://www.linkedin.com/sharing/share-offsite';
const whatsAppBaseUrl = 'https://wa.me/';
const mailBaseUrl = 'mailto:';

export const socialShareList = ({text, url}) => [
  {slug: getINShareUrl({url}), icon: ICON.LINKEDIN, name: 'Linkedin'},
  {slug: getTWShareUrl({text, url}), icon: ICON.TWITTER, name: 'Twitter'},
  {slug: getFBShareUrl({url}), icon: ICON.FACEBOOK, name: 'Facebook'},
  {slug: getWhatsAppShareUrl({text, url}), icon: ICON.WHATSAPP, name: 'WhatsApp'},
];

export const getINShareUrl = ({url}) => `${inBaseUrl}?&url=${url}`;
export const getTWShareUrl = ({text, url}) => `${twBaseUrl}?&via=${TwitterAccount}&text=${text}&url=${url}`;
export const getFBShareUrl = ({url}) => `${fbBaseUrl}?u=${encodeURI(url)}&src=share_button`;
export const getWhatsAppShareUrl = ({text, url}) => `${whatsAppBaseUrl}?&text=${text} ${encodeURI(url)}`;
export const getMailShareUrl = ({body, subject, url}) =>
  `${mailBaseUrl}?subject=${subject}&body=${encodeURIComponent(body)}%20${encodeURI(url)}`;
