import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Picture, {RATIO} from './../../../atoms/Picture/Picture';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';

import TitleTextComponent from './../../../components/TitleTextComponent/TitleTextComponent';

import './CmsCard.scss';

const CmsCard = ({className, card, renderLink, reverse, imageFill}) => {
  const {image, title, label, linkedin, twitter, cta, orientation} = card;
  return (
    <div className={classNames('cms-card__wrapper', className)}>
      <article
        className={classNames(
          'cms-card',
          {
            'cms-card--horizontal': orientation === 'landscape',
          },
          {'cms-card--reverse': reverse},
        )}
      >
        {image && (
          <div className="cms-card__picture">
            <Picture
              alt={image.alt || image.title}
              className="cms-card__picture-image"
              contain={imageFill === 'contain'}
              cover={imageFill === 'cover'}
              ratio={RATIO.R_3_2}
              src={image.src.medium}
              srcSet={[
                {
                  src: image.src.small,
                  maxWidth: 480,
                },
              ]}
            />
          </div>
        )}

        <div className="cms-card__content">
          {(title || label) && <TitleTextComponent text={title} title={label} />}
          {(linkedin || twitter) && (
            <div className="cms-card__content-social">
              {linkedin &&
                renderLink(<Iconography className="cms-card__content-social-icon" icon={ICON.LINKEDIN} />, linkedin)}
              {twitter &&
                renderLink(<Iconography className="cms-card__content-social-icon" icon={ICON.TWITTER} />, twitter)}
            </div>
          )}
          {cta &&
            renderLink(
              <Typography className="cms-card__content-link" variant={VARIANT.BODY_SMALL}>
                {cta.ctaText}
              </Typography>,
              cta.ctaUrl,
            )}
        </div>
      </article>
    </div>
  );
};

CmsCard.propTypes = {
  card: PropTypes.object,
  className: PropTypes.string,
  renderLink: PropTypes.func,
  reverse: PropTypes.bool,
};

CmsCard.defaultProps = {
  renderLink: children => children,
  reverse: false,
};

export default React.memo(CmsCard);
