import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Loader from './../../atoms/Loader/Loader';
import Picture from './../../atoms/Picture/Picture';

import './Form.scss';

const Form = ({catalog, formExtraInfo, formInfo, formId, formUrl, fullHeight, title, type, withTitle}) => {
  const iframew = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const getFormUrl = () => {
    const formType = formInfo ? formInfo.type : type;
    switch (formType) {
      case 'newsletter':
      case 'contact':
      case 'general':
        return `${formInfo.url}?Titulo=N/A&Ubicacion=N/A&Codigo_campanya=N/A&Clave_web=CON001`;
      case 'download':
        return (
          `${formInfo.url}?Clave_web=${formId}` +
          `&Catalogo=${catalog.document.file.url}&Titulo=${catalog.title}&Ubicacion=N/A&Codigo_campanya=N/A`
        );
      case 'expert':
        return `${formInfo.url}?Clave_web=${formId}&Titulo=${title}&Ubicacion=N/A&Codigo_campanya=N/A`;
      case 'budget':
        return `${formInfo.url}?Clave_web=${formId}&Titulo=N/A&Ubicacion=N/A&Codigo_campanya=N/A`;
      case 'footer_contact':
      case 'footer_newsletter':
        return `${formUrl}?Titulo=N/A&Ubicacion=N/A&Codigo_campanya=N/A&Clave_web=CON001`;
      case 'event':
        return (
          `${formInfo.url}?Clave_web=${formId.id}` +
          `&Codigo_campanya=${formId.campaignId}&` +
          `Titulo=${formExtraInfo.title}&Ubicacion=${
            formExtraInfo.place.place ? formExtraInfo.place.place : formExtraInfo.place.delegation.title
          }`
        );
      default:
        return formInfo.url;
    }
  };

  function captureLoad() {
    setIsLoading(false);
    const iframeWindow = iframew.current.contentWindow;
    const stylesheets = Object.values(document.querySelectorAll('link[rel=stylesheet]')).map(e => e.href);
    iframeWindow.postMessage(JSON.stringify(stylesheets), '*');

    window.addEventListener('message', evt => {
      if (evt.origin === 'https://www2.e.tecnalia.com' && fullHeight) {
        var iFrameID = document.getElementById(`form-full-height-${formId}`);

        if (iFrameID) {
          iFrameID.height = '';
          iFrameID.height = `${evt.data + 40}px`;
        }
      }
    });
  }

  return (
    <div className={classNames('form', {'form--catalog': catalog, 'form--full-height': fullHeight})}>
      {catalog && (
        <div className="form__image">
          <Picture alt={catalog.image.alt} className="catalogue-download__image" src={catalog.image.src.small} />
        </div>
      )}
      <div className="form__content">
        {withTitle && (
          <Typography className="form__title" variant={VARIANT.HEADING}>
            {formInfo ? formInfo.title : title}
          </Typography>
        )}
        {isLoading === true && <Loader />}
        <iframe
          frameBorder="0"
          height="100%"
          id={fullHeight ? `form-full-height-${formId}` : ''}
          // id={formInfo ? formInfo.type : type}
          onLoad={captureLoad}
          ref={iframew}
          src={getFormUrl()}
          title="Form"
          type="text/html"
          width="100%"
        />
      </div>
    </div>
  );
};

Form.propTypes = {
  catalog: PropTypes.object,
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  formInfo: PropTypes.object,
  formUrl: PropTypes.string,
  fullHeight: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  withTitle: PropTypes.bool.isRequired,
};

Form.defaultProps = {
  withTitle: true,
  fullHeight: false,
};

export default Form;
