import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Column.scss';

const Column = ({children, className, tag: ColumnTag, ...rest}) => (
  <ColumnTag className={classNames('column', className)} {...rest}>
    {children}
  </ColumnTag>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tag: PropTypes.string,
};

Column.defaultProps = {
  tag: 'div',
};

export default React.memo(Column);
