import React from 'react';
import PropTypes from 'prop-types';

import titleTextPropType from './../../_propTypes/titleTextPropType';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import Grid from './../../layouts/Grid/Grid';

import './ContentGrid.scss';

const ContentGrid = ({items, title}) => (
  <Grid columnsPerBreakpoint={{S: 1, L: 2}} itemClassName="content-grid__item" scrollAnimation={false} title={title}>
    {items.map((item, index) => (
      <React.Fragment key={index}>
        {item.title && (
          <Typography className="content-grid__title" tag="h3" variant={VARIANT.NUMBER_SMALL}>
            {item.title}
          </Typography>
        )}
        <Content raw variant={VARIANT.BODY}>
          {item.text}
        </Content>
      </React.Fragment>
    ))}
  </Grid>
);

ContentGrid.propTypes = {
  items: PropTypes.arrayOf(titleTextPropType),
  title: PropTypes.string,
};

export default React.memo(ContentGrid);
