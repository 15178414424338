import React, {useState} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';
import {SecondaryButton} from './../../../atoms/Button/Button';

import CmsLink from './../../../components/CmsLink/CmsLink';

import './PrefooterMobile.scss';

const PrefooterMobile = ({prefooter}) => {
  const [isVisible, setIsvisible] = useState(false);

  return (
    <div className="prefooter-mobile">
      <SecondaryButton className="prefooter-mobile__title-container" onClick={() => setIsvisible(!isVisible)}>
        <div className="prefooter-mobile__title">
          <Typography variant={VARIANT.HEADING_SMALL}>{prefooter.options.title}</Typography>
          <Iconography className="prefooter-mobile__toggle-icon" icon={isVisible ? ICON.LESS : ICON.PLUS} />
        </div>
      </SecondaryButton>
      {isVisible && (
        <div className="prefooter-mobile__menu">
          <div className="prefooter-mobile__menu-col">
            {prefooter.items.map((menuLink, index) => (
              <CmsLink className="prefooter-mobile__menu-item" key={index} slug={menuLink.slug}>
                <Typography variant={VARIANT.MENU}>{menuLink.title}</Typography>
              </CmsLink>
            ))}
          </div>
        </div>
      )}
      <AnchorLink className="prefooter-mobile__anchor" href="#header" offset="">
        <Iconography icon={ICON.UP_ARROW} />
      </AnchorLink>
    </div>
  );
};

export default React.memo(PrefooterMobile);
