import React from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';

import {AMBIT} from './../../../routing/ambits/routes';
// import {SECTOR} from './../../../routing/sectors/routes';
import {TECHNOLOGY} from './../../../routing/technologies/routes';
import {PROJECT} from './../../../routing/projects/routes';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';
import RoutingExpertModule from './../../_bridges/RoutingExpertModule/RoutingExpertModule';
import RoutingLogosGrid from './../../_bridges/RoutingLogosGrid/RoutingLogosGrid';
import RoutingGenericPreview from './../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import {VARIANT} from './../../atoms/Typography/Typography';
import Content from './../../atoms/Content/Content';

import CatalogueDownload from './../../components/CatalogueDownload/CatalogueDownload';

import Hero from './../../compositions/Hero/Hero';
import RelatedContentCards from './../../compositions/RelatedContentCards/RelatedContentCards';
import MediaGallery from './../../compositions/MediaGallery/MediaGallery';
import NewsAgendaModule from './../../compositions/NewsAgendaModule/NewsAgendaModule';

import MainLayout from './../../layouts/MainLayout/MainLayout';
import CmsContent from './../../layouts/CmsContent/CmsContent';

import './Project.css';

const Project = ({content: project, intl}) => (
  <div className="project">
    <Hero
      content={
        <div className="project__hero-main-content">
          {project.ambits && project.ambits.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XXXXL: 3}}
              tags={project.ambits.items}
              title={project.ambits.title}
              to={AMBIT}
            />
          )}
        </div>
      }
      image={project.image}
      title={project.title}
      type={intl.formatMessage({id: 'project.title'})}
    />
    <MainLayout
      asideContent={
        <React.Fragment>
          {project.relatedContact.items.map((contact, index) => (
            <RoutingExpertModule
              expertPageSlug={contact.slug}
              formId={project.formId.id}
              formInfo={project.form.find((form) => form.type === 'expert')}
              hidden={project.relatedContact.simplified}
              imgAlt={contact.title}
              imgSrc={contact.image.src.small}
              key={`relatedContact${index}`}
              name={contact.title}
              position={contact.position}
              templateTitle={project.title}
            />
          ))}
          {project.relatedPublication.items.map((catalog, index) => (
            <CatalogueDownload
              catalogueInfo={catalog}
              formId={project.formId.catalogId}
              formInfo={project.form.find((form) => form.type === 'download')}
              key={index}
            />
          ))}
        </React.Fragment>
      }
      mainContent={
        <React.Fragment>
          {project.featuredDescription.featuredDescription && (
            <Content className="technology__main-excerpt" raw variant={VARIANT.H3}>
              {project.featuredDescription.featuredDescription}
            </Content>
          )}
          {project.sectors && project.sectors.items.length > 0 && (
            <RoutingRelatedTags
              breakpoints={{S: 2, XL: 3, XXXL: 4}}
              tags={project.sectors.items}
              title={project.sectors.title}
              // to={SECTOR}
            />
          )}

          {project.content && <CmsContent content={project.content} />}

          {project.relatedPartner &&
            project.relatedPartner.length > 0 &&
            project.relatedPartner.map((item, index) => (
              <RoutingLogosGrid
                breakpoints={{S: 2, M: 3, L: 6}}
                key={index}
                logos={item.items}
                maxLogos={6}
                title={item.title}
              />
            ))}
        </React.Fragment>
      }
      socialText={project.title}
    />
    {project.technologies.items.length > 0 && (
      <RelatedContentCards title={project.technologies.title}>
        {project.technologies.items.map((relatedTechnology, index) => (
          <RoutingGenericPreview
            horizontal
            key={index}
            label={intl.formatMessage({id: 'technology.title'})}
            preview={relatedTechnology.fields.relatedPage}
            to={TECHNOLOGY}
          />
        ))}
      </RelatedContentCards>
    )}

    {project.relatedMediaGallery.elements &&
      project.relatedMediaGallery.elements.length > 0 &&
      project.relatedMediaGallery.elements[0].mediaGallery.items.length > 0 && (
        <MediaGallery gallery={project.relatedMediaGallery} />
      )}

    {((project.nextEvents && project.nextEvents.items.length > 0) || project.relatedNews.items.length > 0) && (
      <NewsAgendaModule
        agendaItems={project.nextEvents && project.nextEvents.items}
        agendaTitle={project.nextEvents && project.nextEvents.title}
        newsCards={project.relatedNews.items}
        newsTitle={project.relatedNews.title}
        slidesPerBreakpoint={{S: 1, L: 3, XL: 4, XXL: 5}}
      />
    )}

    {project.relatedProject.items.length > 0 && (
      <RelatedContentCards
        slidesPerBreakpoint={{S: 1, L: 2, XXL: 3, XXXXL: 4}}
        title={intl.formatMessage({id: 'related.projects.title'})}
        withBackground
      >
        {project.relatedProject.items.map((relProject, index) => (
          <RoutingGenericPreview
            key={index}
            label={intl.formatMessage({id: 'project.category'})}
            preview={relProject}
            to={PROJECT}
          />
        ))}
      </RelatedContentCards>
    )}
  </div>
);
export default compose(injectIntl, React.memo)(Project);
