import React from 'react';

import ExpertModule from './../../components/ExpertModule/ExpertModule';

const RoutingExpertModule = ({...rest}) => (
  <ExpertModule
    /* renderContainer={children => (
      <FormattedLink name={RESEARCHER} params={{slug: expertPageSlug}}>
        {children}
      </FormattedLink>
    )} */
    {...rest}
  />
);

export default React.memo(RoutingExpertModule);
