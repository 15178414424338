import React from 'react';
import PropTypes from 'prop-types';

import relatedTagsPropType from '../../_propTypes/relatedTagsPropType';
import {FormattedLink} from '@foes/react-i18n-routing';

import RelatedTags from '../../components/RelatedTags/RelatedTags';

const RoutingRelatedTagsCategory = ({tags, to, ...rest}) => (
  <RelatedTags
    hasLink={to}
    renderContainer={(children, index) =>
      tags[index] && to ? (
        <FormattedLink key={index} name={to} params={{slug: tags[index].slug}}>
          {children}
        </FormattedLink>
      ) : (
        React.cloneElement(children, {key: index})
      )
    }
    tags={tags}
    {...rest}
  />
);

RoutingRelatedTagsCategory.propTypes = {
  tags: relatedTagsPropType.isRequired,
  to: PropTypes.string,
};

export default React.memo(RoutingRelatedTagsCategory);
