import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'redux';

import withSize from './../../../_packages/resize/ui/withSize';
import {valuePerBreakpointForConfig} from './../../_config/breakpoints';
import prefixStyles from './../../_util/prefixStyles';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Row from './../Row/Row';
import Column from './../Column/Column';

import './Grid.scss';

const Grid = ({
  children,
  className,
  columnsPerBreakpoint,
  columnTag,
  isRow,
  itemClassName,
  windowWidth,
  scrollAnimation,
  smallerTitle,
  title,
  ...rest
}) => {
  const columnsPerView = valuePerBreakpointForConfig(columnsPerBreakpoint)(windowWidth);
  const GridComponent = isRow ? Row : 'div';
  return (
    <GridComponent
      className={classNames('grid', {'grid--multi-column': columnsPerView > 1}, className)}
      scrollAnimation={scrollAnimation}
      {...rest}
    >
      {title && (
        <Column className="grid__title">
          <Typography variant={smallerTitle ? VARIANT.HEADING_SMALL : VARIANT.HEADING}>{title}</Typography>
        </Column>
      )}
      {React.Children.map(children, (item, index) => (
        <Column
          className={classNames('grid__item', itemClassName)}
          key={index}
          style={prefixStyles({
            flexBasis: `${(1 / columnsPerView) * 100}%`,
            maxWidth: `${(1 / columnsPerView) * 100}%`,
          })}
          tag={columnTag}
        >
          {item}
        </Column>
      ))}
    </GridComponent>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columnTag: PropTypes.string,
  columnsPerBreakpoint: PropTypes.object,
  isRow: PropTypes.bool,
  itemClassName: PropTypes.string,
  scrollAnimation: PropTypes.bool,
  smallerTitle: PropTypes.bool,
  title: PropTypes.string,
};

Grid.defaultProps = {
  columnsPerBreakpoint: {S: 1},
  isRow: true,
  scrollAnimation: true,
  smallerTitle: false,
};

export default compose(React.memo, withSize)(Grid);
