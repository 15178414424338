import React from 'react';
import classNames from 'classnames';
import {pushDataLayer} from 'lin3s-react-analytics';

import {pagePrintClick} from './../../../_util/dataLayerTransformation';

import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';
import {PrimaryButton} from './../../../atoms/Button/Button';

import './PrintButton.scss';

const PrintButton = ({isOpen}) => {
  const handlePrint = () => {
    window.print();
    pushDataLayer(pagePrintClick());
  };

  return (
    <PrimaryButton
      className={classNames('print-button__button', {'print-button__button--open-download': isOpen})}
      onClick={handlePrint}
    >
      <Iconography icon={ICON.DOWNLOAD} />
    </PrimaryButton>
  );
};

export default PrintButton;
