import React from 'react';
import classNames from 'classnames';

import {ICON} from './../../../atoms/Iconography/Iconography';

import RoutingCmsCard from './../../../_bridges/RoutingCmsCard/RoutingCmsCard';
import RoutingFeaturedLink from './../../../_bridges/RoutingFeaturedLink/RoutingFeaturedLink';

import CmsCtaText from './../CmsCtaText/CmsCtaText';
import CmsNumbers from './../CmsNumbers/CmsNumbers';

import Grid from './../../../layouts/Grid/Grid';

import './CmsGrid.scss';

const getGridElement = (element, index, imageFill) => {
  switch (element.type) {
    case 'grid_card_block':
      return <RoutingCmsCard card={element.values} imageFill={imageFill} key={index} />;
    case 'grid_file_single_field':
      return (
        <RoutingFeaturedLink
          icon={ICON.DOWNLOAD}
          key={index}
          scrollAnimation={false}
          slug={element.values.file}
          title={element.values.text}
          uppercaseTitle
        />
      );
    case 'grid_small_card_block':
      return <CmsCtaText content={element.values} key={index} />;
    case 'grid_numbers':
      return <CmsNumbers content={element.values} key={index} wrapWithSensor />;
    case 'grid_partner_group':
      return element.values.map((item, itemIndex) => (
        <RoutingCmsCard card={item} imageFill={imageFill} key={itemIndex} />
      ));
    default:
      return null;
  }
};

const CmsGrid = ({content, scrollAnimation}) => (
  <Grid
    className={classNames('cms-grid', `cms-grid--desktop-${content.breakpoints.desktop}`)}
    columnsPerBreakpoint={{S: 1, M: 2, XL: content.breakpoints.desktop}}
    itemClassName="cms-grid__item"
    scrollAnimation={scrollAnimation}
    title={content.title}
  >
    {content.items.map((element, index) => getGridElement(element, index, content.imageFill))}
  </Grid>
);
export default React.memo(CmsGrid);
