import React from 'react';
import {compose} from 'redux';
import {injectIntl} from 'react-intl';

import {getFullFormatDate} from '../../../_util/getDateFromString';

import Column from '../../../layouts/Column/Column';
import Picture from '../../../atoms/Picture/Picture';
import Typography, {VARIANT} from '../../../atoms/Typography/Typography';

import './Comment.scss';

const Comment = ({comment, className, intl}) => (
  <div className={'comment '.concat(className || '')}>
    <Column className="avatar">
      {comment.authorAvatarUrls && <Picture src={comment.authorAvatarUrls[Object.keys(comment.authorAvatarUrls)[0]]} />}
    </Column>
    <Column>
      <div className="author">
        <Typography variant={VARIANT.BODY_XSMALL}>{comment.authorName}</Typography>
      </div>
      <div className="date">
        <Typography variant={VARIANT.BODY_XSMALL_LOWERCASE}>{getFullFormatDate(comment.date, intl.locale)}</Typography>
      </div>
      <div className="content">
        <div dangerouslySetInnerHTML={{__html: comment.content.rendered}} />
      </div>
    </Column>
  </div>
);
export default compose(injectIntl)(Comment);
