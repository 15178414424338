import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Picture, {RATIO} from './../../../atoms/Picture/Picture';
import InlineButton from './../../../atoms/InlineButton/InlineButton';
import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Content from './../../../atoms/Content/Content';
import AnimatedInlineButton from './../../../atoms/AnimatedInlineButton/AnimatedInlineButton';

import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';

import './FeaturedPreviewCard.scss';

const FeaturedPreviewCard = ({
  image,
  renderContainer,
  renderFullContainer,
  intl,
  index,
  title,
  text,
  wholeCardIsLink,
}) => (
  <React.Fragment>
    {renderFullContainer(
      <Row className="featured-preview__item" scrollAnimation={false}>
        <Column className="featured-preview__image">
          <Picture
            alt={image.alt || image.title}
            className={classNames('featured-preview-card__picture-image', {
              'featured-preview-card__picture-image--link': wholeCardIsLink,
            })}
            cover
            ratio={RATIO.R_4_3}
            src={image.src.medium}
            srcSet={[
              {
                src: image.src.small,
                maxWidth: 480,
              },
            ]}
          />
        </Column>
        <Column className="featured-preview__content">
          <div className="featured-preview__content-text">
            <Typography variant={VARIANT.H4}>{title}</Typography>
            <Content raw>{text}</Content>
          </div>
          <div className="featured-preview__content-cta">
            {renderContainer(
              <InlineButton>
                <AnimatedInlineButton text={intl.formatMessage({id: 'button.know.more'})} />
              </InlineButton>,
              index,
            )}
          </div>
        </Column>
      </Row>,
    )}
  </React.Fragment>
);

export default React.memo(FeaturedPreviewCard);

FeaturedPreviewCard.propTypes = {
  renderContainer: PropTypes.func,
  renderFullContainer: PropTypes.func,
  wholeCardIsLink: PropTypes.bool,
};

FeaturedPreviewCard.defaultProps = {
  renderContainer: children => children,
  renderFullContainer: children => children,
  wholeCardIsLink: true,
};
