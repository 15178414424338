import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {compose} from 'redux';
import {Trail} from 'react-spring/renderprops.cjs.js';

import BREAKPOINTS from './../../_config/breakpoints';
import withSize from './../../../_packages/resize/ui/withSize';

import RoutingRelatedTags from './../../_bridges/RoutingRelatedTags/RoutingRelatedTags';

import InspiringStoryHeroQuote from './InspiringStoryHeroQuote/InspiringStoryHeroQuote';

import Picture, {RATIO} from './../../atoms/Picture/Picture';
import {SecondaryButton} from './../../atoms/Button/Button';
import Modal from './../../atoms/Modal/Modal';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../atoms/Iconography/Iconography';
import Video from './../../atoms/Video/Video';

import HeroCategoryTitle from './../../components/HeroCategoryTitle/HeroCategoryTitle';

import FullRow from './../../layouts/FullRow/FullRow';
import Breadcrumbs from './../../layouts/Breadcrumbs/Breadcrumbs';

import './InspiringStoryHero.scss';

const InspiringStoryHero = ({image, quote, title, type, intl, sectors, video, windowWidth}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FullRow className="inspiring-story-hero">
      <div className="inspiring-story-hero__image">
        <Breadcrumbs />
        <Trail
          config={{duration: 1500}}
          from={{opacity: 0, transform: 'translateY(100px)'}}
          items={[
            <HeroCategoryTitle key="inspirign-story-hero__title" title={title} type={type} />,
            <React.Fragment key="inspirign-story__video">
              {video && (
                <SecondaryButton className="inspiring-story-hero__video" onClick={() => setIsOpen(true)}>
                  {windowWidth < BREAKPOINTS.M ? (
                    <Iconography icon={ICON.PLAY} />
                  ) : (
                    <Typography variant={VARIANT.BODY_SMALL}>{intl.formatMessage({id: 'video.watch'})}</Typography>
                  )}
                </SecondaryButton>
              )}
            </React.Fragment>,
            <div className="inspiring-story-hero__tags" key="inspirign-story__tags">
              {sectors.items.length > 0 && (
                <RoutingRelatedTags
                  breakpoints={{S: 2}}
                  tags={sectors.items}
                  title={sectors.title}
                  withBorder={false}
                />
              )}
            </div>,
          ]}
          keys={item => item.key}
          to={{opacity: 1, transform: 'translateY(0)'}}
        >
          {item => props => <div style={props}>{item}</div>}
        </Trail>
        <Picture
          alt={image.alt}
          className="inspiring-story-hero__picture"
          cover
          ratio={RATIO.R_16_9}
          src={image.src.original}
          srcSet={[
            {src: image.src.small, maxWidth: 479},
            {src: image.src.medium, maxWidth: 639},
            {src: image.src.large, maxWidth: 1279},
          ]}
        />
      </div>
      <InspiringStoryHeroQuote quote={quote} />
      <Modal className="inspiring-story-hero__modal" isOpen={isOpen} onCloseClick={() => setIsOpen(false)}>
        <div className="inspiring-story-hero__modal-video">
          <Video src={`https://www.youtube.com/embed/${video}?autoplay=1`} />
        </div>
      </Modal>
    </FullRow>
  );
};

export default compose(withSize, injectIntl, React.memo)(InspiringStoryHero);
