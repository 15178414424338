import React from 'react';

import './LandingVideo.scss';

const LandingVideo = ({label, labelColor, url}) => {
  const labelStyle = {
    color: labelColor,
  };
  return (
    <div className="landing-video__wrapper">
      {label && (
        <h2 className="fs--label" style={labelStyle}>
          {label}
        </h2>
      )}
      <div className="landing-video">
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="landing-video__iframe"
          referrerpolicy="strict-origin-when-cross-origin"
          src={url}
          title="YouTube video player"
        ></iframe>
      </div>
    </div>
  );
};

export default React.memo(LandingVideo);
