import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import RoutingFeaturedLink from './../../_bridges/RoutingFeaturedLink/RoutingFeaturedLink';

import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import Grid from './../../layouts/Grid/Grid';
import Row from './../../layouts/Row/Row';
import Column from './../../layouts/Column/Column';
import get from 'lodash.get';

import './RelatedAreas.scss';

const RelatedAreas = ({areas, icon, title, titleTag, to}) => (
  <div className="related-areas">
    <Row>
      <Column>
        <Typography className="related-areas__title" tag={titleTag} variant={VARIANT.HEADING}>
          {title}
        </Typography>
      </Column>
    </Row>
    <Grid className="related-areas__grid" columnsPerBreakpoint={{S: 1, M: 2}} itemClassName="related-areas__grid-item">
      {areas.map((area, index) => (
        <>
          <RoutingFeaturedLink
            area={area.fields.relatedPage}
            className={classNames(`related-areas--${area.fields.relatedPage.gradient.type}`)}
            icon={icon}
            key={index}
            scrollAnimation={false}
            title={get(area, 'fields.relatedPage.title') || area.title}
            to={to}
          />
        </>
      ))}
    </Grid>
  </div>
);

RelatedAreas.propTypes = {
  areas: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
  to: PropTypes.string.isRequired,
};

RelatedAreas.defaultProps = {
  titleTag: 'h3',
};

export default React.memo(RelatedAreas);
