import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {compose} from 'redux';

import ConditionalWrap from './../../../_util/ConditionalWrap';
import {valuePerBreakpointForConfig} from './../../../_config/breakpoints';
import withSize from './../../../../_packages/resize/ui/withSize';
import menuItemsPropType from './../../../_propTypes/menuItemsPropType';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Iconography, {ICON} from './../../../atoms/Iconography/Iconography';

import CmsLink from './../../../components/CmsLink/CmsLink';

import Grid from './../../../layouts/Grid/Grid';
import MainMenuChild from './MainMenuChild';

import {shouldShowChildsByColumnsAndRows, hasSubElements} from './../Header.utils';

import './MainMenu.scss';

const getItemIndexByRowAndColumnsPerView = ({row, columnsPerView, column}) => row * columnsPerView + column;

const handleSelectedItemChange = setSelectedItem => selected => value => () =>
  setSelectedItem(selected === value ? null : value);

const MainMenu = ({isScrolled, items, onCustomClick, windowWidth}) => {
  const [selected, setSelected] = useState(null);

  const columnsPerBreakpoint = hasSubElements(items) ? {S: 1} : {L: 1, XL: 4};
  const columnsPerView = valuePerBreakpointForConfig(columnsPerBreakpoint)(windowWidth);

  const transformItemsIntoRowsByColumsPerView = items.reduce(
    (rows, key, index) => (index % columnsPerView === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows,
    [],
  );

  useEffect(() => {
    setSelected(null);
  }, [items]);

  return (
    <div className={classNames('main-menu', {'main-menu--scrolled': isScrolled})}>
      <Iconography className="main-menu__close" icon={ICON.CLOSE} onClick={() => onCustomClick()} />
      {transformItemsIntoRowsByColumsPerView.length > 0 &&
        transformItemsIntoRowsByColumsPerView.map((transformedItemsRow, row) => (
          <div key={row}>
            <Grid className="main-menu__grid" columnsPerBreakpoint={columnsPerBreakpoint} scrollAnimation={false}>
              {transformedItemsRow.map((transformedItemsColumn, column) => (
                /* eslint-disable jsx-a11y/click-events-have-key-events */
                /* eslint-disable jsx-a11y/no-static-element-interactions */
                <div
                  className={classNames('main-menu__item', {
                    'main-menu__item-selected': shouldShowChildsByColumnsAndRows({
                      columnsPerView,
                      row,
                      selected,
                      items,
                    }),
                  })}
                  key={column}
                  onClick={handleSelectedItemChange(setSelected)(selected)(
                    getItemIndexByRowAndColumnsPerView({row, columnsPerView, column}),
                  )}
                >
                  <ConditionalWrap
                    condition={transformedItemsColumn.slug}
                    wrap={children => {
                      let linkClasses = {};
                      if (transformedItemsColumn.fields) {
                        linkClasses = {
                          'main-menu__link--featured': transformedItemsColumn.fields.featured,
                        };
                      }
                      return (
                        <CmsLink
                          className={classNames('main-menu__link', linkClasses)}
                          onClick={transformedItemsColumn.items && (e => e.preventDefault())}
                          slug={transformedItemsColumn.slug}
                        >
                          {children}
                        </CmsLink>
                      );
                    }}
                  >
                    <Typography
                      className="main-menu__item-title"
                      variant={hasSubElements(items) ? VARIANT.HEADING_SMALL : VARIANT.BODY_SMALL}
                    >
                      {transformedItemsColumn.title}
                    </Typography>
                    {items[row].items && <Iconography className="main-menu__item-icon" icon={ICON.PYKE} />}
                  </ConditionalWrap>
                </div>
                /* eslint-disable jsx-a11y/click-events-have-key-events */
                /* eslint-disable jsx-a11y/no-static-element-interactions */
              ))}
            </Grid>
            {shouldShowChildsByColumnsAndRows({columnsPerView, row, selected, items}) && (
              <MainMenuChild items={items[selected]} onSelected={onCustomClick} />
            )}
          </div>
        ))}
    </div>
  );
};

MainMenu.propTypes = {
  isScrolled: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(menuItemsPropType),
  onCustomClick: PropTypes.func.isRequired,
};

MainMenu.defaultProps = {
  isScrolled: false,
};

export default compose(withSize, React.memo)(MainMenu);
