import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import algoliaSearch from 'algoliasearch/lite';
import {InstantSearch, connectHits, Pagination, Configure, ScrollTo} from 'react-instantsearch-dom';
import {withI18nRouting} from '@foes/react-i18n-routing';
import {withRouter} from 'react-router';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import get from 'lodash.get';

import {TECHNOLOGY} from './../../../../routing/technologies/routes';
import {SECTOR} from './../../../../routing/sectors/routes';
import {INSPIRING_BLOG_POST} from '../../../../routing/inspiringBlog/routes';
import {EVENT} from './../../../../routing/events/routes';
import {NEWS} from './../../../../routing/news/routes';
import {PROJECT} from './../../../../routing/projects/routes';
import {LABORATORY_SERVICE} from './../../../../routing/laboratoryServices/routes';
import {INSPIRING_STORY} from './../../../../routing/inspiringStories/routes';
import {AMBIT} from './../../../../routing/ambits/routes';
import {INFRASTRUCTURE} from './../../../../routing/infrastructures/routes';
import {PRESS_RELEASE} from './../../../../routing/pressReleases/routes';
import {ACTIVE} from './../../../../routing/actives/routes';
import {COMPANY} from './../../../../routing/companies/routes';
import {PAGE} from './../../../../routing/page/routes';

import RoutingGenericPreview from './../../../_bridges/RoutingGenericPreview/RoutingGenericPreview';

import SearchInput from './../SearchInput/SearchInput';

import Typography, {VARIANT} from './../../../atoms/Typography/Typography';
import Content from './../../../atoms/Content/Content';

import SearchBox from './../../../components/SearchBox/SearchBox';

import FullRow from './../../../layouts/FullRow/FullRow';
import Row from './../../../layouts/Row/Row';
import Column from './../../../layouts/Column/Column';
import Breadcrumbs from './../../../layouts/Breadcrumbs/Breadcrumbs';

import './SearchContent.scss';

const searchClient = algoliaSearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);

const getContentType = (type, intl) => {
  switch (type) {
    case 'sector':
      return {to: SECTOR, label: intl.formatMessage({id: 'sectors.category'})};
    case 'technology':
      return {to: TECHNOLOGY, label: intl.formatMessage({id: 'technology.category'})};
    case 'post':
      return {to: INSPIRING_BLOG_POST, label: intl.formatMessage({id: 'blog.category'})};
    case 'event':
      return {to: EVENT, label: intl.formatMessage({id: 'event.category'})};
    case 'news':
      return {to: NEWS, label: intl.formatMessage({id: 'news.category'})};
    case 'project':
      return {to: PROJECT, label: intl.formatMessage({id: 'project.category'})};
    case 'laboratory':
      return {to: LABORATORY_SERVICE, label: intl.formatMessage({id: 'lab.service.category'})};
    case 'inspiring_story':
      return {to: INSPIRING_STORY, label: intl.formatMessage({id: 'inspiringStories.category'})};
    case 'ambit':
      return {to: AMBIT, label: intl.formatMessage({id: 'ambit.category'})};
    case 'infrastructure':
      return {to: INFRASTRUCTURE, label: intl.formatMessage({id: 'infrastructures.category'})};
    case 'press_release':
      return {to: PRESS_RELEASE, label: intl.formatMessage({id: 'press.release.category'})};
    case 'active':
      return {to: ACTIVE, label: intl.formatMessage({id: 'business.opportunity.active'})};
    case 'company':
      return {to: COMPANY, label: intl.formatMessage({id: 'business.opportunity.company'})};
    case 'page':
      return {to: PAGE, label: intl.formatMessage({id: 'site.title'})};
    default:
      return {to: TECHNOLOGY, label: intl.formatMessage({id: 'technology.category'})};
  }
};

const Hit = ({hit, intl}) => {
  const getHitTypeInfo = getContentType(hit.type, intl);
  const highlightedText = get(hit, '_snippetResult.content.value') || get(hit, '_snippetResult.content[0].value');
  return (
    <RoutingGenericPreview
      className="search-content__item search-content__highlighted"
      content={
        highlightedText && (
          <Content
            children={highlightedText && `[...] ${highlightedText} [...]`}
            className="search-content__highlighted"
            raw
          />
        )
      }
      horizontal
      key={hit.objectID}
      label={getHitTypeInfo.label}
      preview={{...hit, title: hit._highlightResult.title.value}}
      rawContent={true}
      to={getHitTypeInfo.to}
    />
  );
};

const Hits = ({hits, intl}) =>
  Array.isArray(hits) && hits.length > 0 ? (
    hits.map(hit => <Hit hit={hit} intl={intl} key={hit.objectID} />)
  ) : (
    <Typography className="hits--no-hits" tag="div">
      {intl.formatMessage({id: 'search.no.results'})}
    </Typography>
  );

const CustomHits = connectHits(Hits);

const updatePageOnStatePageChange = history => state => {
  const search = new URLSearchParams(window.location.search);
  search.set('page', state.page);
  history.push(`${window.location.pathname}?${search.toString()}`);
};

const getPage = query => {
  const search = new URLSearchParams(query.location.search);
  return search.get('page') || 1;
};

const SearchContent = ({i18nRouting, query, intl, history}) => (
  <div className="search-content">
    <FullRow className="search-content__header">
      <Breadcrumbs />
      <SearchBox className="search-content__search-box" defaultValue={query} showCloseIcon={false} />
    </FullRow>

    <Row className="search-content_results">
      <Column className="search-content_results-title">
        <Typography variant={VARIANT.HEADING}>
          <FormattedHTMLMessage id="search.results" values={{query: query}} />
        </Typography>
      </Column>
    </Row>

    <Row>
      <InstantSearch
        indexName={`unified_index_${i18nRouting.locale}`}
        onSearchStateChange={updatePageOnStatePageChange(history)}
        searchClient={searchClient}
      >
        <Configure hitsPerPage={10} />
        <div style={{display: 'none'}}>
          <SearchInput defaultRefinement={query} />
        </div>
        <ScrollTo>
          <CustomHits intl={intl} />
        </ScrollTo>

        <Pagination
          defaultRefinement={getPage(history)}
          showFirst={false}
          translations={{
            previous: '|<',
            next: '>|',
            ariaPrevious: 'Previous page',
            ariaFirst: 'First page',
          }}
        />
      </InstantSearch>
    </Row>
  </div>
);

SearchContent.propTypes = {
  filter: PropTypes.objectOf(PropTypes.string),
  query: PropTypes.string,
};

SearchContent.defaultProps = {
  query: '',
};

export default compose(withI18nRouting, withRouter, React.memo, injectIntl)(SearchContent);
